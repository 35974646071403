import PersonnelCardSkeleton from '../utils/reusables/PersonnelCardSkeleton';

function PersonnelSearchSkeleton() {
  return (
    <div className="flex flex-col p-4 gap-4">
      <PersonnelCardSkeleton />
      <PersonnelCardSkeleton />
      <PersonnelCardSkeleton />
    </div>
  );
}

export default PersonnelSearchSkeleton;
