import backgroundImage from '../Images/Background.png';
import clients from '../Images/Clients.png';
import layer1 from '../Images/Layer1.png';
import layer2 from '../Images/Layer2.png';
import layer3 from '../Images/Layer3.png';
import layer4 from '../Images/Layer4.png';
import imgVector from '../Images/Vector1.png';
import assetImg from '../Images/asset.png';
import audio from '../Images/audio.png';
import Communicate from '../component/Communicate';
import Layout from '../component/Layout';
import Options from '../components/utils/reusables/Options';

function Home() {
  // const history = useNavigate();
  // const access_token = localStorage.getItem('access_token');

  // const getStarted = () => {
  //   if (access_token) {
  //     history('/company/setup');
  //   } else {
  //     history('/signin');
  //   }
  // };

  return (
    <Layout>
      <Communicate />
      <div
        className="bg-cover bg-center h-100 w-100 px-3 lg:px-7 xl:px-20"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <section className="flex flex-col lg:flex-row items-center lg:justify-evenly justify-center lg:mx-14">
          <div className="flex flex-col gap-5 mt-[2rem]">
            <p className="text-[#111B3D] text-3xl font-medium max-[600px]:text-xl text-center lg:text-left">
              Understand Your Client <br />
              Needs in Real Time
            </p>

            <p className="text-[#5E516F] text-lg text-center w-full lg:w-[70%] xl:w-[60%] lg:text-left px-5 lg:px-0">
              Imagine this: a potential customer visits your website and has a
              question about your products or services. Instead of waiting for
              an email response or making a phone call, they can simply click on
              the chat icon and start a conversation with you instantly.
            </p>
          </div>

          <img src={assetImg} alt="" className="h-96 w-96" />
        </section>

        <section className="px-5">
          <div className="grid grid-rows-2 justify-center place-items-center content-center mb-10 mt-10 px-20 ">
            <p className="text-3xl max-[768px]:text-xl text-[#000]">
              Why <span className="text-[#3592FF]">Andromedia?</span>
            </p>
            <img
              src={imgVector}
              className="w-32 max-[768px]:w-24"
              alt="vector1"
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[3rem]">
            <div className="flex flex-col gap-7 place-items-center content-center">
              <img src={layer1} className="w-20 h-20" alt="layer1" />
              <p className="w-full md:w-[25rem]">
                It's a convenient way to engage with customers in real-time and
                provide instant support. With Andromedia, website owners can
                easily integrate a chat application on their site, allowing them
                to interact with visitors, answer queries, and provide
                personalized assistance.
              </p>
            </div>
            <div className="flex flex-col gap-7 place-items-center content-center">
              <img src={layer2} className="w-20 h-20 " alt="layer2" />
              <p className="w-full md:w-[25rem]">
                The user-friendly interface allows you to manage multiple
                conversations simultaneously, ensuring that no customer is left
                waiting. Customize the chat widget to match your brand's look
                and feel, creating a seamless and cohesive experience for your
                visitors.
              </p>
            </div>
            <div className="flex flex-col gap-7 place-items-center content-center">
              <img src={layer3} className="w-20 h-20" alt="layer3" />
              <p className="w-full md:w-[25rem]">
                Andromedia also provides powerful features to enhance your
                customer support capabilities. You can track visitor behavior,
                gather valuable insights, and even automate responses with
                chatbots. This helps you streamline your support process, save
                time, and provide efficient assistance to your customers.
              </p>
            </div>
            <div className="flex flex-col gap-7 place-items-center content-center">
              <img src={layer4} className="w-20 h-20" alt="layer4" />
              <p className="w-full md:w-[25rem]">
                A game-changer for customer service. It's like having a virtual
                customer service representative right on your site.
              </p>
            </div>
          </div>
        </section>

        <section className="mt-24">
          <div className="flex items-center justify-around mb-10 flex-wrap max-[600px]:justify-center">
            <img src={audio} alt="audioImg" className="h-96 w-96" />
            <ul>
              <ul className="grid grid-rows-2 place-items-center">
                <li>
                  <span className="text-[#111B3D] text-3xl max-[768px]:text-xl font-medium">
                    How to use Andromedia
                  </span>{' '}
                  <span className="text-[#3592FF] text-3xl font-medium max-[768px]:text-xl">
                    {' '}
                    Chat
                  </span>
                </li>
                <li>
                  <img
                    src={imgVector}
                    className="w-32 max-[768px]:w-24"
                    alt="vector1"
                  />
                </li>
              </ul>
              <div className="max-w-3xl">
                <Options color={'#5E516F'} text={'Create an account'} />
                <Options color={'#3592FF'} text={' Dashboard Overview'} />
                <Options
                  color={'#5E516F'}
                  text={'Create an application and paste your website link'}
                />
                <Options
                  color={'#3592FF'}
                  text={'Install the Chat Widget on Your Website'}
                />
              </div>
            </ul>
          </div>
        </section>

        <section>
          <div className="flex flex-col h-64 justify-center items-center mt-48 mb-48 gap-10 max-[768px]:mt-24 max-[768px]:mb-20">
            <div className="">
              <h3 className="text-[#3592FF] font-medium text-3xl  max-[768px]:text-xl text-center">
                Communicate with Clients Anywhere in <br />
                the World
              </h3>
            </div>
            <img src={clients} alt="clients" className="w-9/12 align-center" />
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Home;
