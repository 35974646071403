function VideoChatIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 65 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3941 1H37.9392C40.3309 1 42.3333 3.02419 42.3333 5.60156V39.3984C42.3333 41.9758 40.3309 44 37.9392 44H5.3941C3.00245 44 1 41.9758 1 39.3984V5.60156C1 3.02419 3.00245 1 5.3941 1ZM47.9444 31.2085V13.7911L59.8924 5.23265C59.8928 5.23239 59.8932 5.23212 59.8935 5.23185C61.603 4.0163 64 5.23836 64 7.44141V37.5469C64 39.7599 61.5938 40.9723 59.8943 39.757C59.8943 39.757 59.8942 39.7569 59.8942 39.7569L47.9444 31.2085Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
}

export default VideoChatIcon;
