import React from 'react';

function Profile() {
  return (
    <div className="flex w-full gap-3  px-5 ">
      <div className=" w-[30%] text-[#818485] flex flex-col items-center gap-4">
        <h2 className="font-[] text-[29px] ">Profile</h2>
        <img src="web_profile.png" />
        <h2 className="font-[800] text-[25px]">Aaron Sanchez</h2>
        <h3>Admin</h3>
        <button className="bg-[#3B8CF7] text-[#fff] px-4 py-3 rounded-lg">
          Upload new Avatar
        </button>
      </div>
      <div className=" w-[60%] text-[#818485] flex flex-col ">
        <div className="flex items-center">
          <h2 className="uppercase font-bold text-[#818485] ">Basic Info</h2>

          <div className="flex self-start ml-auto gap-3  mt-2 ">
            <button className="border border-[#3B8CF7] rounded-lg text-[#3B8CF7] w-24 p-2">
              Cancel
            </button>
            <button className="border bg-[#3B8CF7] text-[#fff] rounded-lg w-24 p-2">
              Save
            </button>
          </div>
        </div>
        <hr className="mt-4 bg-[#818485]" />
        <div className="flex gap-3 flex-col ">
          <div></div>
          <form className="">
            <div className="flex gap-4 w-full ">
              <div className="mb-6 w-full">
                <label
                  for="text"
                  className="block uppercase mb-2 text-[#818485] text-sm font-medium  dark:text-white"
                >
                  First Name
                </label>
                <input
                  type="email"
                  id="firstname"
                  className="shadow-sm bg-gray-50 w-full border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="John"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  for="text"
                  className="block uppercase mb-2 text-[#818485] text-sm font-medium dark:text-white"
                >
                  Last Name
                </label>
                <input
                  type="email"
                  id="lastname"
                  className="shadow-sm bg-gray-50 border border-gray-300 w-64 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Doe"
                  required
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                for="title"
                className="block uppercase mb-2 text-sm font-medium text-[#818485] dark:text-white"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
              />
            </div>
            <div className="mb-6">
              <label
                for="email"
                className="block uppercase mb-2 text-sm font-medium text-[#818485]  dark:text-white"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
              />
            </div>

            <h2 className="text-[#818485] font-bold">Password Reset</h2>
            <div className="flex flex-col gap-1 w-full mt-3 ">
              <div className="mb-6 w-full">
                <label
                  for="text"
                  className="block uppercase mb-2 text-[#818485] text-sm font-medium  dark:text-white"
                >
                  Old Password
                </label>
                <input
                  type="password"
                  id="old_password"
                  className="shadow-sm bg-gray-50 w-full border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="John"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  for="text"
                  className="block uppercase mb-2 text-[#818485] text-sm font-medium  dark:text-white"
                >
                  New Password
                </label>
                <input
                  type="password"
                  id="new_password"
                  className="shadow-sm bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="New Password"
                  required
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
