import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function BottombarItem({ item, key }, { defaultActive }) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const isActive = location.pathname === item.path;

  let iconElement = null;
  const isAgentIconActive = item.path === '/Agents';

  if (item.icon === 'customer_support.png' && isAgentIconActive) {
    const agentIconImage =
      location.pathname === '/Agents'
        ? 'customer_support_active.svg'
        : 'customer_support.png';
    iconElement = (
      <img
        src={agentIconImage}
        alt="Agent Icon"
        className={`w-[30px] h-full ${isActive ? 'active-icon' : ''}`}
      />
    );
  } else if (typeof item.icon === 'string') {
    iconElement = (
      <img
        src={item.icon}
        alt="Icon"
        className={`w-[30px] h-full ${isActive ? 'active-icon' : ''}`}
      />
    );
  } else {
    iconElement = React.createElement(item.icon, {
      className: `w-[30px] h-full ${isActive ? 'active-icon' : ''}`,
    });
  }

  if (item.childrens) {
    return (
      <div
        className={
          open
            ? 'sidebar-item open'
            : 'sidebar-item p-[10px] flex flex-col place-items-center  hover:bg-[#DAEBFF] rounded'
        }
      >
        <Link
          to={item.path}
          className="sidebar-title flex flex-row justify-between w-full items-center hover:bg-[rgba(218, 235, 255, 0.79)] hover:rounded"
          active={key === isActive}
        >
          {/* <img src={item.icon} alt="" className="w-[24] h-[28]" /> */}
          {item.icon &&
            React.createElement(item.icon, { className: 'w-[30px] h-full' })}

          <p className="font-semibold text-[#818485] font-['Poppins'] text-[20px] leading-[30px]">
            {item.title}
          </p>
          <img
            src="Polygon 1.svg"
            alt=""
            className="w-4 h-4 cursor-pointer duration-[0.3s] toggle-btn"
            onClick={() => setOpen(!open)}
          />
        </Link>

        <div className="sidebar-content h-0 overflow-hidden">
          <hr className=" -ml-0.5 h-0.5 w-80 bg-black-600" />
          {item.childrens.map((child) => (
            <Link to={item.path}>
              <div className=" cursor-pointer hover:text-[#DAEBFF]">
                <p className="font-semibold text-[#818485] font-['Poppins'] text-[14px] leading-[21px] mt-[15px] self-center">
                  {child.title}
                </p>
                <hr className=" -ml-0.5 h-0.5 w-80 bg-black-600" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex w-full">
        <Link
          to={item.path || '#'}
          className={`sidebar-item plain flex items-center flex-col p-[10px] justify-center hover:bg-[#DAEBFF] rounded mx-auto w-full ${
            isActive ? 'active-link' : 'text-[#70B2FF]'
          }`}
          key={key}
          active={isActive}
        >
          {iconElement}
          <p
            className={`font-semibold self-center text-[10px] leading-[30px] ${
              isActive ? ' text-[#17106B]' : 'text-[#70B2FF]'
            }`}
          >
            {item.title}
          </p>
        </Link>
      </div>
    );
  }
}
