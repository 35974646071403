import React from 'react';
import Graph from '../../components/Graph';
import GraphHeader from '../../components/GraphHeader';
import Statistics from '../../components/Statistics';
import {
  TablesContactList,
  TablesConversations,
  TablesConversationsMobile,
} from '../../components/Tables';
import Dashboards from '../Dashboards';

const Dashboard = () => {
  return (
    <Dashboards>
      <div className="flex  w-full h-auto px-2 md:mt-[80px] mt-[10px]">
        <div className="flex flex-col w:md-2/5   w-full  h-[70%] px-5">
          <div className="w-full h-auto hidden md:block rounded-xl bg-[#F8F9FC] shadow-[0_4px_10px_-1px_rgba(0,0,0,0.25)] p-[12px] mb-12">
            <TablesConversations />
          </div>
          <div className=" w-full md:hidden  mb-6">
            <TablesConversationsMobile name={'Conversations'} />
          </div>
          <div className="flex flex-col w-full h-[50%] py-6 rounded-xl items-center justify-center bg-[#F8F9FC] font-black shadow-[0_4px_10px_-1px_rgba(0,0,0,0.25)] md:py-8">
            <GraphHeader />
            <Graph />
          </div>
          <Statistics />
        </div>
        <div className="md:flex gap-3  ml-3 hidden flex-col h-[80%] px-3 justify-between">
          {/* <div className="flex items-center h-1/8 justify-end gap-2 mt-[20px] font-medium text-[#818485]">
            <h5>
              Agent: <span className="text-[#3592FF]">Admin</span>
            </h5>
            <img src="Vector(2).svg" alt="" className="h-7 w-7" />
          </div> */}
          <div className="flex flex-col h-[200px] w-full justify-between  text-sm">
            <h2 className="text-[#17106B] text-md font-bold  self-start">
              Contact List
            </h2>

            <TablesContactList />
          </div>

          <div className="flex mt-[20px] flex-row w-full h-1/5 rounded-lg gap-3 px-3 items-center justify-around bg-[#F8F9FC] shadow-[0_4px_10px_-1px_rgba(0,0,0,0.25)] py-8">
            <div className="  gap-1  flex flex-col items-center justify-center w-24">
              <div className=" h-[full] ">
                <img src="Vector(7).svg" alt="" className="w-fit h-auto" />
              </div>
              <p className=" text-[10px] font-md">Total Agents</p>
              <h4 className=" font-black text-[#17106B]">105</h4>
            </div>

            <div className="  gap-1  flex flex-col items-center justify-center w-24">
              <div className="h-[full]">
                <img src="Vector(8).svg" alt="" className="w-fit h-auto" />
              </div>
              <p className=" text-[10px] font-md">Inactive Agents</p>
              <h4 className=" font-black text-[#17106B]">105</h4>
            </div>

            <div className="  gap-1  flex flex-col items-center justify-center w-24">
              <div className="h-[full]">
                <img src="Vector(9).svg" alt="" className="w-fit h-auto" />
              </div>
              <p className=" text-[10px] font-md">Active Agents</p>
              <h4 className=" font-black text-[#17106B]">105</h4>
            </div>

            <div className="w-12 h-12">
              <img src="add-plus-circle.svg" alt="" />
            </div>

            <div className="w-10 h-10">
              <img src="hamburger-menu-dots-svgrepo-com copy.svg" alt="" />
            </div>

            <div className="w-12 h-12">
              <img src="user-avatar.png" alt="" />
            </div>
          </div>

          <div className="flex flex-row mt-[20px] items-center justify-around  w-full h-1/5">
            <div className="flex flex-col  items-center justify-center">
              <p className="text-[#17106B] text-xs font-bold">Visitors</p>
              <h1 className="text-[#17106B] font-['Poppins'] font-[600] text-4xl leading-[60px]">
                105
              </h1>
            </div>

            <div className="flex flex-col  items-center justify-center">
              <p className="text-[#17106B] text-xs font-bold">
                Chat Engagement
              </p>
              <h1 className="text-[#17106B] font-['Poppins'] font-[600] text-4xl leading-[60px]">
                105
              </h1>
            </div>

            <div className="flex flex-col  items-center justify-center">
              <p className="text-[#17106B] text-xs font-bold">Page Views</p>
              <h1 className="text-[#17106B] font-['Poppins'] font-[600] text-4xl leading-[60px]">
                105
              </h1>
            </div>
          </div>
        </div>
      </div>
    </Dashboards>
  );
};

export default Dashboard;
