import bg_image from '../Images/about_bg.png';
import preview from '../Images/preview.png';
import preview1 from '../Images/preview1.png';
import preview3 from '../Images/preview3.png';
import preview4 from '../Images/preview4.png';
import Layout from '../component/Layout';
import SectionOne from './about/SectionOne';
import SectionThree from './about/SectionThree';
import SectionTwo from './about/SectionTwo';

const About = () => {
  return (
    <Layout>
      <div className="w-full flex flex-col relative">
        <div
          style={{
            backgroundImage: `url(${bg_image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '100%',
          }}
        >
          <SectionOne />
          <SectionTwo />
        </div>
        <SectionThree />

        <img src={preview4} alt="" className="absolute top-40 right-36 -z-10" />
        <img
          src={preview1}
          alt=""
          className="absolute top-[65rem] left-0 md:left-36"
        />
        <img
          src={preview}
          alt=""
          className="absolute top-[80rem] right-0 -z-10"
        />
        <img
          src={preview3}
          alt=""
          className="absolute bottom-[18rem] right-0 -z-10"
        />
        <img
          src={preview3}
          alt=""
          className="absolute bottom-0 left-20 -z-10"
        />
        <img
          src={preview4}
          alt=""
          className="absolute -bottom-64 right-[30%] -z-10"
        />
      </div>
    </Layout>
  );
};

export default About;
