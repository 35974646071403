import { Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { SyncLoader } from 'react-spinners';
import { API_URL } from '../constants/api';
import { config } from '../constants/auth_actions';
import Dashboards from '../routes/Dashboards';
import CheckIcon from './utils/icons/CheckIcon';
import CompanyImagePreview from './utils/reusables/CompanyImagePreview';

const CompanySettings = () => {
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState();
  const [color, setColor] = useState('');
  const [selectedColor, setSelectedColor] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const access_token = localStorage.getItem('access_token');
  const current_company_id = localStorage.getItem('current_company_id');

  // console.log(selectedImage);

  const { data: company } = useQuery({
    queryKey: ['conversations'],
    queryFn: () =>
      axios
        .get(
          `${API_URL}/companies/${current_company_id}/`,
          config(access_token)
        )
        .then((res) => res),
  });

  const handleColor = (col, selectedCol) => {
    setColor(col);
    setSelectedColor(selectedCol);
  };

  const handleSubmit = async (data) => {
    data.preventDefault();

    const bodyFormData = new FormData();
    bodyFormData.append('name', data.target[0].value);
    bodyFormData.append('website_url', data.target[1].value);
    bodyFormData.append('language', data.target[2].value);
    bodyFormData.append('init_message', data.target[3].value);
    bodyFormData.append('color', color);
    if (selectedImage?.length > 0) {
      bodyFormData.append('image', selectedImage[0]);
    }

    try {
      setLoading(true);
      const res = await axios.patch(
        `${API_URL}/companies/${current_company_id}/update/`,
        bodyFormData,
        config(access_token)
      );

      console.log(res);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 403) {
        setError('You are not permitted to make any changes');
      } else {
        setError('An error occured while updating your application profile');
      }
      console.log(error);
      setLoading(false);

      setTimeout(() => {
        setError(null);
      }, 30000);
    }
  };

  useEffect(() => {
    document.getElementById('name').value = company?.data?.name;
    document.getElementById('website_url').value = company?.data?.website_url;
    document.getElementById('language').value = company?.data?.language;
    document.getElementById('init_message').value = company?.data?.init_message;
    setColor(company?.data?.color);
    if (
      company?.data?.color ===
      'bg-gradient-to-b from-purple-200 to-blue-600 shadow-md'
    ) {
      setSelectedColor(0);
    } else if (
      company?.data?.color === 'bg-gradient-to-b from-green-600 to-blue-400'
    ) {
      setSelectedColor(1);
    } else if (
      company?.data?.color === 'bg-gradient-to-b from-blue-500 to-purple-800'
    ) {
      setSelectedColor(2);
    } else if (
      company?.data?.color === 'bg-gradient-to-b from-orange-500 to-red-700'
    ) {
      setSelectedColor(3);
    } else if (company?.data?.color === 'bg-[#183E6B]') {
      setSelectedColor(4);
    }
  }, [company]);

  return (
    <Dashboards>
      <div className="w-full flex flex-col lg:flex-row justify-around items-start">
        <div className="w-[30%] my-[2rem] lg:my-[7rem]">
          <CompanyImagePreview
            selectedImageUrl={selectedImageUrl}
            setSelectedImageUrl={setSelectedImageUrl}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            image={company?.data?.image}
          />
        </div>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="flex flex-col justify-center w-full md:w-[90vw] lg:w-[40vw] h-full my-[2rem] gap-[1rem] px-2 mx-auto"
        >
          <p className="text-[#344266] font-semibold text-2xl text-center">
            Edit company details
          </p>

          <div>
            <p className="text-[#344266] font-medium text-sm mb-2">Theme</p>
            <div className="flex items-center gap-2">
              <div
                onClick={() =>
                  handleColor('bg-gradient-to-b from-purple-200 to-blue-600', 0)
                }
                className="h-[3rem] w-[3rem] rounded-[50%] bg-gradient-to-b from-purple-200 to-blue-600 shadow-md relative cursor-pointer"
              >
                {selectedColor === 0 && <CheckIcon />}
              </div>

              <div
                onClick={() =>
                  handleColor('bg-gradient-to-b from-green-600 to-blue-400', 1)
                }
                className="h-[3rem] w-[3rem] rounded-[50%] bg-gradient-to-b from-green-600 to-blue-400 shadow-md relative cursor-pointer"
              >
                {selectedColor === 1 && <CheckIcon />}
              </div>

              <div
                onClick={() =>
                  handleColor('bg-gradient-to-b from-blue-500 to-purple-800', 2)
                }
                className="h-[3rem] w-[3rem] rounded-[50%] bg-gradient-to-b from-blue-500 to-purple-800 shadow-md relative cursor-pointer"
              >
                {selectedColor === 2 && <CheckIcon />}
              </div>

              <div
                onClick={() =>
                  handleColor('bg-gradient-to-b from-orange-500 to-red-700', 3)
                }
                className="h-[3rem] w-[3rem] rounded-[50%] bg-gradient-to-b from-orange-500 to-red-700 shadow-md relative cursor-pointer"
              >
                {selectedColor === 3 && <CheckIcon />}
              </div>

              <div
                onClick={() => handleColor('bg-[#183E6B]', 4)}
                className="h-[3rem] w-[3rem] rounded-[50%] bg-[#183E6B] shadow-md relative cursor-pointer"
              >
                {selectedColor === 4 && <CheckIcon />}
              </div>
            </div>
          </div>

          <div>
            <p className="font-medium mb-1 text-[#344266] text-sm">
              Company Name
            </p>
            <input
              id="name"
              disabled
              required
              className="bg-[#DAEBFF] rounded-md border-none p-2"
            />
          </div>

          <div>
            <p className="font-medium mb-1 text-[#344266] text-sm">
              Website url
            </p>
            <input
              disabled
              id="website_url"
              required
              className="bg-[#DAEBFF] rounded-md border-none p-2"
            />
          </div>

          <div>
            <p className="font-medium mb-1 text-[#344266] text-sm">Language</p>
            <input
              id="language"
              required
              className="bg-[#DAEBFF] rounded-md border-none p-2"
            />
          </div>

          <div className="w-full">
            <p className="font-medium mb-1 text-[#344266] text-sm">
              Custom Message
            </p>
            <textarea
              id="init_message"
              required
              type="text"
              className="p-2 bg-[#DAEBFF] rounded-md border-none w-full h-[10rem] max-h-[10rem]"
            />
          </div>

          {error && (
            <Alert
              severity="error"
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              {error}
            </Alert>
          )}

          <button
            disabled={loading}
            type="submit"
            className="bg-[#3592FF] font-semibold text-white py-3 rounded-md w-full"
          >
            {loading ? <SyncLoader color="white" size={10} /> : 'Save'}
          </button>
        </form>
      </div>
    </Dashboards>
  );
};

export default CompanySettings;
