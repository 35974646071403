import { useNavigate } from 'react-router-dom';
import AnalyticsIcon from '../utils/icons/AnalyticsIcon';
import ChatbotIcon from '../utils/icons/ChatbotIcon';
import HeartOutlineIcon from '../utils/icons/HeartOutlineIcon';
import VideoChatIcon from '../utils/icons/VideoChatIcon';

const SectionThree = () => {
  const history = useNavigate();
  const access_token = localStorage.getItem('access_token');

  const getStarted = () => {
    if (access_token) {
      history('/company/setup');
    } else {
      history('/signin');
    }
  };

  return (
    <div className="flex flex-col items-center gap-14 px-5 md:px-12 lg:px-32 xl:px-52 py-16">
      <p className="text-lg lg:text-xl w-full md:w-[45rem] text-center">
        Andromedia helped us boost our sales by 10.8%. We have been using
        Andromedia for several years and it helped us deliver a 30% higher order
        value and 169 higher conversation rate
      </p>

      <div className="w-full bg-[#0CADF8] py-7 px-4 md:px-12 flex justify-between">
        <div className="flex flex-col items-center">
          <p className="text-white text-2xl md:text-5xl font-extrabold">95%</p>
          <p className="text-[#ffffffbf] text-sm md:text-base text-center">
            Satisfactory rate
          </p>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-white text-2xl md:text-5xl font-extrabold">+99%</p>
          <p className="text-[#ffffffbf] text-sm md:text-base text-center">
            Conversation rate
          </p>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-white text-2xl md:text-5xl font-extrabold">+95%</p>
          <p className="text-[#ffffffbf] text-sm md:text-base text-center">
            Average order rate
          </p>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-5 lg:gap-10">
        <div className="p-5 md:p-10 w-full h-[25rem] rounded-lg shadow-lg">
          <div className="flex items-center gap-5 md:gap-10 mb-6 md:mb-10">
            <ChatbotIcon />
            <p className="text-lg md:text-2xl">Chat</p>
          </div>

          <p className="text-sm xl:text-base tracking-wider font-light">
            Andromedia's chat application is easy to integrate into your
            website, making it a easy for both you and your customers to use.
            The user-friendly interface allows you to manage multiple
            conversations simultaneously, ensuring that no customer is left
            waiting. Customize the chat widget to match your brand's look and
            feel, creating a seamless and cohesive experience for your visitors.
          </p>
        </div>

        <div className="p-5 md:p-10 w-full h-[25rem] rounded-lg shadow-lg">
          <div className="flex items-center gap-5 md:gap-10 mb-6 md:mb-10">
            <HeartOutlineIcon />
            <p className="text-lg md:text-2xl">Customer Ratings</p>
          </div>

          <p className="text-sm xl:text-base tracking-wider font-light">
            A game-changer for customer service. It's like having a virtual
            customer service representative right on your site.
          </p>
        </div>

        <div className="p-5 md:p-10 w-full h-[25rem] rounded-lg shadow-lg">
          <div className="flex items-center gap-5 md:gap-10 mb-6 md:mb-10">
            <VideoChatIcon />
            <p className="text-lg md:text-2xl">Real Time</p>
          </div>

          <p className="text-sm xl:text-base tracking-wider font-light">
            Imagine this: a potential customer visits your website and has a
            question about your products or services. Instead of waiting for an
            email response or making a phone call, they can simply click on the
            chat icon and start a conversation with you instantly. You can
            address their concerns, guide them through the purchasing process,
            and provide valuable information in real time.
          </p>
        </div>

        <div className="p-5 md:p-10 w-full h-[25rem] rounded-lg shadow-lg">
          <div className="flex items-center gap-5 md:gap-10 mb-6 md:mb-10">
            <AnalyticsIcon />
            <p className="text-lg md:text-2xl">Analytics</p>
          </div>

          <p className="text-sm xl:text-base tracking-wider font-light">
            Andromedia also provides powerful features to enhance your customer
            support capabilities. You can track visitor behavior, gather
            valuable insights, and even automate responses with chatbots. This
            helps you streamline your support process, save time, and provide
            efficient assistance to your customers.
          </p>
        </div>
      </div>

      <div className="my-7 flex flex-col gap-10 w-full md:w-[80%]">
        <p className="text-2xl md:text-4xl text-[#3592FF] text-center">
          Start engaging with your website visitors using live chat software in
          few minutes
        </p>

        <p className="text-xl text-center">
          Assist your customers and convert them into leads with Andromedia live
          chat platform for online sales conversions
        </p>

        <button
          onClick={getStarted}
          className="bg-[#71BDFF] text-white rounded-sm border-none outline-none py-3 px-3 mt-9 w-[10rem] font-medium mx-auto z-10"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default SectionThree;
