function LocationIcon() {
  return (
    <svg
      width="30"
      height="40"
      viewBox="0 0 35 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7015 45.0719C2.4582 26.1473 0 24.2051 0 17.25C0 7.72306 7.83499 0 17.5 0C27.165 0 35 7.72306 35 17.25C35 24.2051 32.5418 26.1473 19.2985 45.0719C18.4294 46.3094 16.5705 46.3093 15.7015 45.0719ZM17.5 24.4375C21.5271 24.4375 24.7917 21.2196 24.7917 17.25C24.7917 13.2804 21.5271 10.0625 17.5 10.0625C13.4729 10.0625 10.2083 13.2804 10.2083 17.25C10.2083 21.2196 13.4729 24.4375 17.5 24.4375Z"
        fill="#15ACF5"
      />
    </svg>
  );
}

export default LocationIcon;
