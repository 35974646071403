import React from 'react';

import { Avatar } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Header from '../component/Header';
import MobileBottomNav from '../components/MobileBottomNav';
import NewHeader from '../components/NewHeader';
import Sidebar from '../components/Sidebar';
import SelectComponent from '../components/utils/reusables/SelectComponent';
import { API_URL } from '../constants/api';
import { config } from '../constants/auth_actions';

const Dashboards = ({ children }) => {
  const access_token = localStorage.getItem('access_token');

  const { data: user } = useQuery({
    queryKey: ['agentId'],
    queryFn: () =>
      axios
        .get(`${API_URL}/auth/user/`, config(access_token))
        .then((res) => res),
  });

  return (
    <>
      <div className="hidden md:block">
        <NewHeader />
      </div>
      <div className="block md:hidden">
        <Header />
      </div>
      <div className="md:mx-[60px] mt-3 relative hidden md:flex justify-between">
        <SelectComponent />

        <div className="hidden md:flex border py-2 gap-2 justify-between border-[#3592FF] text-[10px] rounded-lg px-5">
          <Avatar
            src={user?.data?.image || 'customer_support.png'}
            alt=""
            sx={{ height: '30px', width: '30px' }}
          />
          <div className="flex flex-col items-start justify-center">
            {user?.data?.first_name ? (
              <p className="font-bold">{`${user?.data?.first_name} ${user?.data?.last_name}`}</p>
            ) : (
              <p className="font-bold">User</p>
            )}
            {/* <p className="text-[#3592FF] font-bold">Role</p> */}
          </div>
        </div>
      </div>
      <div className="flex flex-row h-full overflow-hidden">
        <Sidebar />
        <MobileBottomNav />
        {children}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Dashboards;
