import axios from "axios"
import { parseError } from "./mixin"
// import {setAuthHeaders} from './config'

const URL = 'companies'


export const createCompanyAPI = async (data) => {
  try {
    const response = await axios.post(`${URL}/create`, data )
    console.log("create", response)
    if (response.status === 200) {
      return response.data.data
    }
    return parseError(response)
  } catch (error) {
    return parseError(error)
  }
}

export const getCompanyAPI = async () => {
  try {
    const response = await axios.get(`${URL}/` )
    console.log("get", response.data)
    if (response.status === 200) {
      return response.data
    }
    return parseError(response)
  } catch (error) {
    return parseError(error)
  }
}

export const createCustomersAPI = async (data, company_id) => {
  try {
    const response = await axios.post(`${URL}/${company_id}/customers/add/`, data,  {
    //   auth: {
    //     username: username,
    //     password: password
    // }
    })

    console.log({response})
    if (response.status === 200) {
      return response.data.data
    }
    return parseError(response)
  } catch (error) {
    return parseError(error)
  }
}
export const getConversationApi = async (data, company_id) => {
  try {

    const response = await axios.get(`${URL}/${company_id}/conversations/`, data )
    console.log({response})
    if (response.status === 200) {
      return response.data.data
    }
    return parseError(response)
  } catch (error) {
    return parseError(error)
  }
}
export const getTeammatesApi = async (company_id) => {
  try {

    const response = await axios.get(`${URL}/${company_id}/teammates/`)
    console.log({response})
    if (response.status === 200) {
      return response.data.data
    }
    return parseError(response)
  } catch (error) {
    return parseError(error)
  }
}
export const createTeammatesApi = async (company_id) => {
  try {

    const response = await axios.get(`${URL}/${company_id}/teammates/add/`)
    console.log({response})
    if (response.status === 200) {
      return response.data.data
    }
    return parseError(response)
  } catch (error) {
    return parseError(error)
  }
}
