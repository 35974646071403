import React from 'react'
import { AiFillApple } from "react-icons/ai"
export default function MobilePhone() {
    return (
        <div className='relative z-index-[1] '>
            <img className='-mt-60 w-2/4 h-full mx-auto' src="/application_phone.png" alt="" />
            <div className="block w-2/4 mx-auto flex flex-col justify-center items-center gap-4">

            <div className='flex gap-6 text-white  mt-5 '>
                <button className='bg-[#000] flex w-56 p-2 rounded-lg'> <AiFillApple style={{fontSize:'50px'}} /> <span>Download on the App Store</span></button>
                <button className='bg-[#000] flex  w-56 p-2 rounded-lg'><img src='playstore.png' /> 
                <div className="block">
                <p className='text-[10px]'> GET IT ON </p> 
                <span className='font-bold'>Google Play</span>
                </div>
                </button>

            </div>

            <h2 className='text-[#06364D] text-2xl text-center mt-10'>Nam sollicitudin dignissim nunc, 
cursus ullamcorper eros 
vulputate sed. .</h2>
<button className='text-[#2D80E0] p-2 shadow-lg mb-10 mx-auto w-fit rounded-lg'>Download Apps on google Play</button>
            </div>
        </div>
    )
}
