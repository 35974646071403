import { useNavigate } from 'react-router-dom';
import AndroIcon from './utils/icons/AndroIcon';

const NewHeader = () => {
  const history = useNavigate();
  const access_token = localStorage.getItem('access_token');
  const current_company_id = localStorage.getItem('current_company_id');

  const handleSignout = () => {
    localStorage.clear();
    history('/');
    window.location.reload();
  };

  return (
    <div className="flex justify-between py-2 px-2 md:px-5 lg:px-10 shadow-md">
      <div
        onClick={() => history('/')}
        className="flex items-center cursor-pointer"
      >
        <AndroIcon />
        <p className="text-[#3592FF] font-semibold text-lg -ml-2">ANDROMEDIA</p>
      </div>

      <div className="hidden md:flex items-center gap-5 font-medium">
        <p
          onClick={() => history('/monthly-pricing')}
          className="text-[#755F90] cursor-pointer"
        >
          Pricing
        </p>

        {access_token && current_company_id && (
          <p
            onClick={() => history('/dashboard')}
            className="text-[#755F90] cursor-pointer"
          >
            Dashboard
          </p>
        )}

        {!current_company_id && (
          <p
            onClick={() => history('/company/setup')}
            className="cursor-pointer py-2 px-3 text-[#4D6EC3] border-2 rounded-xl"
          >
            Free Demo
          </p>
        )}

        {access_token ? (
          <p
            onClick={handleSignout}
            className="cursor-pointer py-2 px-3 text-[#000] border-2  border-[#6DB6E2] rounded-xl"
          >
            Sign out
          </p>
        ) : (
          <p
            onClick={() => history('/signin')}
            className="cursor-pointer py-2 px-3 text-[#000] border-2 border-[#6DB6E2] rounded-xl"
          >
            Login
          </p>
        )}
      </div>
    </div>
  );
};

export default NewHeader;
