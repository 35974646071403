import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import { BiDotsVertical } from 'react-icons/bi';
import { SyncLoader } from 'react-spinners';
import { API_URL } from '../constants/api';
import { config } from '../constants/auth_actions';
import items from '../data/tables.json';
import DeleteIcon from './utils/icons/DeleteIcon';

const access_token = localStorage.getItem('access_token');
const current_company_id = localStorage.getItem('current_company_id');

const TableConversations = ({ conversations }) => {
  // let conversations = items[0].conversations;
  // console.log('CONV', conversations);

  const { data } = useQuery({
    queryKey: ['conversations'],
    queryFn: () =>
      axios
        .get(
          `${API_URL}/companies/${current_company_id}/customers/${conversations?.customer}/`,
          config(access_token)
        )
        .then((res) => res),
  });

  return (
    <>
      {conversations &&
        conversations?.length > 0 &&
        conversations?.map((item) => (
          <tr className=" border-b-[1px] py-3 cursor-pointer">
            <td>
              <img src={item?.country} alt="" />
            </td>
            <td className="text-[#656375]  font-[700] items-start text-[10px] leading-[15px]">
              {item?.id}
            </td>
            <td className="text-[#656375]  font-[700] items-start text-[10px] leading-[15px] capitalize">
              {data?.name}
            </td>
            {/* <td className="text-[#656375]  font-[700] items-start text-[10px] leading-[15px]">
          {item.agent}
        </td> */}
            <td className="text-[#656375]  font-[700] items-start text-[10px] leading-[15px] capitalize">
              {item?.chat_status}
            </td>
            <td className="flex flex-col text-[#656375]font-[700] text-[10px] leading-[15px] items-end">
              {item?.updated_at?.slice(11, 16)}
              <span className="text-xs">{item?.updated_at?.slice(0, 10)}</span>
            </td>
          </tr>
        ))}
    </>
  );
};
const ConversationsMobile = () => {
  let conversations = items[0].conversations;
  return conversations?.map((item) => (
    <>
      <div className="flex gap-3 w-full items-center px-[10px] mt-[10px] md:hidden border-b-2 py-1">
        <img
          src={getStatusImage(item.status)}
          className="w-[10px] h-[10px] mb-5 "
          alt=""
        />
        <div className="flex items-center justify-between">
          <div className=" ">
            <div className="flex items-center gap-4">
              <h2 className="font-bold text-[#344266]">{item.client}</h2>{' '}
              <span class="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                2{' '}
              </span>
            </div>
            <p className="font-[15px]  w-full">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </div>
          <p>{item.time} </p>
          <BiDotsVertical style={{ fontSize: '40px' }} />
        </div>
      </div>
    </>
  ));
};

function getStatusImage(status) {
  switch (status) {
    case 'Waiting':
      return 'pending.png';
    case 'Closed':
      return 'offline.png';
    case 'In Progress':
      return 'online.png';
    default:
      return 'pending.png';
  }
}

const TableAgents = ({ data }) => {
  // const { isLoading, error, data } = useQuery({
  //   queryKey: ['agents'],
  //   queryFn: () =>
  //     axios
  //       .get(
  //         `${API_URL}/companies/${current_company_id}/teammates/`,
  //         config(access_token)
  //       )
  //       .then((res) => res),
  // });

  console.log(data);

  return data?.map((item) => (
    <>
      <tr className="border-b-[1px]">
        <td className="text-[#656375] font-['Poppins'] font-[700] text-[10px] leading-[15px] py-[10px] pr-3">
          {item.id}
        </td>
        <td className="text-[#656375] capitalize font-['Poppins'] font-[700] text-[10px] leading-[15px] py-[10px] pr-3">
          {`${item.user?.first_name}  ${item.user?.last_name}`}
        </td>
        <td className="text-[#656375] font-['Poppins'] font-[700] text-[10px] leading-[15px] py-[10px] pr-3">
          {`${item.user?.email}`}
        </td>
        <td className="text-[#656375] md:text-left text-right capitalize font-['Poppins'] font-[500] text-[10px] leading-[15px] py-[10px] md:pr-3">
          {item.role}
        </td>
      </tr>
    </>
  ));
};

const AgentsMobile = () => {
  let Agents = items[1].agents;

  return Agents.map((item) => (
    <>
      <div className="flex gap-3 w-full items-center overflow-y-auto  mt-[10px]   border-b-2 py-1">
        <img
          src={getStatusImage(item.status)}
          className="w-[10px] h-[10px] mb-5 "
          alt=""
        />
        <div className="flex items-center justify-between">
          <div className=" ">
            <div className="flex items-center gap-4">
              <h2 className="font-bold text-[#344266]">{item.agentName}</h2>{' '}
              <span class="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                2{' '}
              </span>
            </div>
            <p className="font-[15px]  w-full">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </div>
          <p>{item.time} </p>
          <BiDotsVertical style={{ fontSize: '40px' }} />
        </div>
      </div>
    </>
  ));
};

const TableContacts = ({ contacts }) => {
  const [isLoadig, setIsLoading] = useState(false);

  console.log(contacts);
  // let Contacts = items[2].contacts;

  const handleCustomerDelete = async () => {
    const reqData = {
      company_id: '',
      id: '',
    };

    try {
      setIsLoading(true);
      const response = axios.delete(
        `${API_URL}/companies/${current_company_id}/customers/`,
        reqData,
        config(access_token)
      );

      console.log(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return error;
    }
  };

  return contacts?.map((item) => (
    <>
      <tr className="border-b-[1px]">
        {/* <td>
          <img src={item.country} alt="" />
        </td>
        <td className="text-[#656375] font-['Poppins'] font-[700] text-[12px] leading-[15px]">
          {item.refId}
        </td> */}
        <td className="text-[#656375] font-['Poppins'] font-[700] text-[12px] leading-[15px]">
          {item.name}
        </td>
        <td className="text-[#656375] font-['Poppins'] font-[700] text-[12px] leading-[15px]">
          {item.email}
        </td>
        <td className="flex flex-col text-[#656375] font-['Poppins'] font-[700] text-[12px] leading-[15px]">
          {item.updated_at?.slice(12, 16)}
          <span className="text-[10px]">{item.updated_at?.slice(0, 10)}</span>
        </td>
        <td
          onClick={handleCustomerDelete}
          className="text-[#656375] font-['Poppins'] font-[700] text-[12px] leading-[15px] cursor-pointer"
        >
          {isLoadig ? <SyncLoader /> : <DeleteIcon />}
        </td>
      </tr>

      {/* <hr className=" -ml-2 h-4 w-full bg-black-500 self-center" /> */}
    </>
  ));
};

const TableContactList = ({ contacts }) => {
  console.log(contacts);
  // let ContactList = items[3].contactList;

  return contacts?.map((item) => (
    <>
      <tr className=" border-b-[1px]">
        <td className="text-[#656375]  font-[700] text-[9px] leading-[15px] p-[10px]">
          {item.id}
        </td>
        <td className="text-[#656375]  font-[700] text-[9px] leading-[15px] p-[10px]">
          {item.name}
        </td>
        <td className="text-[#656375]  font-[700] text-[9px] leading-[15px] p-[10px] border-l-[1px]">
          {item.email}
        </td>
        {/* <td className="text-[#656375]  font-[700] text-[9px] leading-[15px] p-[10px] border-l-[1px]">
          {item.phoneNo}
        </td> */}
      </tr>

      {/* <hr className=" -ml-2 h-4 w-full bg-black-500 self-center" /> */}
    </>
  ));
  // return console.log(ContactList)
};

const ContactsListMobile = () => {
  let ContactList = items[3].contactList;

  const { isLoading, error, data } = useQuery({
    queryKey: ['customers'],
    queryFn: () =>
      axios
        .get(
          `${API_URL}/companies/{companyId}/customers/{id}/`,
          config(access_token)
        )
        .then((res) => res.json()),
  });

  console.log(data);
  console.log('error', error);

  if (isLoading)
    return (
      <div className="flex justify-center py-20">
        <SyncLoader />
      </div>
    );

  return ContactList.map((item) => (
    <>
      <div className="flex gap-3 w-full items-center   mt-[10px]  border-b-2 py-1">
        <img
          src={getStatusImage(item.status)}
          className="w-[10px] h-[10px] mb-5 "
          alt=""
        />
        <div
          className="flex items-center w-full justify-between"
          key={item.refId}
        >
          <div className="w-full  ">
            <h2 className="font-bold text-[#344266] text-[15px] ">
              {item.name}
            </h2>
            <div className="flex flex-wrap divide-x gap-1 w-full ">
              <h2 className="font-bold text-[#344266] text-[11px]  ">
                {item.email}
              </h2>
              <h2 className="font-bold text-[#344266] pl-2 text-[11px] ">
                {item.phoneNo}
              </h2>
              <h2 className="font-bold text-[#344266] pl-2 text-[11px] ">
                Nigeria
              </h2>
            </div>
          </div>
          <h2 className="font-bold text-[#344266] text-[12px] ">2.23pm</h2>
          <BiDotsVertical style={{ fontSize: '40px' }} />
        </div>
      </div>
    </>
  ));
};
export {
  AgentsMobile,
  ContactsListMobile,
  ConversationsMobile,
  TableAgents,
  TableContactList,
  TableContacts,
  TableConversations,
};
