import { useEffect, useState } from 'react';

const useWidgetScript = (scriptUrl) => {
  const [scriptElement, setScriptElement] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;
    script.type = 'text/javascript'
    script.onload = () => {
      setScriptElement(script);
    };

    script.onerror = () => {
      setError(new Error(`Failed to load script: ${scriptUrl}`));
    };

    document.body.appendChild(script);

    // Cleanup function
    return () => {
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
    };
  }, [scriptUrl]);

  return { scriptElement, error };
};

export default useWidgetScript;
