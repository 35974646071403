function PhoneIcon() {
  return (
    <svg
      width="30"
      height="40"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.7167 31.7986L33.8728 27.5798C33.4522 27.4006 32.9849 27.3628 32.541 27.4722C32.0972 27.5815 31.7009 27.8321 31.4118 28.1862L27.0523 33.5125C20.2106 30.2867 14.7045 24.7806 11.4787 17.9389L16.805 13.5794C17.1598 13.2909 17.4109 12.8945 17.5203 12.4505C17.6298 12.0064 17.5915 11.5388 17.4114 11.1184L13.1926 1.27452C12.995 0.821354 12.6454 0.45136 12.2041 0.228337C11.7629 0.00531321 11.2577 -0.0567612 10.7756 0.0528173L1.63479 2.16223C1.16999 2.26956 0.755297 2.53127 0.458388 2.90464C0.16148 3.27801 -0.000107071 3.741 5.32293e-08 4.21803C5.32293e-08 26.7624 18.2728 45 40.782 45C41.2591 45.0003 41.7223 44.8388 42.0959 44.5419C42.4694 44.245 42.7313 43.8302 42.8386 43.3652L44.948 34.2244C45.0569 33.74 44.9935 33.2327 44.7688 32.79C44.544 32.3472 44.172 31.9967 43.7167 31.7986Z"
        fill="#15ACF5"
      />
    </svg>
  );
}

export default PhoneIcon;
