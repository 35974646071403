import { React, useEffect, useState } from 'react';
// import Pricing from '../components/Pricing';

import axios from 'axios';
import backgroundImage from '../Images/Background.png';
import blueStar from '../Images/blue-star.png';
import whiteStar from '../Images/white-star.png';
import Layout from '../component/Layout';
// import { useNavigate } from 'react-router-dom';
import { API_URL } from '../constants/api';
import usePaystackPayment from '../hooks/paystack';

const fetcher = async (url, authDetails) => {
  const res = await axios.get(url, authDetails);
  return res.data;
};

function MonthlyPricing() {
  const { isLoading, error, initiatePaystackPayment } = usePaystackPayment();
  const [plans, setPlans] = useState(null);

  const monthlyPlans = [
    { name: 'Free', amount: 0 },
    { name: 'Standard', amount: 29.55 },
    { name: 'Premium', amount: 49.99 },
  ];

  const handlePayment = (amount) => {
    initiatePaystackPayment(amount);
  };

  useEffect(() => {
    (async () => {
      try {
        const pricingPlans = await axios.get(`${API_URL}/subscription-plans/`);

        setPlans(pricingPlans?.data);
      } catch (error) {
        console.log(error);
        return error;
      }
    })();
  }, []);

  // console.log('Pricing', plans);

  return (
    <Layout className="">
      <div
        className="bg-cover bg-center h-100 w-100 mb-[5rem]"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <section className="flex flex-col gap-10 items-center justify-center mt-12">
          {/* <div className="flex gap-0 justify-center items-center w-2/5  rounded-lg  h-[3rem] max-[1174px]:w-1/2 max-[768px]:w-[70%] max-[500px]:w-[80%] max-[400px]:w-[95%]">
            <Link
              to="/MonthlyPricing"
              className="bg-[#1B72FC] rounded-lg  w-1/2  h-full outline-none text-center  cursor-pointer  text-[#ffffff] relative left-2"
            >
              <input
                type="submit"
                className="h-full cursor-pointer"
                value="Monthly"
              />{' '}
            </Link>
            <Link
              to="/YearlyPricing"
              className="bg-[#DAEBFF] rounded-lg  w-1/2  h-full outline-none cursor-pointer text-center text-[#1B72FC] border-[#1B72FC]"
            >
              <input
                type="submit"
                className="h-full cursor-pointer"
                value="Yearly"
              />{' '}
            </Link>
          </div> */}
          <div className="flex gap-20 flex-wrap items-center justify-center">
            <div className=" border border-[#1B72FC] w-72 h-96 rounded-md flex items-center flex-col gap-5 justify-center bg-[#1B72FC] text-[white]">
              <div className="flex justify-center">
                <img src={whiteStar} alt="" className="" />
              </div>
              <div className="">
                <ul className="flex flex-col justify-center items-center text-sm leading-10">
                  <li>Unlimited</li>
                  <li>Unlimited</li>
                  <li>Unlimited Chats</li>
                  <li>Unlimited Agents</li>
                  <li>Monthly</li>
                </ul>
                <p className="text-[1.5rem] font-bold">Free</p>
              </div>
              {/* <PaymentForm planName={"Monthly"} planAmount={29.95} /> */}
              <button
                onClick={() => handlePayment('0.0')}
                className="border-2 bg-[#1B72FC] py-2 px-5 text-[white] rounded-md"
              >
                Get Started
              </button>

              {/* <a href="" onClick={initiatePaystackPayment} className='border-2 border-[white] py-2 px-5 text-[white]'>Get Started</a> */}
            </div>

            <div className=" border border-[#1B72FC] w-72 h-96 rounded-md flex items-center flex-col gap-5 justify-center ">
              <div className="flex justify-center gap-4">
                <img src={blueStar} alt="" className="" />
                <img src={blueStar} alt="" className="" />
              </div>
              <div className="">
                <ul className="flex flex-col justify-center items-center text-sm leading-10">
                  <li>Unlimited</li>
                  <li>Unlimited</li>
                  <li>Unlimited Chats</li>
                  <li>Unlimited Agents</li>
                  <li>
                    <span className="text-[2rem]">$29.</span>
                    <span>55</span>
                  </li>
                  <li>Monthly</li>
                </ul>
              </div>
              <button
                onClick={() => handlePayment('2.9')}
                className="border-2 bg-[#1B72FC] py-2 px-5 text-[white]"
              >
                Get Started
              </button>
            </div>

            <div className=" border-2 border-[#1B72FC] w-72 h-96 rounded-md flex items-center flex-col gap-5 justify-center">
              <div className="flex justify-center relative gap-10">
                <img src={blueStar} alt="" className="absolute bottom-2" />
                <img src={blueStar} alt="" className="" />
                <img src={blueStar} alt="" className="" />
              </div>
              <div className="">
                <ul className="flex flex-col justify-center items-center text-sm leading-10">
                  <li>Unlimited</li>
                  <li>Unlimited</li>
                  <li>Unlimited Chats</li>
                  <li>Unlimited Agents</li>
                  <li>
                    <span className="text-[2rem]">$29.</span>
                    <span>55</span>
                  </li>
                  <li>Monthly</li>
                </ul>
              </div>
              <button
                onClick={() => handlePayment('5.9')}
                className="border-2 bg-[#1B72FC] py-2 px-5 text-[white]"
              >
                Get Started
              </button>

              {/* <a href="" className=' bg-[#1B72FC] py-2 px-5 text-center self-center text-[white]'>Get Started</a> */}
            </div>

            {plans?.map((plan) => (
              <div className=" border-2 border-[#1B72FC] w-72 h-96 rounded-md flex items-center flex-col gap-5 justify-center">
                <div className="flex justify-center relative gap-10">
                  <img src={blueStar} alt="" className="absolute bottom-2" />
                  <img src={blueStar} alt="" className="" />
                  <img src={blueStar} alt="" className="" />
                </div>
                {plan?.name && (
                  <p className="uppercase text-lg font-medium">{plan?.name}</p>
                )}
                <div className="">
                  <ul className="flex flex-col justify-center items-center text-sm leading-10">
                    <li>Unlimited</li>
                    <li>Unlimited</li>
                    <li>Unlimited Chats</li>
                    <li>Unlimited Agents</li>
                    <li>
                      <span className="text-[2rem]">{plan?.price}</span>
                      {/* <span>55</span> */}
                    </li>
                    <li>{plan?.duration}</li>
                  </ul>
                </div>
                <button
                  onClick={() => handlePayment('5.9')}
                  className="border-2 bg-[#1B72FC] py-2 px-5 text-[white]"
                >
                  Get Started
                </button>

                {/* <a href="" className=' bg-[#1B72FC] py-2 px-5 text-center self-center text-[white]'>Get Started</a> */}
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default MonthlyPricing;
