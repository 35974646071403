import Skeleton from '@mui/material/Skeleton';

function PersonnelCardSkeleton() {
  return (
    <div className="flex items-center">
      <div className="mr-2">
        <Skeleton variant="circular" width={40} height={40} />
      </div>
      <div className="flex flex-col">
        <Skeleton variant="text" width={100} height={20} />
        <Skeleton variant="text" width={180} height={16} />
      </div>
    </div>
  );
}
export default PersonnelCardSkeleton;
