import axios from 'axios';
import { useState } from 'react';
import { API_URL } from '../../constants/api';
import SearchIcon from '../utils/icons/SearchIcon';
import PersonnelSearch from './PersonnelSearch';

const Search = () => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchValues, setSearchValues] = useState(0);
  const [searchItems, setSearchItems] = useState([]);

  const searchInput = document.getElementById('search-input');

  const searchInvoke = async (e) => {
    let results;

    try {
      if (e.target.value && e.target.value.length >= 1) {
        setIsSearching(true);
        setSearchValues(e.target.value.length);
        // results = await searchAction(e?.target?.value);

        results = await axios.get(
          `${API_URL}/user/search/${e?.target?.value}/`
        );
        console.log(results);
      }

      if (e.target.value.length === 0) {
        setSearchValues(0);
        setSearchItems([]);
      }
    } catch (e) {
      console.log(e);
    }

    if (results?.data) {
      console.log(results);
      setSearchItems(results?.data);
    } else {
      setSearchItems([]);
    }

    if (e.target.value.length === 0) {
      setSearchItems([]);
    }
  };

  return (
    <div className="relative hidden sm:flex items-center w-[28rem] max-w-[24rem] justify-start py-[0.2rem] px-[1rem] border rounded-lg bg-white">
      <SearchIcon height={'20px'} width={'20px'} />
      <input
        id="search-input"
        type="text"
        className="border-none outline-none w-full bg-transparent ml-2 p-2"
        placeholder="Search email"
        onChange={(e) => searchInvoke(e)}
      />
      <PersonnelSearch
        searchValues={searchValues}
        isSearching={isSearching}
        setIsSearching={setIsSearching}
        searchInput={searchInput}
        searchItems={searchItems}
      />
    </div>
  );
};

export default Search;
