import axios from 'axios';
import React, { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import backgroundImage from '../Images/Background.png';
import Layout from '../component/Layout';
import { UserContext } from '../context/UserContext';
import Signup from '../routes/Signup';

function SignUp() {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [changeIcon1, setIcon1] = useState(false);
  const [changeIcon2, setIcon2] = useState(false);

  const logindata = useContext(UserContext);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
    setIcon2(!changeIcon2);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
    setIcon1(!changeIcon1);
  };

  const [isRegistered, setIsRegistered] = useState(false);

  const history = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  //   console.log(email, password1);

  function validatePassword(password) {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).{8,}$/;
    return regex.test(password);
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      email,
      password: password1,
    };

    const checkPassword = validatePassword(password1);

    if (password1 === password2) {
      if (checkPassword) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password1);

        try {
          const response = await axios.post(`/api/v1/auth/register/`, data);
          console.log(response);
          toast.success('Successfully registered');
          // logindata.tokenHandler(response?.data?.access);
          // logindata.userHandler(response?.data?.user);
          localStorage.setItem('refresh_token', response?.data?.refresh);
          localStorage.setItem('access_token', response?.data?.access);
          setLoading(false);
          history('/company/setup');
        } catch (error) {
          console.log(error);
          setLoading(false);
          toast.error('something went wrong');
          if (error.response) {
            console.error(error.response.status);
            console.error(error.response.headers);
          } else if (error.request) {
            console.error(error.request);
          } else {
            console.error('Error', error.message);
          }
        }
      } else {
        setLoading(false);
        toast.error('Please choose a strong password');
      }
    } else {
      setLoading(false);
      toast.error('Passwords must match');
    }
  };

  return (
    <>
      {!isRegistered ? (
        <Layout>
          <form
            onSubmit={handleSubmit}
            className=" mt-40 bg-cover bg-center h-100 w-100 mb-[7rem]"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <div className="flex flex-col gap-10 justify-center items-center ">
              <div className="flex gap-0 justify-center items-center w-[50%]  rounded-lg  h-[3rem] max-[1174px]:w-1/2 max-[768px]:w-[70%] max-[500px]:w-[80%] max-[400px]:w-[95%]">
                <Link
                  to="/signup"
                  className="bg-[#1B72FC] rounded-lg  w-1/2  h-full outline-none text-center  cursor-pointer  text-[#ffffff] relative left-2"
                >
                  <input
                    type="submit"
                    className="h-full cursor-pointer"
                    value="Register"
                  />{' '}
                </Link>
                <Link
                  to="/signin"
                  className="bg-[#DAEBFF] rounded-lg  w-1/2  h-full outline-none cursor-pointer text-center text-[#1B72FC] border-[#1B72FC]"
                >
                  <input
                    type="submit"
                    className="h-full cursor-pointer"
                    value="Sign In"
                  />{' '}
                </Link>
              </div>
              <div className="w-[50%] flex flex-col  max-[1174px]:w-1/2 max-[768px]:w-[68%] max-[500px]:w-[77%] max-[400px]:w-[93%]">
                <label htmlForfor="#" className="text-[#000] font-medium">
                  Email
                </label>

                <input
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  required
                  className="bg-[#DAEBFF] rounded-md  outline-none px-2  h-[3rem] max"
                />
              </div>
              <div className="w-[50%]  flex flex-col  max-[1174px]:w-1/2 max-[768px]:w-[68%] max-[500px]:w-[77%] max-[400px]:w-[93%] relative">
                <label htmlFor="#" className="text-[#000] font-medium">
                  Password
                </label>
                <input
                  type={showPassword1 ? 'text' : 'password'}
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  className="bg-[#DAEBFF] rounded-md w-full h-[3rem] outline-none px-2"
                />

                {showPassword1 ? (
                  <FaEye
                    onClick={togglePasswordVisibility1}
                    className="text-gray-400 h-5 w-5 mx-2 cursor-pointer self-end absolute bottom-3"
                  >
                    {showPassword1 ? 'Hide' : 'Show'} Password1;
                  </FaEye>
                ) : (
                  <FaEyeSlash
                    onClick={togglePasswordVisibility1}
                    className="text-gray-400 h-5 w-5 mx-2 cursor-pointer self-end absolute bottom-3"
                  >
                    {showPassword1 ? 'Hide' : 'Show'} Password1;
                  </FaEyeSlash>
                )}
                {/* <FaEye className="absolute bottom-3.5 self-end right-2"></FaEye> */}
              </div>
              <div className="w-[50%]  flex flex-col  max-[1174px]:w-1/2 max-[768px]:w-[68%] max-[500px]:w-[77%] max-[400px]:w-[93%] relative">
                <label htmlForfor="#" className="text-[#000] font-medium">
                  Confirm Password
                </label>
                <input
                  type={showPassword2 ? 'text' : 'password'}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  required
                  className="bg-[#DAEBFF] rounded-md w-full h-[3rem] outline-none px-2"
                />
                {showPassword2 ? (
                  <FaEye
                    onClick={togglePasswordVisibility2}
                    className="text-gray-400 h-5 w-5 mx-2 cursor-pointer self-end absolute bottom-3"
                  >
                    {showPassword2 ? 'Hide' : 'Show'} Password2;
                    {changeIcon2 ? 'FaEye' : 'FaEyeSlash'}
                  </FaEye>
                ) : (
                  <FaEyeSlash
                    onClick={togglePasswordVisibility2}
                    className="text-gray-400 h-5 w-5 mx-2 cursor-pointer self-end absolute bottom-3"
                  >
                    {showPassword2 ? 'Hide' : 'Show'} Password2;
                    {changeIcon2 ? 'FaEye' : 'FaEyeSlash'}
                  </FaEyeSlash>
                )}
              </div>

              <button
                type="submit"
                className="text-[white] rounded-md w-[50%] h-[3rem] outline-none px-2 bg-[#1B72FC] cursor-pointer  max-[1174px]:w-1/2 max-[768px]:w-[68%] max-[500px]:w-[77%] max-[400px]:w-[93%]"
                value="Sign Up"
              >
                {loading ? <SyncLoader color="white" /> : 'Sign Up'}
              </button>
            </div>
            {/* <div className="relative h-96 mb-16 mt-16">
              <img src={assetImg} alt="" className="absolute right-0" />
            </div> */}
          </form>
        </Layout>
      ) : (
        <Signup />
      )}
    </>
  );
}

export default SignUp;
