let socketHandler = null;
const connect = (conversationId) => {
//   console.log(token)
    const socketUrl = `wss://api.andromedia.cc/ws/chat/${conversationId}/`;
    socketHandler = new WebSocket(socketUrl);

    return new Promise((resolve, reject) => {
        socketHandler.addEventListener('open', () => {
            console.log("Socket connected ==> " + socketUrl);
            socketHandler.send(JSON.stringify({conversationId }));
            resolve(socketHandler);
        });

        socketHandler.addEventListener('message', (event) => {
            console.log('Message from server:', event.data);
        });

        socketHandler.addEventListener('close', (event) => {
            // Handle WebSocket close event
            console.log(`Socket closed with code: ${event.code}, reason: ${event.reason}`);
        });

        socketHandler.addEventListener('error', (error) => {
            reject(error);
        });
    });
};


const disconnect = () => {
    if (socketHandler && socketHandler.readyState === WebSocket.OPEN) {
        socketHandler.close();
        console.log("Socket disconnected");
    }
};

export { socketHandler, connect, disconnect };
