import React, { useState } from 'react';

function PasswordReset() {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const resetEndpoint = 'https://andromedia-api-64b890199b07.herokuapp.com/docs/schema/swagger-ui/';

    try {
      const response = await fetch(resetEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        // Handle success (e.g., show a success message)
        console.log('Password reset email sent successfully.');
      } else {
        // Handle errors (e.g., display an error message)
        console.error('Password reset request failed.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
}

export default PasswordReset;
