import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsFunnelFill } from 'react-icons/bs';
import { SyncLoader } from 'react-spinners';
import { API_URL } from '../constants/api';
import { config } from '../constants/auth_actions';
import { ContentHeader } from './ContentHeader';
import {
  TableAgents,
  TableContactList,
  TableContacts,
  TableConversations,
} from './TableItems';
import NoData from './utils/svgs/NoData';
import NoDataSvg from './utils/svgs/NoDataSvg';

const access_token = localStorage.getItem('access_token');
const current_company_id = localStorage.getItem('current_company_id');

const TablesConversations = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ['conversations'],
    queryFn: () =>
      axios
        .get(
          `${API_URL}/conversations/company/${current_company_id}`,
          config(access_token)
        )
        .then((res) => res),
  });

  // console.log(data);

  if (isLoading)
    return (
      <div className="flex items-center justify-center">
        <SyncLoader />
      </div>
    );

  if (error)
    return (
      <div className="flex items-center justify-center">
        <NoDataSvg />
      </div>
    );

  if (data?.data?.length === 0)
    return (
      <div className="flex items-center justify-center">
        <NoDataSvg />
      </div>
    );

  return (
    <>
      <div className="overflow-auto w-full px-2 py-4">
        <ContentHeader />
        <table className="table-auto w-full text-sm">
          <thead className="">
            <tr className="">
              <th scope="col" className=""></th>
              <th scope="col" className="text-[8px] text-left text-[#17106B]">
                ID
              </th>
              <th scope="col" className="text-[8px] text-[#17106B]">
                <div className="text-left flex flex-row items-center">
                  Customer
                </div>
              </th>
              {/* <th scope="col" className="text-[8px] text-[#17106B]">
                <div className="text-left flex flex-row items-center">
                  Agent
                </div>
              </th> */}
              <th scope="col" className="text-[8px] text-left text-[#17106B]">
                Status
              </th>
              <th scope="col" className="text-[8px] text-right text-[#17106B]">
                Time
              </th>
            </tr>
          </thead>

          <tbody>
            <TableConversations conversations={data?.data} />
          </tbody>
        </table>
      </div>
    </>
  );
};
const TablesConversationsMobile = ({ name }) => {
  return (
    <>
      <div className=" overflow-auto w-full mb-[20px] mt-[20px] md:hidden">
        <div className="flex items-center justify-between">
          <h3 className="text-[#344266] text-[24px]  font-bold">{name}</h3>
          <div className="flex items-center gap-4">
            <AiOutlineSearch style={{ color: '#3B8CF7', fontSize: '16px' }} />
            <BsFunnelFill style={{ color: '#3B8CF7', fontSize: '16px' }} />
          </div>
        </div>
        <hr className="border-1 border-[#3B8CF7] " />
      </div>
    </>
  );
};

const TablesAgents = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ['agents'],
    queryFn: () =>
      axios
        .get(
          `${API_URL}/companies/${current_company_id}/teammates/`,
          config(access_token)
        )
        .then((res) => res),
  });
  // console.log('DAT', error);

  if (isLoading)
    return (
      <div className="flex justify-center py-5">
        <SyncLoader />
      </div>
    );

  if (error)
    return (
      <div className="flex items-center justify-center">
        <NoDataSvg />
      </div>
    );

  if (data?.data?.length === 0)
    return (
      <div className="flex items-center justify-center">
        <NoDataSvg />
      </div>
    );

  return (
    <div className="overflow-auto w-full">
      <table className="table-auto w-full text-sm my-[1rem]">
        <thead>
          <tr className="">
            {/* <th scope="col" className="flex text-sm text-left font-medium">
              Country
            </th> */}
            <th scope="col" className="text-sm text-left font-medium">
              ID
            </th>
            <th scope="col" className="text-sm text-left font-medium">
              Agent Name
            </th>
            <th scope="col" className="text-sm text-left font-medium">
              Email
            </th>
            <th
              scope="col"
              className="text-sm text-right md:text-left font-medium"
            >
              Role
            </th>
            {/* <th scope="col" className="text-sm text-right font-medium">
              Time & Date
            </th> */}
          </tr>
        </thead>
        <tbody>
          <TableAgents data={data?.data} />
        </tbody>
      </table>
    </div>
  );
};

const TableConversations1 = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ['inbox_conversations'],
    queryFn: () =>
      axios
        .get(
          `${API_URL}/conversations/company/${current_company_id}`,
          config(access_token)
        )
        .then((res) => res),
  });

  console.log(data);

  if (isLoading)
    return (
      <div className="flex items-center justify-center">
        <SyncLoader />
      </div>
    );

  if (error)
    return (
      <div className="flex items-center justify-center -mt-10">
        <NoDataSvg height={'500'} width={'391'} />
      </div>
    );

  if (data?.data?.length === 0)
    return (
      <div className="flex items-center justify-center -mt-10">
        <NoDataSvg height={'500'} width={'391'} />
      </div>
    );

  return (
    <>
      <div className=" overflow-auto hidden md:block">
        <ContentHeader />

        <table className="table-auto w-full text-sm">
          <thead className="">
            <tr className="">
              <th scope="col" className=""></th>
              <th scope="col" className="text-left font-medium">
                ID
              </th>
              <th scope="col" className="text-left font-medium">
                <div className="text-left flex flex-row items-center">
                  Customer
                </div>
              </th>
              <th scope="col" className="text-left font-medium">
                Status
              </th>
              <th scope="col" className="text-right font-medium">
                Time & Date
              </th>
            </tr>
          </thead>

          <tbody>
            <TableConversations conversations={data?.data} />
          </tbody>
        </table>
      </div>
      {/* Mobile  */}
      {/* <div className="">
        <TablesConversationsMobile name={'Conversations'} />
        <ConversationsMobile />
      </div> */}
    </>
  );
};

const TablesContacts = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ['customers'],
    queryFn: () =>
      axios
        .get(
          `${API_URL}/companies/${current_company_id}/customers/`,
          config(access_token)
        )
        .then((res) => res),
  });

  // console.log('error', error);

  if (isLoading)
    return (
      <div className="flex justify-center py-5">
        <SyncLoader />
      </div>
    );

  if (error)
    return (
      <div className="flex items-center justify-center -mt-10">
        <NoDataSvg height={'500'} width={'391'} />
      </div>
    );

  if (data?.data?.length === 0)
    return (
      <div className="flex items-center justify-center -mt-10">
        <NoDataSvg height={'500'} width={'391'} />
      </div>
    );

  return (
    <div className="mb-[2rem] overflow-auto">
      <table className="table-auto w-full text-sm">
        <thead>
          <tr className="">
            {/* <th scope="col" className="text-sm font-[500] text-left">
              Country
            </th>
            <th scope="col" className="text-sm font-[500] text-left">
              ID
            </th> */}
            <th scope="col" className="text-sm font-[500] text-left">
              Customer Name
            </th>
            <th scope="col" className="text-sm font-[500] text-left">
              Email Address
            </th>
            <th scope="col" className="text-sm font-[500] text-left">
              Time & Date
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <TableContacts contacts={data?.data} />
        </tbody>
      </table>
    </div>
  );
};

const TablesContactList = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ['customers'],
    queryFn: () =>
      axios
        .get(
          `${API_URL}/companies/${current_company_id}/customers/`,
          config(access_token)
        )
        .then((res) => res),
  });

  if (isLoading)
    return (
      <div className="flex justify-center py-5">
        <SyncLoader />
      </div>
    );

  if (error)
    return (
      <div className="flex items-center justify-center -mt-10">
        <NoData />
      </div>
    );

  if (data?.data?.length === 0)
    return (
      <div className="flex items-center justify-center mb-24">
        <NoData />
      </div>
    );

  if (isLoading)
    return (
      <div className="flex justify-center">
        <SyncLoader />
      </div>
    );

  return (
    <div className=" overflow-auto">
      <table className="table-auto w-full text-sm">
        <thead>
          <tr className="text-sm">
            <th scope="col" className=" self-start"></th>
            <th scope="col" className="text-[8px]">
              Name
            </th>
            <th scope="col" className="text-[8px]">
              Email
            </th>
            {/* <th scope="col" className="text-[8px]">
              Phone Number
            </th> */}
          </tr>
        </thead>

        <tbody>
          <TableContactList contacts={data?.data} />
        </tbody>
      </table>
    </div>
  );
};

export {
  TableConversations1,
  TablesAgents,
  TablesContactList,
  TablesContacts,
  TablesConversations,
  TablesConversationsMobile,
};
