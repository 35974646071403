import React from 'react';
import BillingPlans from '../../components/BillingPlans';
import data from '../../data/plans.json';
import Dashboards from '../Dashboards';

const history = [
  {
    date: 'Sept 01 2023',
    plan: 'Compact',
    price: 500,
  },
  {
    date: 'Sept 01 2023',
    plan: 'Compact',
    price: 500,
  },
];
function Billing() {
  return (
    <Dashboards>
      <div className="flex flex-col gap-4 w-[90%] px-4">
        <div className="flex self-end gap-3 mt-2">
          <button className="border border-[#3B8CF7] rounded-lg text-[#3B8CF7] w-24 p-2">
            Cancel
          </button>
          <button className="border bg-[#3B8CF7] text-[#fff] rounded-lg w-24 p-2">
            Save
          </button>
        </div>

        <div className="flex gap-3">
          {data.map((item, index) => (
            <>
              <BillingPlans
                index={index}
                type={item.type}
                value={item.value}
                duration={item.duration}
                lists={item.lists}
              />
            </>
          ))}
        </div>
        <div>
          <h2 className="text-[#818485] mt-4">
            You are currently on{' '}
            <span className="text-[#3B8CF7] font-bold">Compact Plan</span>
          </h2>
          <p className="text-[#818485] text-[10px] ">Expires in 12d 13hr</p>
        </div>

        <h2 className="text-[#818485] text-[20px] mt-4 font-bold ">
          Billing History
        </h2>

        {history.map((item) => (
          <div className="flex justify-between">
            <h2 className="text-[#818485]">{item.date}</h2>
            <h2 className="text-[#818485]">{item.plan}</h2>
            <h2 className="text-[#818485]">${item.price}</h2>
          </div>
        ))}
      </div>
    </Dashboards>
  );
}

export default Billing;
