import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import avatar from '../avatar-default-svgrepo-com 2.svg';
import useChat from '../hooks/chat';
import { createCustomer } from '../services/companies';
import {
  createConversationMessages,
  createConversations,
  getConversationsMessages,
} from '../services/conversations';
import { connect, disconnect, socketHandler } from '../services/socket';

const messageQueue = [];

const PhoneUI = ({ file, name, token }) => {
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [chat, addChat] = useState('');
  const messagesContainerRef = useRef(null);
  const { messages, addMessage } = useChat();
  const [isFormVisible, setFormVisible] = useState(true);
  const [conversation, setConversation] = useState([]);
  useEffect(() => {
    getConversationsMessages(10)
      .then((response) => {
        console.log(response);
        setConversation(response);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const sendMessageThroughSocket = (socket, message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
      console.log('sending message', message);

      return true; // Message sent successfully
    } else {
      console.log('websocket connection is not open');
      // WebSocket connection is not open, add the message to the queue
      messageQueue.push(message);
    }
  };

  const data = {
    image: { avatar },
    name: name,
  };

  useEffect(() => {
    connect(10)
      .then((socket) => {
        socket.addEventListener('open', handleSocketOpen);
        socket.addEventListener('message', handleSocketMessage);
        // socket.addEventListener("message", (event) => {
        //     console.log("Message from server ", event.data);
        // });
        socket.addEventListener('close', handleSocketClose);
        socket.addEventListener('error', (error) => {
          console.error('WebSocket Error:', error);
        });
      })
      .catch((error) => {
        console.error('WebSocket Error:', error);
      });

    const handleSocketMessage = (event) => {
      const receivedData = JSON.parse(event.data);
      const innerMessage = JSON.parse(receivedData.message);
      console.log('receiving message', receivedData);
      const receivedMessage = {
        id: innerMessage.id,
        conversation: innerMessage.conversation,
        message: innerMessage.message,
        attachment: innerMessage.attachment,
        sender_type: innerMessage.sender_type,
        sender_id: innerMessage.sender_id,
        receiver_type: innerMessage.receiver_type,
        receiver_id: innerMessage.receiver_id,
        created_at: innerMessage.created_at,
        updated_at: innerMessage.updated_at,
      };
      console.log({ receivedMessage });

      addMessage((prevMessages) => [...prevMessages, receivedMessage]);
    };
    console.log({ messages });

    const handleSocketClose = (event) => {
      // console.log('Socket closed:', event);
    };
    //    const messageQueue = []
    const handleSocketOpen = () => {
      console.log('WebSocket connection opened.');

      // Send messages from the queue
      while (messageQueue.length > 0) {
        const message = messageQueue.shift();
        socketHandler.send(JSON.stringify(message));
      }
    };

    return () => {
      disconnect();
    };
  }, [10]);

  // const createCompanies = ()=>{
  //     const data={
  //         name:'Thedigit',
  //         website_url:'thedigit.com',
  //         region: "Nigeria",
  //         description:'hello world',
  //         init_message:'Test this ',
  //         color:'white',
  //         language:'igbo',
  //         number_of_agents:10029099990,
  //         size_of_firm: 10029099990,
  //         industry:'Tech',
  //         inquires_monthly:9823797923,
  //         account_state: "active",
  //         is_suspended:false
  //     }
  //     createCompany(data)
  //     .then(response=>{
  //     console.log({response})
  //     })
  //     .catch(( error) =>
  //     {
  //         console.log(error)
  //     } )
  //     .finally(()=>{
  //         console.log('done')
  //     })
  // }

  function createSession() {
    const data = {
      chat_status: 'active',
      session_key: '200',
      customer_location: 'home',
      company: 2,
      agent: 1,
      customer: 2,
    };
    createConversations(data)
      .then((response) => {
        console.log(response);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        console.log('done');
      });
  }

  // createSession()
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (email.trim() !== '' && userName.trim() !== '') {
      const data = {
        email,
        userName,
        company: 2,
      };
      createCustomer(data, 2)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));

      const welcomeMessage = {
        image: '',
        text: `Welcome, ${userName}! You can now start chatting. Your email is ${email}.`,
        time: new Date().toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
      };
      addMessage((oldArray) => [...oldArray, welcomeMessage]);
      setFormVisible(false);
    }
  };

  const sendMessage = () => {
    if (socketHandler && socketHandler.readyState === WebSocket.OPEN) {
      if (chat.trim() !== '') {
        const formattedCreatedAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const message2 = {
          type: 'typing',
          sender_type: 'user',
          conversation: '10',
        };
        const messageData = {
          message: chat,
          read: true,
          conversation: 10,
          sender_id: 10,
          receiver_id: 5,
          sender_type: 'user',
          receiver_type: 'customer',
        };
        createConversationMessages(messageData, 10)
          .then((response) => {
            console.log('Message sent successfully:', response);
          })
          .catch((error) => {
            console.error('Error sending message:', error);
          })
          .finally(() => {
            const message = {
              message: chat,
              conversation: 10,
              sender_id: 10,
              receiver_id: 5,
              sender_type: 'user',
              receiver_type: 'customer',
              created_at: formattedCreatedAt,
            };

            sendMessageThroughSocket(socketHandler, message);
            addMessage(message);
            addChat('');
          });
      }
    } else if (
      socketHandler &&
      socketHandler.readyState === WebSocket.CONNECTING
    ) {
      console.log('WebSocket is still connecting');
    } else {
      console.log('WebSocket is not connected');
    }
  };

  const allMessages = [...conversation, ...messages].sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  );
  console.log({ allMessages });
  const hasMessages = allMessages.length > 0;
  // useEffect(() => {
  //     scrollToBottom();
  // }, [allMessages]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };
  return (
    <div className="relative h-[90%] w-full flex justify-center items-center overflow-hidden ">
      <div className="bg-white  relative overflow-hidden box-border border-[8px] border-solid border-black shadow-[0_0_0_0_#333] w-[330px] h-[600px] rounded-[40px]">
        <div className="absolute bg-[#000] top-0 h-6 w-24 z-[100] left-[50%] translate-x-[-50%] rounded-br-[10px] rounded-bl-[10px] before:content-[''] before:absolute before:h-1 before:w-8 before:top-[7px] before:left-[10px] before:rounded-[10px] before:border-[0.03em] before:border-solid before:border-[rgba(51,51,51, 0.479)] before:shadow-inner-[0_0_0_0_rgba(51,51,51,0.712)] after:content-[''] after:absolute after:h-[7px] after:w-[7px] after:top-[5px] after:right-[20px] after:rounded-[50%] after:bg-[#333]"></div>
        <div className="flex flex-col h-full w-full">
          <div className="flex flex-col h-34">
            <div className="h-24 w-full bg-[color:var(--bg-color)] py-6">
              <div className="flex flex-row h-14 w-full items-center justify-around">
                <div className="w-12 h-12 border-[2px] border-[#fff] bg-[rgba(0,0,0,1)] rounded-full overflow-hidden">
                  <img src={file} alt="" className="" />
                </div>
                <div className="flex flex-col justify-between h-[80%] items-start">
                  <p className="text-[#fff] text-sm">you're chatting with</p>
                  <h2 className="text-[#fff] text-lg font-black">
                    {data.name}
                  </h2>
                </div>
                <div className="w-8 h-8">
                  <img src="call.svg" alt="" />
                </div>
                <div className="w-8 h-8">
                  <img src="hamburger-menu-dots-svgrepo-com.svg" alt="" />
                </div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="var(--bg-color)"
                fill-opacity="0.17"
                d="M0,224L80,224C160,224,320,224,480,192C640,160,800,96,960,69.3C1120,43,1280,53,1360,58.7L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
              ></path>
            </svg>
          </div>
          <div
            ref={messagesContainerRef}
            className="w-full relative min-h-[360px] max-h-[360px] flex flex-col content-start overflow-y-auto"
          >
            {allMessages.map((chatContent, index) => {
              const isSender = chatContent.sender_type === 'user'; // Check if the message is sent by the user
              const messageContent = isSender
                ? chatContent.message
                : chatContent.message; // Get message content based on sender type

              if (index === 0) {
                return (
                  <>
                    {!hasMessages && isFormVisible && (
                      <form onSubmit={handleFormSubmit} className="px-4">
                        <div className="mb-2">
                          <p className="font-bold text-[10px]">
                            Welcome to Andromedia, Fill in this form to get
                            started!
                          </p>
                          <label
                            htmlFor="email"
                            className="text-[#fff] text-sm"
                          >
                            Email:
                          </label>
                          <input
                            type="email"
                            id="email"
                            value={email}
                            placeholder="Input Your Email"
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full bg-[#DAEBFF] rounded-[10px] h-10 pl-2"
                            required
                          />
                        </div>
                        <div className="mb-2">
                          <label htmlFor="name" className="text-[#fff] text-sm">
                            Name:
                          </label>
                          <input
                            type="text"
                            id="name"
                            value={userName}
                            placeholder="Input Your username here"
                            onChange={(e) => setUserName(e.target.value)}
                            className="w-full bg-[#DAEBFF] rounded-[10px] h-10 pl-2"
                            required
                          />
                        </div>
                        <button
                          type="submit"
                          className="bg-blue-500 text-white py-1 px-3 rounded-md mt-2"
                        >
                          Start Chat
                        </button>
                      </form>
                    )}

                    {!isFormVisible && hasMessages && (
                      <div className="default-message">
                        Welcome, {userName}! You can now start chatting. Your
                        email is {email}.
                      </div>
                    )}
                  </>
                );
              } else {
                const createdAt = new Date(chatContent.created_at);

                const amOrPm = createdAt.getHours() >= 12 ? 'PM' : 'AM';
                const hours = createdAt.getHours() % 12 || 12;
                const formattedTime = `${hours}:${String(
                  createdAt.getMinutes()
                ).padStart(2, '0')}${amOrPm}`;
                return (
                  <div
                    className={`flex  px-3 ${
                      isSender ? 'justify-end ' : 'justify-start  '
                    } `}
                    key={chatContent.id}
                  >
                    <div
                      className={`message ${
                        isSender
                          ? 'bg-green-500 text-white'
                          : 'bg-gray-300 text-black'
                      } p-3 rounded-lg shadow-md mb-2 `}
                    >
                      {messageContent}
                      <p className="text-xs text-gray-500 w-auto">
                        {formattedTime}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="z-[110] bg-[#fff] w-full height-[40px] flex flex-row items-center justify-center px-[15px] pb-[10px] self-end justify-self-end">
            <div className="flex flex-row w-[100%] bg-[#DAEBFF] rounded-md pr-2">
              <input
                type="text"
                value={chat}
                onChange={(e) => {
                  addChat(e.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === 'Enter' && sendMessage();
                }}
                className="bg-[#DAEBFF] rounded-tl-[10px] rounded-bl-[10px] h-10 w-[100%] pl-2"
              />
              <div className="bg-[#DAEBFF] rounded-tr-[10px] rounded-br-[10px] w-[15px] h-10 py-2">
                <img src="Vector(13).svg" alt="" className="w-[14px]" />
              </div>
            </div>
            <button className="" onClick={sendMessage}>
              <img src="Send(2).svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneUI;
