import React from 'react';
import Layout from '../component/Layout';
import Header from './Applications/Header';
import MobilePhone from './Applications/MobilePhone';
import Products from './Applications/Products';

export default function Application() {
  return (
    <div>
      <Layout>
        <Header />
        <MobilePhone />
        <Products />
      </Layout>
    </div>
  );
}
