import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../constants/auth_actions';
import AndroWhiteIcon from './utils/icons/AndroWhiteIcon';

const NewFooter = () => {
  const history = useNavigate();

  return (
    <div className="bg-[#222121] w-full text-white text-lg px-3 lg:px-14 xl:px-44 pb-[2rem]">
      <div className="w-full flex flex-col md:grid grid-cols-4 gap-10 items-start justify-between py-10">
        <div className="flex flex-wrap">
          <div
            onClick={() => {
              scrollToTop();
              history('/');
            }}
            className="mb-7 -mt-2 flex items-center cursor-pointer"
          >
            <AndroWhiteIcon />

            <p className="text-[#fff] font-semibold text-lg mb-4">ANDROMEDIA</p>
          </div>
          <p className="font-normal mb-7 text-sm tracking-wider">
            With Andromedia, you can take your customer service to the next
            level. Engage with customers in real-time and provide instant
            support.
          </p>
        </div>

        <div className="z-10">
          <p className="mb-7 font-medium">COMPANY</p>
          <div className="flex flex-col gap-3">
            <p
              onClick={() => {
                scrollToTop();
                history('/about');
              }}
              className="text-sm cursor-pointer hover:text-[#0CADF8]"
            >
              About Us
            </p>
            <p
              onClick={() => {
                scrollToTop();
                history('/contact');
              }}
              className="text-sm cursor-pointer hover:text-[#0CADF8]"
            >
              Contact Us
            </p>
            <p
              onClick={() => {
                scrollToTop();
                history('/monthly-pricing');
              }}
              className="text-sm cursor-pointer hover:text-[#0CADF8]"
            >
              Pricing
            </p>
          </div>
        </div>

        <div className="z-10">
          <p className="mb-7 font-medium">HELP</p>
          <div className="flex flex-col gap-3">
            <p
              onClick={() => {
                scrollToTop();
                history('/application');
              }}
              className="text-sm cursor-pointer hover:text-[#0CADF8]"
            >
              Application
            </p>
            <p className="text-sm cursor-pointer hover:text-[#0CADF8]">
              Terms & Service
            </p>
            <p
              onClick={() => {
                scrollToTop();
                history('/privacy-policy');
              }}
              className="text-sm cursor-pointer hover:text-[#0CADF8]"
            >
              Privacy Policy
            </p>
          </div>
        </div>

        <div className="z-10">
          <p className="mb-14"></p>
          <input
            type="email"
            placeholder="Enter email"
            className="bg-white border-none outline-none text-sm p-3 text-[#828282]"
          />
          <button className="bg-[#15ACF5] text-white py-2 px-3 w-full rounded-lg my-2">
            Free Demo
          </button>
        </div>
      </div>

      <div className="border border-[#828282]"></div>
    </div>
  );
};

export default NewFooter;
