function AnalyticsIcon() {
  return (
    <svg
      width="60px"
      height="60px"
      viewBox="0 0 512 512"
      id="Layer_1"
      version="1.1"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <style type="text/css">.st0 fill:#232020;</style>

      <g>
        <path
          class="st0"
          d="M93.5,329.2c-38.7,0-67.9,14.3-67.9,33.3v52.6c0,19,29.2,33.3,67.9,33.3s67.9-14.3,67.9-33.3v-52.6   C161.4,343.6,132.2,329.2,93.5,329.2z M39.6,379.1c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3v17.2   c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4V379.1z M93.5,343.2c32.9,0,53.9,11.4,53.9,19.3v7.4   c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4v-7.4C39.6,354.7,60.6,343.2,93.5,343.2z M93.5,434.5   c-32.9,0-53.9-11.4-53.9-19.3v-9.8c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3v9.8C147.4,423,126.4,434.5,93.5,434.5z"
        />

        <path
          class="st0"
          d="M256,276.6c-38.7,0-67.9,14.3-67.9,33.3v105.2c0,19,29.2,33.3,67.9,33.3s67.9-14.3,67.9-33.3V309.9   C323.9,290.9,294.7,276.6,256,276.6z M309.9,369.9c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4v-17.2   c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3V369.9z M309.9,343.6c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4v-17.2   c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3V343.6z M202.1,379.1c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3v17.2   c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4V379.1z M256,290.6c32.9,0,53.9,11.4,53.9,19.3v7.4   c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4v-7.4C202.1,302.1,223.1,290.6,256,290.6z M256,434.5   c-32.9,0-53.9-11.4-53.9-19.3v-9.8c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3v9.8C309.9,423,288.9,434.5,256,434.5z"
        />

        <path
          class="st0"
          d="M418.5,224c-38.7,0-67.9,14.3-67.9,33.3v157.8c0,19,29.2,33.3,67.9,33.3s67.9-14.3,67.9-33.3V257.3   C486.4,238.3,457.2,224,418.5,224z M472.4,369.9c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4v-17.2   c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3V369.9z M472.4,343.6c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4v-17.2   c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3V343.6z M472.4,317.3c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4v-17.2   c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3V317.3z M472.4,291c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4v-17.2   c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3V291z M364.6,379.1c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3v17.2   c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4V379.1z M418.5,238c32.9,0,53.9,11.4,53.9,19.3v7.4   c-7.7,8.2-27.7,15.4-53.9,15.4s-46.1-7.2-53.9-15.4v-7.4C364.6,249.5,385.6,238,418.5,238z M418.5,434.5   c-32.9,0-53.9-11.4-53.9-19.3v-9.8c11.4,8.1,31.1,13.3,53.9,13.3s42.5-5.2,53.9-13.3v9.8C472.4,423,451.4,434.5,418.5,434.5z"
        />

        <path
          class="st0"
          d="M93.5,283.1c14.2,0,25.8-11.6,25.8-25.8c0-0.7,0-1.4-0.1-2l115.9-42.8c4.7,6.4,12.3,10.6,20.9,10.6   c14.2,0,25.8-11.6,25.8-25.8c0-2.8-0.4-5.4-1.2-7.9l121.1-80.5c4.5,3.9,10.4,6.2,16.8,6.2c14.2,0,25.8-11.6,25.8-25.8   s-11.6-25.8-25.8-25.8s-25.8,11.6-25.8,25.8c0,2.8,0.4,5.4,1.2,7.9l-121.1,80.5c-4.5-3.9-10.4-6.2-16.8-6.2   c-14.2,0-25.8,11.6-25.8,25.8c0,0.7,0,1.4,0.1,2l-115.9,42.8c-4.7-6.4-12.3-10.6-20.9-10.6c-14.2,0-25.8,11.6-25.8,25.8   S79.3,283.1,93.5,283.1z M418.5,77.5c6.5,0,11.8,5.3,11.8,11.8s-5.3,11.8-11.8,11.8s-11.8-5.3-11.8-11.8S412,77.5,418.5,77.5z    M256,185.6c6.5,0,11.8,5.3,11.8,11.8s-5.3,11.8-11.8,11.8c-5,0-9.3-3.2-11-7.6c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1   c-0.5-1.3-0.7-2.6-0.7-4C244.2,190.9,249.5,185.6,256,185.6z M93.5,245.5c6.5,0,11.8,5.3,11.8,11.8s-5.3,11.8-11.8,11.8   s-11.8-5.3-11.8-11.8S87,245.5,93.5,245.5z"
        />
      </g>
    </svg>
  );
}

export default AnalyticsIcon;
