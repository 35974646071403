import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import About from './components/About';
import CompanySettings from './components/CompanySettings';
import ContactPage from './components/ContactPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import UserSettings from './components/UserSettings';
import Application from './components/Application';
import CompanySetup from './components/company_setup/CompanySetup';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import MonthlyPricing from './pages/MonthlyPricing';
import PasswordReset from './pages/PasswordReset';
import Paystacks from './pages/Paystacks';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import YearlyPricing from './pages/YearlyPricing';
import Agents from './routes/pages/Agents';
import Billing from './routes/pages/Billing';
import Chat from './routes/pages/Chat';
import {
  default as ChatInbox,
  default as ChatTemplate,
} from './routes/pages/ChatTemplate';
import Contacts from './routes/pages/Contacts';
import Dashboard from './routes/pages/Dashboard';
import Inbox from './routes/pages/Inbox';
import Logout from './routes/pages/Logout';
import Profile from './routes/pages/Profile'; 
// import SignUp from "./components/SignUp";
// import SignIn from "./components/SignIn";
// import MonthlyPricing from "./components/MonthlyPricing";
// import YearlyPricing from "./components/YearlyPricing";

const access_token = localStorage.getItem('access_token');

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
  {
    path: '/signin',
    element: <SignIn />,
  },
  {
    path: '/monthly-pricing',
    element: <MonthlyPricing />,
  },

  {
    path: '/yearly-pricing',
    element: <YearlyPricing />,
  },
  {
    path: '/inbox',
    element: access_token ? <Inbox /> : <SignIn />,
  },
  {
    path: '/chat',
    element: <ChatTemplate />,
  },
  {
    path: '/contacts',
    element: access_token ? <Contacts /> : <SignIn />,
  },
  {
    path: '/agents',
    element: access_token ? <Agents /> : <SignIn />,
  },

  {
    path: '/dashboard',
    element: access_token ? <Dashboard /> : <SignIn />,
  },
  {
    // path: '/settings/*',
    // element: <Settings />,
    // children: [
    //   {
    //     path: '',
    //     element: <Web />,
    path: '/settings',
    element: access_token ? <CompanySettings /> : <SignIn />,
    children: [
      {
        path: '',
        element: access_token ? <Chat /> : <SignIn />, // Default component for /Settings route
      },
      {
        path: 'profile',
        element: access_token ? <Profile /> : <SignIn />,
      },
      {
        path: 'billing',
        element: <Billing />,
      },
    ],
  },
  {
    path: 'billing',
    element: <Billing />,
  },
  {
    path: 'user',
    element: <UserSettings />,
  },
  {
    path: 'company',
    element: <CompanySettings />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/chatinbox',
    element: <ChatInbox />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },

  {
    path: '/paystacks',
    element: <Paystacks />,
  },
  {
    path: '/passwordReset',
    element: <PasswordReset />,
  },
  {
    path: '/company/setup',
    element: access_token ? <CompanySetup /> : <SignUp />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/contact',
    element: <ContactPage />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/application',
    element: <Application />,
  },
]);

function App() {
  return (
    <>
      {/* <AddAgentForm /> */}
      <RouterProvider router={router} />
    </>
  );
}

export default App;
