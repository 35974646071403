import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ContentHeader1 } from '../../components/ContentHeader';
import { TablesAgents } from '../../components/Tables';
import AddAgentForm from '../../components/utils/cards/AddAgentForm';
import { API_URL } from '../../constants/api';
import { config } from '../../constants/auth_actions';
import Dashboards from '../Dashboards';

const Agents = () => {
  const [modalState, setModalState] = useState(false);
  const access_token = localStorage.getItem('access_token');

  const { data: loggedinAgent } = useQuery({
    queryKey: ['conversations'],
    queryFn: () =>
      axios
        .get(`${API_URL}/auth/user/`, config(access_token))
        .then((res) => res),
  });

  const handleNewAgent = () => {
    if (loggedinAgent?.data?.role === 'agent') {
      console.log('not permitted');
    } else {
      localStorage.setItem('user_modal', 'AGENTFORM');
      const user_modal = localStorage.getItem('user_modal');
      if (user_modal === 'AGENTFORM') {
        setModalState(true);
      }
    }
  };

  const handleClose = () => {
    localStorage.removeItem('user_modal');
    const user_modal = localStorage.getItem('user_modal');
    if (!user_modal || user_modal !== 'AGENTFORM') {
      setModalState(false);
    }
  };

  useEffect(() => {
    const user_modal = localStorage.getItem('user_modal');
    if (user_modal === 'AGENTFORM') {
      setModalState(true);
    } else {
      setModalState(false);
    }
  }, []);

  return (
    <Dashboards>
      <div className="flex flex-col md:flex-row w-full px-[24px] md:px-[10px] justify-around">
        <div className="flex flex-col justify-between md:w-[65%] mt-[30px] md:mt-[60px]">
          <div>
            <ContentHeader1 />
            <TablesAgents />
          </div>
          <button
            onClick={handleNewAgent}
            className="h-[41px] w-full bg-[#DAEBFF] rounded-[10px] text-[#17106B] font-['Poppins'] text-sm leading-[18px] mb-[15px] font-medium"
          >
            + Add New Agent
          </button>
          {/* <button className="text-[#656375] font-['Poppins'] font-[700] text-[12px] leading-[18px]">
            See All
          </button> */}
        </div>
        <div className="flex flex-col md:w-[20%] md:mt-[30px] gap-5">
          {/* <div className="hidden md:flex flex-row h-1/8 bg-grey gap-2 justify-end items-center">
            <h5 className="text-[#818485] font-['Poppins'] leading-[30px] font-medium">
              Agent: <span className="text-[#3592FF]">Admin</span>
            </h5>
            <img src="Vector(2).svg" alt="" className="h-7 w-7" />
          </div> */}

          <div className="flex flex-row flex-wrap items-start justify-around">
            <div className="flex flex-col items-center gap-2 justify-center m-[15px]">
              <p className="text-[#17106B] text-sm font-medium">Total Agents</p>
              <h1 className="text-[#17106B] font-['Poppins'] font-semibold text-xl md:text-4xl leading-[72px] -mt-2 md:mt-0">
                105
              </h1>
            </div>

            <div className="flex flex-col items-center gap-2 justify-center m-[15px]">
              <p className="text-[#17106B] text-sm font-medium">Active</p>
              <h1 className="text-[#17106B] font-['Poppins'] font-semibold text-xl md:text-4xl leading-[72px] -mt-2 md:mt-0">
                105
              </h1>
            </div>

            <div className="flex flex-col items-center gap-2 justify-center m-[15px]">
              <p className="text-[#17106B] text-sm font-medium">Engaged</p>
              <h1 className="text-[#17106B] font-['Poppins'] font-semibold text-xl md:text-4xl leading-[72px] -mt-2 md:mt-0">
                105
              </h1>
            </div>

            <div className="flex flex-col items-center gap-2 justify-center m-[15px]">
              <p className="text-[#17106B] text-sm font-medium">Offline</p>
              <h1 className="text-[#17106B] font-['Poppins'] font-semibold text-xl md:text-4xl leading-[72px] -mt-2 md:mt-0">
                105
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      {/* <div className="w-full px-[20px] md:hidden block mb-[100px]">
        <TablesConversationsMobile name={'Agents'} />
        <AgentsMobile />
        <button
          onClick={handleNewAgent}
          className="h-[41px] w-full rounded-[10px] mt-5 text-[#17106B] border border-[#DAEBFF] font-[700] text-sm leading-[18px] mb-[15px]"
        >
          + Add New Agent
        </button>
      </div> */}
      <AddAgentForm modalState={modalState} handleClose={handleClose} />
    </Dashboards>
  );
};

export default Agents;
