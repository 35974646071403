import { Alert } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { SyncLoader } from 'react-spinners';
import { API_URL } from '../../constants/api';

const CompanyDetails = ({ handleStep, image }) => {
  // console.log(JSON.parse(localStorage.getItem('chat_details')));
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log(image);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('access_token');
    const chatJSON = localStorage.getItem('chat_details');
    const chat = JSON.parse(chatJSON);
    console.log(chat);

    const uploadConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const bodyFormData = new FormData();
    bodyFormData.append('name', chat?.name);
    bodyFormData.append('image', image);
    bodyFormData.append('website_url', chat?.website_url);
    bodyFormData.append('color', chat?.color);
    bodyFormData.append('language', chat?.language);
    bodyFormData.append('number_of_agents', e.target[0].value);
    bodyFormData.append('size_of_firm', e.target[1].value);
    bodyFormData.append('industry', e.target[2].value);
    bodyFormData.append('inquiries_monthly', e.target[3].value);
    bodyFormData.append('init_message', e.target[4].value);

    // console.log('image', bodyFormData.getAll('image'));

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_URL}/companies/create`,
        bodyFormData,
        uploadConfig
      );
      console.log(response);
      setLoading(false);
      localStorage.setItem('current_company_id', response?.data?.id);
      handleStep(2);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('An error occured');
    }
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="flex flex-col justify-center w-full md:w-[90vw] lg:w-[55vw] h-full my-[5rem] gap-[2rem] px-2"
    >
      <p className="text-[#344266] font-semibold text-2xl text-center">
        Enter your company details
      </p>

      <div className="flex flex-col md:flex-row items-end gap-2">
        <div className="w-full">
          <p className="font-medium mb-1 text-[#344266]">Number of Agents</p>
          <input
            // required
            type="number"
            className="bg-[#DAEBFF] rounded-md border-none p-2"
          />
        </div>

        <div className="w-full">
          <p className="font-medium mb-1 text-[#344266]">Size of Firm</p>
          <input
            // required
            type="number"
            className="bg-[#DAEBFF] rounded-md border-none p-2"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-end gap-2">
        <div className="w-full">
          <p className="font-medium mb-1 text-[#344266]">Industry</p>
          <input
            // required
            type="text"
            className="bg-[#DAEBFF] rounded-md border-none p-2"
          />
        </div>

        <div className="w-full">
          <p className="font-medium mb-1 text-[#344266] text-sm">
            How many inquiries do you get monthly?
          </p>
          <input
            // required
            type="number"
            className="bg-[#DAEBFF] rounded-md border-none p-2"
          />
        </div>
      </div>

      <div className="w-full">
        <p className="font-medium mb-1 text-[#344266]">Custom Message</p>
        <textarea
          required
          type="text"
          className="p-2 bg-[#DAEBFF] rounded-md border-none w-full h-[5rem] max-h-[5rem]"
        />
      </div>

      {error && (
        <Alert
          severity="error"
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
          }}
        >
          {error}
        </Alert>
      )}

      <div className="flex flex-col md:flex-row items-center gap-2 mt-10">
        {/* <button
          onClick={() => {
            handleStep(2);
          }}
          className="bg-[#DAEBFF] text-[#344266] font-semibold py-3 rounded-md w-full"
        >
          Skip
        </button> */}
        <button
          type="submit"
          className="bg-[#3592FF] font-semibold text-white py-3 rounded-md w-full"
        >
          {loading ? <SyncLoader /> : 'Continue'}
        </button>
      </div>

      <p className="font-semibold text-center text-[#344266]">Step 2/3</p>
    </form>
  );
};

export default CompanyDetails;
