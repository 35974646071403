import React, { useState } from 'react';
import useWidgetScript from '../../hooks/widgetScript';

function Chat() {
  const [scriptUrl, setScriptUrl] = useState('');
  const { scriptElement } = useWidgetScript(scriptUrl);

  const handleFormSubmit = (event, generatedScriptElement) => {
    event.preventDefault();

    console.log('Generating script widget with URL:', scriptUrl);
  };

  const handleInputChange = (event) => {
    setScriptUrl(event.target.value);
  };

  return (
    <div className='chat-widget'>
      <h2>Chat Widget</h2>
      <form onSubmit={(event) => handleFormSubmit(event, scriptElement)}>
        <label>
          Enter Script URL:
          <input
            type='text'
            onChange={handleInputChange}
            required
          />
        </label>
        <button type='submit'>Generate Script Widget</button>
      </form>

      {scriptElement && (
        <div dangerouslySetInnerHTML={{ __html: scriptElement.outerHTML }} />
      )}
    </div>
  );
}

export default Chat;
