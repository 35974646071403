import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Avatar } from '@mui/material';
import React, { useRef } from 'react';

function CompanyImagePreview({
  selectedImageUrl,
  setSelectedImageUrl,
  setSelectedImage,
  image,
}) {
  const fileInputRef = useRef(null);
  const handleImageChange = (e) => {
    const file = e.target.files;
    setSelectedImage(file);
    if (file) {
      const imageUrl = URL.createObjectURL(file[0]);

      setSelectedImageUrl(imageUrl);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="h-[250px] w-[250px] rounded-full border-[2px] m-auto relative">
      <Avatar
        src={selectedImageUrl || image}
        alt=""
        sx={{ height: '250px', width: '250px' }}
      />

      <div className="absolute h-full w-full left-0 cursor-pointer top-0 rounded-full flex items-end p-[1rem] justify-center">
        <input
          required
          type="file"
          accept=".png, .jpg, .jpeg"
          ref={fileInputRef}
          onChange={handleImageChange}
          className="bg-[#DAEBFF] rounded-md border-none text-sm hidden"
        />
        <div
          onClick={handleIconClick}
          className="bg-gray-200 py-1 px-2 rounded-md shadow-lg"
        >
          <CameraAltIcon />
        </div>
      </div>
    </div>
  );
}

export default CompanyImagePreview;
