import axios from "axios";

// export const API_URL = 'https://api.andromedia.cc/api/v1/';
export const API_URL = 'https://django-andromedia-api-a0c055964407.herokuapp.com/api/v1/';
// const socketUrl = `wss://ws/chat/${conversationId}/`;

export default function init() {
    axios.defaults.baseURL = API_URL;
    axios.defaults.withCredentials = false;

    const authToken = localStorage.getItem('access_token'); 

    if (authToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    } else {
       
    }
}
