import React, { useState } from 'react';

export default function BillingPlans({ type, duration, value, lists, index }) {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  return (
    <div
      key={index}
      className={`px-3 py-4 shadow-lg w-2/3 flex items-center flex-col rounded-lg h-64`}
      style={{
        backgroundColor: index === 0 ? '#5CA8FF' : 'white',
        color: index === 0 ? 'white' : '#5CA8FF',
      }}
    >
      <h2 className='text-[25px] font-[700]' style={{
        color: index === 0 ? 'white' : '#3B8CF7',
      }}>{type}</h2>
      <div className="flex">
        <p>${value}</p> / <span>{duration}</span>

      </div>
      <hr className='w-full m-2' />
      <ul className='list-disc'>
        {lists.map((item, itemIndex) => (
          <li key={itemIndex}>{item}</li>
        ))}
      </ul>
    </div>
  );
}
