import Dashboards from '../Dashboards';
import ChatTemplate from '../../components/ChatTemplate';
const ChatInbox = () => {
  return (
    <Dashboards>
    <div className='flex w-full'>
      <div className='flex flex-col w:md-[60%] w-full md:mt-[80px]  px-[20px]'>
      </div>
      <div className="md:flex flex-col w-[40%] px-12 justify-between hidden ">
        <ChatTemplate />
      </div>
    </div>
    </Dashboards>
  )
}

export default ChatInbox
