import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import Layout from '../component/Layout';
import { API_URL } from '../constants/api';

function ForgotPassword() {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem('Username');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const password_state = localStorage.getItem('password_state');

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  function validatePassword(password) {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).{8,}$/;
    return regex.test(password);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setMessage('Please enter your email address.');
      setTimeout(() => {
        setMessage(null);
      }, 20000);
      return;
    }

    try {
      setLoading(true);
     const response = await axios.post(`${API_URL}/auth/password_reset/`, {
        email,
      });

      console.log(response);

      localStorage.setItem('password_state', true);

      setLoading(false);
      toast.success('An email has been sent to you.');
      console.log(response);
    } catch (err) {
      console.log('error', err);
      setLoading(false);

      if (err?.response?.status === 500) {
        toast.error('Email does not exist');
      }
      setMessage('An error occured.Please try again.');
      setTimeout(() => {
        setMessage(null);
      }, 20000);
      return err;
    }
  };

  const changePassword = async (event) => {
    event.preventDefault();

    const data = {
      verification_code: otp,
      password: password,
    };

    // console.log(data);
    const checkPassword = validatePassword(password);

    if (password === confirmPassword) {
      if (checkPassword) {
        localStorage.setItem('email', email);

        try {
          setLoading(true);
          await axios.post(`${API_URL}/auth/confirm_password/`, data);

          setLoading(false);
          localStorage.removeItem('password_state');
          toast.success('Your password has been changed successfully');
          navigate('/SignIn');
        } catch (error) {
          setLoading(false);
          toast.error('something went wrong');
          if (error.response) {
            console.error(error.response.status);
            console.error(error.response.headers);
          } else if (error.request) {
            console.error(error.request);
          } else {
            console.error('Error', error.message);
          }
          // console.log(error);
        }
      } else {
        setLoading(false);
        toast.error('Please choose a strong password');
      }
    } else {
      setLoading(false);
      toast.error('Passwords must match');
    }
  };

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    }
  }, [userEmail]);

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center min-h-screen">
        {!password_state && (
          <div className="w-80 md:w-96 px-4 py-8 mx-2 md:mx-0 bg-white shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Reset password
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4 flex flex-col">
                <label htmlFor="email" className="text-gray-700">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="andromedia@gmail.com"
                  className="border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                />
              </div>

              <div className="flex items-center justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
                >
                  {loading ? (
                    <SyncLoader color="white" size={10} />
                  ) : (
                    'Continue'
                  )}
                </button>
              </div>
            </form>
            <p className="text-red-500 mt-2 text-center">{message}</p>
          </div>
        )}

        {password_state !== undefined && password_state && (
          <div className="w-80 md:w-96 px-4 py-8 mx-2 md:mx-0 bg-white shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Enter your new password
            </h2>
            <form onSubmit={changePassword}>
              <div className="mb-4 flex flex-col">
                <label htmlFor="email" className="text-gray-700">
                  Otp:
                </label>
                <input
                  type="number"
                  id="otp"
                  value={otp}
                  onChange={handleOtpChange}
                  placeholder="Enter the otp code sent to your email"
                  className="border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                />
              </div>

              <div
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                className="relative mb-4 flex flex-col"
              >
                <label htmlFor="email" className="text-gray-700">
                  New password:
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter a new password e.g 123*Tyla"
                  className="border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                />

                {isHovering && (
                  <span className="absolute bg-white -top-[4.5rem] lg:-top-16 xl:-top-20 right-0 p-2 shadow-md rounded-md text-gray-800">
                    Your password should have at least 8 characters, 1
                    lowercase, 1 uppercase, 1 number, 1 special character
                  </span>
                )}
              </div>

              <div className="mb-4 flex flex-col">
                <label htmlFor="email" className="text-gray-700">
                  Confirm password:
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Re-enter your password"
                  className="border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                />
              </div>

              <div className="flex items-center justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
                >
                  {loading ? (
                    <SyncLoader color="white" size={10} />
                  ) : (
                    'Change password'
                  )}
                </button>
              </div>
              <p className="text-center text-red-600 mt-2">
                Do not refresh this page
              </p>
            </form>
            <p className="text-red-500 mt-2">{message}</p>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default ForgotPassword;
