import { Alert } from '@mui/material';
import { useState } from 'react';
import { SyncLoader } from 'react-spinners';
import CheckIcon from '../utils/icons/CheckIcon';

const Setup = ({ handleStep, setImage }) => {
  const [color, setColor] = useState('from-purple-200 to-blue-600');
  const [selectedColor, setSelectedColor] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleColor = (col, selectedCol) => {
    setColor(col);
    setSelectedColor(selectedCol);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const bodyFormData = new FormData();
    console.log(e.target[1].files[0]);
    console.log(e.target);

    setImage(e.target[1].files[0]);
    const data = {
      name: e.target[0].value,
      image: e.target[1].files[0],
      website_url: e.target[2].value,
      color,
      language: e.target[3].value,
    };
    if (data?.color) {
      try {
        setLoading(true);
        // const websiteResponse = await axios.get(`${data?.website_url}`);
        // console.log(websiteResponse);

        localStorage.setItem('chat_details', JSON.stringify(data));
        setLoading(false);
        handleStep(1);
      } catch (error) {
        setLoading(false);
        console.log(error);
        setError('An error occured, try again');
      }
    } else {
      setError('Every field must is required');
    }

    // bodyFormData.append('name', e.target[0].value);
    // bodyFormData.append('image', e.target[1].files[0]);
    // bodyFormData.append('website_url', e.target[3].files[0]);
    // bodyFormData.append('color', color);
    // bodyFormData.append('language', e.target[4].value);

    // console.log('image', bodyFormData.getAll('image'));
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="flex flex-col justify-center w-full md:w-[90vw] lg:w-[35vw] h-full my-[2rem] gap-[2rem] px-2"
    >
      <p className="text-[#344266] font-semibold text-2xl text-center">
        Set Up Your Live Chat
      </p>

      <div>
        <p className="font-medium mb-1 text-[#344266]">Company Name</p>
        <input required className="bg-[#DAEBFF] rounded-md border-none p-2" />
      </div>

      <div className="flex items-center gap-2">
        <div
          onClick={() =>
            handleColor('bg-gradient-to-b from-purple-200 to-blue-600', 0)
          }
          className="h-[3rem] w-[3rem] rounded-[50%] bg-gradient-to-b from-purple-200 to-blue-600 shadow-md relative cursor-pointer"
        >
          {selectedColor === 0 && <CheckIcon />}
        </div>

        <div
          onClick={() =>
            handleColor('bg-gradient-to-b from-green-600 to-blue-400', 1)
          }
          className="h-[3rem] w-[3rem] rounded-[50%] bg-gradient-to-b from-green-600 to-blue-400 shadow-md relative cursor-pointer"
        >
          {selectedColor === 1 && <CheckIcon />}
        </div>

        <div
          onClick={() =>
            handleColor('bg-gradient-to-b from-blue-500 to-purple-800', 2)
          }
          className="h-[3rem] w-[3rem] rounded-[50%] bg-gradient-to-b from-blue-500 to-purple-800 shadow-md relative cursor-pointer"
        >
          {selectedColor === 2 && <CheckIcon />}
        </div>

        <div
          onClick={() =>
            handleColor('bg-gradient-to-b from-orange-500 to-red-700', 3)
          }
          className="h-[3rem] w-[3rem] rounded-[50%] bg-gradient-to-b from-orange-500 to-red-700 shadow-md relative cursor-pointer"
        >
          {selectedColor === 3 && <CheckIcon />}
        </div>

        <div
          onClick={() => handleColor('bg-[#183E6B]', 4)}
          className="h-[3rem] w-[3rem] rounded-[50%] bg-[#183E6B] shadow-md relative cursor-pointer"
        >
          {selectedColor === 4 && <CheckIcon />}
        </div>
      </div>

      <div className="flex items-end gap-2">
        <div className="flex-1">
          <p className="font-medium mb-1 text-[#344266]">Avatar</p>
          <input
            required
            type="file"
            accept=".png, .jpg, .jpeg"
            className="bg-[#DAEBFF] rounded-md border-none text-sm p-2"
          />
        </div>

        {/* <button className="bg-[#DAEBFF] px-2 py-2 rounded-md text-[#344266]">
          Upload
        </button> */}
      </div>

      <div>
        <p className="font-medium mb-1 text-[#344266]">Website url</p>
        <input required className="bg-[#DAEBFF] rounded-md border-none p-2" />
      </div>

      <div>
        <p className="font-medium mb-1 text-[#344266]">Language</p>
        <input required className="bg-[#DAEBFF] rounded-md border-none p-2" />
      </div>

      {error && (
        <Alert
          severity="error"
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
          }}
        >
          {error}
        </Alert>
      )}

      <button
        disabled={loading}
        type="submit"
        className="bg-[#3592FF] font-semibold text-white py-3 rounded-md"
      >
        {loading ? <SyncLoader color="white" size={10} /> : 'Continue'}
      </button>

      <p className="font-semibold text-center text-[#344266]">Step 1/3</p>
    </form>
  );
};

export default Setup;
