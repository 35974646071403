import { Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { SyncLoader } from 'react-spinners';
import { API_URL } from '../constants/api';
import { config } from '../constants/auth_actions';
import Dashboards from '../routes/Dashboards';
import ImagePreview from './utils/reusables/ImagePreview';

const UserSettings = () => {
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState();
  const [error, setError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loading, setLoading] = useState(false);
  const access_token = localStorage.getItem('access_token');

  const { data: user } = useQuery({
    queryKey: ['conversations'],
    queryFn: () =>
      axios
        .get(`${API_URL}/auth/user/`, config(access_token))
        .then((res) => res),
  });

  const handleSubmit = async (data) => {
    data.preventDefault();
    setLoading(true);

    if (data.target[0].value) {
      const bodyFormData = new FormData();
      bodyFormData.append('first_name', data.target[0].value);
      bodyFormData.append('last_name', data.target[1].value);
      bodyFormData.append('email', data.target[2].value);
      if (selectedImage?.length > 0) {
        bodyFormData.append('image', selectedImage[0]);
      }

      try {
        await axios.patch(
          `${API_URL}/auth/user/`,
          bodyFormData,
          config(access_token)
        );

        toast.success('Your profile has been updated successfully');
      } catch (error) {
        setError('An error occured while updating your profile');
        // console.log(error);

        setTimeout(() => {
          setError(null);
        }, 10000);
      }
    }

    if (data.target[4].value) {
      const passwordData = {
        old_password: data.target[3].value,
        new_password: data.target[4].value,
      };

      try {
        await axios.post(
          `${API_URL}/auth/change-password/`,
          passwordData,
          config(access_token)
        );

        toast.success('Your password has been changed');
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.message?.includes('is incorrect')) {
          setPasswordError(error?.response?.data?.message);
        } else {
          setPasswordError('An error occured while changing your password');
        }

        setTimeout(() => {
          setPasswordError(null);
        }, 10000);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    document.getElementById('email').value = user?.data?.email;
    document.getElementById('first_name').value = user?.data?.first_name;
    document.getElementById('last_name').value = user?.data?.last_name;
  }, [user]);

  return (
    <Dashboards>
      <div className="flex flex-col justify-around items-center lg:items-start lg:flex-row w-full">
        <div className="w-[30%] my-[4rem]">
          <ImagePreview
            selectedImageUrl={selectedImageUrl}
            setSelectedImageUrl={setSelectedImageUrl}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            image={user?.data?.image}
          />

          {user?.data?.first_name && (
            <p className="text-center font-semibold text-2xl mt-3 text-[#344266]">
              {`${user?.data?.first_name} ${user?.data?.last_name}`}
            </p>
          )}
        </div>

        <form
          onSubmit={(e) => handleSubmit(e)}
          className="flex flex-col justify-center w-full md:w-[65vw] lg:w-[45vw] xl:w-[40vw] h-full my-[2rem] gap-[2rem] px-2 mx-auto"
        >
          <div className="flex flex-col gap-5">
            <p className="text-[#344266] font-semibold text-xl text-left">
              User details
            </p>

            <div className="flex items-center justify-between gap-2">
              <div>
                <p className="font-medium mb-1 text-[#344266] text-sm">
                  First Name
                </p>
                <input
                  id="first_name"
                  required
                  className="bg-[#DAEBFF] rounded-md border-none p-2"
                />
              </div>

              <div>
                <p className="font-medium mb-1 text-[#344266] text-sm">
                  Last Name
                </p>
                <input
                  id="last_name"
                  required
                  className="bg-[#DAEBFF] rounded-md border-none p-2"
                />
              </div>
            </div>

            <div>
              <p className="font-medium mb-1 text-[#344266] text-sm">Email</p>
              <input
                id="email"
                disabled
                required
                className="bg-[#DAEBFF] rounded-md border-none p-2"
              />
            </div>
            {error && (
              <Alert
                severity="error"
                style={{
                  marginTop: '0.5rem',
                  marginBottom: '0.5rem',
                }}
              >
                {error}
              </Alert>
            )}
          </div>

          <div className="flex flex-col gap-5">
            <p className="text-[#344266] font-semibold text-xl text-left">
              Password reset
            </p>

            <div>
              <p className="font-medium mb-1 text-[#344266] text-sm">
                Old Password
              </p>
              <input
                id="old_password"
                type="password"
                className="bg-[#DAEBFF] rounded-md border-none p-2"
              />
            </div>

            <div>
              <p className="font-medium mb-1 text-[#344266] text-sm">
                New Password
              </p>
              <input
                id="new_password"
                type="password"
                className="bg-[#DAEBFF] rounded-md border-none p-2"
              />
            </div>
          </div>

          {passwordError && (
            <Alert
              severity="error"
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              {passwordError}
            </Alert>
          )}

          <button
            disabled={loading}
            type="submit"
            className="bg-[#3592FF] font-semibold text-white py-3 rounded-md w-full"
          >
            {loading ? <SyncLoader color="white" size={10} /> : 'Save'}
          </button>
        </form>
      </div>
    </Dashboards>
  );
};

export default UserSettings;
