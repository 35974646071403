function HeartOutlineIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 68 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.4877 28.9302L50.4905 28.9267C51.2469 27.9863 51.9767 26.1755 51.9086 24.2153C51.8378 22.1787 50.9022 19.9799 48.333 18.442L48.3312 18.4409C43.9912 15.8552 39.8604 17.8834 37.7635 20.093C36.9514 20.9181 35.6592 21.4052 34.2849 21.4188C32.9136 21.4323 31.6043 20.9732 30.745 20.0943C28.6444 17.8799 24.5166 15.8595 20.1266 18.4377L20.1266 18.4377L20.1171 18.4434C17.5595 19.9838 16.6375 22.1879 16.5729 24.2229C16.5107 26.1821 17.2394 27.9915 17.9884 28.9256L17.9913 28.9292L33.4421 48.0167L34.2183 48.9756L34.9958 48.0177L50.4877 28.9302ZM1 31C1 14.5247 15.6948 1 34 1C52.3052 1 67 14.5247 67 31C67 47.4753 52.3052 61 34 61C15.6948 61 1 47.4753 1 31Z"
        stroke="black"
        stroke-width="2"
      />
    </svg>
  );
}

export default HeartOutlineIcon;
