import React from 'react';
import PhoneUI from '../../components/PhoneUI';
import { TableConversations1 } from '../../components/Tables';
import Dashboards from '../Dashboards';

const Inbox = () => {
  return (
    <Dashboards>
      <div className="flex flex-col-reverse lg:flex-row w-full h-full">
        <div className="flex flex-col w-full md:mt-20 px-[20px]">
          <TableConversations1 />
        </div>
        <div className="flex flex-col lg:w-[40%] px-12 justify-between h-full">
          {/* <PhoneUI /> */}
          <PhoneUI />
        </div>
      </div>
    </Dashboards>
  );
};

export default Inbox;
