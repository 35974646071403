import React, { useState } from "react"
import { PaystackButton } from "react-paystack"
import "../../src/App.css"
import backgroundImage from '../Images/Background.png';

const Paystacks = ({ price, courseId, course }) => {

  const publicKey = "pk_test_4fc00f3df93a5f8efeb57bdd70605937312a029e"
  const amount = 1000000
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      setEmail("")
      setName("")
      setPhone("")
    },
    // onClose: () => alert("Wait! You need this oil, don't go!!!!"),
  }

  return (
    <div className="App bg-cover bg-center h-100 w-100" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container">
        <div className="item">
          <div className="overlay-effect"></div>
         
          <div className="item-details">
            <p className="item-details__title">Subscription</p>
            <p className="item-details__amount">NGN {amount / 100}</p>
          </div>
        </div>
        <div className="checkout">
          <div className="checkout-form">
            <div className="checkout-field">
              <label>Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="checkout-field">
              <label>Email</label>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="checkout-field">
              <label>Phone</label>
              <input
                type="text"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <PaystackButton className="paystack-button" {...componentProps} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Paystacks;