const SelectComponent = ({
  disabled,
  label,
  error,
  role,
  setRole,
  elements,
}) => {
  return (
    <div className="grid gap-1">
      <p className="text-gray-800 font-medium">{label}</p>
      <div className="grid gap-1">
        <div
          className={
            error
              ? 'relative flex items-center bg-gray-100 border border-red-600 rounded-sm mb-4 p-1'
              : 'relative flex items-center bg-gray-100 border rounded-lg mb-4 p-1'
          }
        >
          <select
            className="w-full py-2 px-3 outline-none bg-inherit rounded-sm no-number-arrows"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            disabled={disabled}
          >
            {elements?.map((element, index) => (
              <option key={index}>{element}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SelectComponent;
