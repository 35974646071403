function AndroWhiteIcon({ color }) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 201 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M185 125C189.552 125 191.828 125 193.762 124.144C196.187 123.07 198.369 120.668 199.205 118.151C199.871 116.144 199.674 114.103 199.28 110.022C196.612 82.3886 186.618 56.4955 170.711 36.6117C151.957 13.1696 126.522 2.50291e-06 100 0C73.4784 -2.50291e-06 48.043 13.1696 29.2893 36.6116C13.3822 56.4955 3.38766 82.3885 0.720084 110.022C0.326131 114.103 0.129155 116.144 0.79535 118.151C1.63095 120.668 3.81292 123.07 6.23808 124.144C8.17157 125 10.4477 125 15 125C19.7046 125 22.0569 125 23.7525 124.365C26.2363 123.435 27.7783 122.092 29.0408 119.76C29.9026 118.168 30.2782 115.461 31.0294 110.047C33.4793 92.3912 40.2295 75.9695 50.5025 63.1282C63.6301 46.7187 81.4349 37.5 100 37.5C118.565 37.5 136.37 46.7187 149.497 63.1282C159.771 75.9695 166.521 92.3912 168.971 110.047C169.722 115.461 170.097 118.168 170.959 119.76C172.222 122.092 173.764 123.435 176.247 124.365C177.943 125 180.295 125 185 125Z"
        fill={color ? color : 'white'}
      />
      <path
        d="M1 138C1 134.134 4.13401 131 8 131H101H152.5H177.5H190.5L201 127C201 130.59 198.09 133.5 194.5 133.5H96.5H45H33.2958C30.5531 133.5 27.9308 134.626 26.0427 136.616L6.17592 157.547C4.30986 159.513 1 158.192 1 155.482V138Z"
        fill={color ? color : 'white'}
      />
      <path
        d="M185.049 135.927C185.348 135.006 186.652 135.006 186.951 135.927L189.143 142.674C189.277 143.086 189.661 143.365 190.094 143.365H197.188C198.157 143.365 198.56 144.604 197.776 145.174L192.037 149.343C191.686 149.598 191.54 150.049 191.674 150.461L193.866 157.208C194.165 158.13 193.111 158.896 192.327 158.326L186.588 154.157C186.237 153.902 185.763 153.902 185.412 154.157L179.673 158.326C178.889 158.896 177.835 158.13 178.134 157.208L180.326 150.461C180.46 150.049 180.314 149.598 179.963 149.343L174.224 145.174C173.44 144.604 173.843 143.365 174.812 143.365H181.906C182.339 143.365 182.723 143.086 182.857 142.674L185.049 135.927Z"
        fill={color ? color : 'white'}
      />
    </svg>
  );
}

export default AndroWhiteIcon;
