import { useEffect, useState } from 'react';
import Footer from '../../component/Footer';
import Header from '../../component/Header';
import MonthlyPricing from '../../pages/MonthlyPricing';
import NewHeader from '../NewHeader';
import CompanyDetails from './CompanyDetails';
import ScriptCode from './ScriptCode';
import Setup from './Setup';

const CompanySetup = () => {
  const [setupStep, setSetupStep] = useState(0);
  const [image, setImage] = useState(null);

  const handleStep = (value) => {
    localStorage.setItem('setup_step', value);
    const step = localStorage.getItem('setup_step');
    setSetupStep(step);
  };

  const pageComponents = [Setup, CompanyDetails, ScriptCode, MonthlyPricing];
  const Page = pageComponents[setupStep];

  useEffect(() => {
    const step = localStorage.getItem('setup_step');
    setSetupStep(step || 0);
  }, []);

  return (
    <div>
      <div className="hidden md:block">
        <NewHeader />
      </div>
      <div className="block md:hidden">
        <Header />
      </div>

      <div className="h-[100%] w-[100%] bg-white flex items-center justify-center ">
        <Page handleStep={handleStep} image={image} setImage={setImage} />
      </div>
      {setupStep > pageComponents?.length && <Footer />}
    </div>
  );
};

export default CompanySetup;
