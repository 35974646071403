import React from 'react';
import Search from './search/Search';

const ContentHeader = ({ title }) => {
  return (
    <div className="flex flex-col lg:flex-row w-full justify-between px-[13px] items-center content-center mb-[3rem]">
      <h3 className="font-bold text-[#17106B] text-lg leading-[24px]">
        {title || 'Conversations'}
      </h3>
      <div className="flex flex-row justify-around gap-x-0.5">
        <input
          type="text"
          className=" bg-[#D9D9D9] rounded-[5px] h-[35px] w-[300px] py-[4px] px-[4px]"
        />
        <>
          <button className=" bg-[#17106B] rounded content-center items-center w-[39px] h-[35px]">
            <img src="Union.svg" alt="" className="ml-[12px]" />
          </button>
        </>
      </div>

      {/* <div className="flex flex-row items-center gap-1">
        <h5 className=" text-[9px] text-[#17106B] font-bold">Sort</h5>
        <img src="Vector(4).svg" alt="" />
      </div> */}
    </div>
  );
};

const ContentHeader1 = () => {
  return (
    <div className="flex flex-col gap-3 md:flex-row w-full justify-between px-[13px] items-center content-center mb-[2rem]">
      <h3 className="font-semibold text-[#17106B] font-['Poppins'] text-[16px] leading-[24px] self-center">
        Agents
      </h3>
      <Search />
      {/* <div className="flex flex-row justify-around gap-x-0.5">
        <input
          type="text"
          className=" bg-[#D9D9D9] rounded-[5px] h-[35px] w-[300px] py-[4px] px-[4px]"
        />
        <>
          <button className=" bg-[#17106B] rounded content-center items-center w-[39px] h-[35px]">
            <img src="Union.svg" alt="" className="ml-[12px]" />
          </button>
        </>
      </div> */}

      {/* <div className="flex flex-row">
        <h5 className=" text-sm font-bold">Sort</h5>
        <img src="Vector(4).svg" alt="" />
      </div> */}
    </div>
  );
};

export { ContentHeader, ContentHeader1 };
