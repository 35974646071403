import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function SidebarItem({ item, key }, { defaultActive }) {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(defaultActive || 1);
  const location = useLocation();
  const isActive = location.pathname === item.path;
  console.log({ isActive })
  if (item.childrens) {
    console.log(item.childrens);
    return (
      <div
        className={
          open
            ? 'sidebar-item open'
            : 'sidebar-item p-[5px] flex flex-col place-items-center  hover:bg-[#DAEBFF] rounded'
        }
      >
        <Link
          to={item.path}
          className={`sidebar-item plain flex flex-row p-[10px] items-center justify-between w-full hover:bg-[#DAEBFF] rounded ${isActive ? 'bg-[#DAEBFF] text-[#000000]' : ''}`}
        >
          <img src={item.icon} alt="" className="w-7 h-7" />
          <p className="font-semibold text-[#818485] font-['Poppins'] text-lg leading-[30px]">
            {item.title}
          </p>
          <img
            src="Polygon 1.svg"
            alt=""
            className="w-4 h-4 cursor-pointer duration-[0.3s] toggle-btn"
            onClick={() => setOpen(!open)}
          />
        </Link>

        <div className="sidebar-content h-0 overflow-hidden">
          <hr className=" -ml-0.5 h-0.5 w-80 bg-black-600" />
          {item.childrens.map((child, index) => (
            <Link to={child.path} key={index}
              className={`sidebar-item plain flex flex-row p-[10px] justify-between w-full hover:bg-[#DAEBFF] rounded ${isActive ? 'bg-[#DAEBFF] text-[#000000]' : ''}`}
            >
              <div className=" cursor-pointer hover:text-[#DAEBFF]">
                <p className="font-semibold text-[#818485] font-['Poppins'] text-[14px] leading-[21px] mt-[15px] self-center">
                  {child.title}
                </p>
                <hr className=" -ml-0.5 h-0.5 w-80 bg-black-600" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <Link
        to={item.path || '#'}
        className={`sidebar-item plain flex flex-row p-[10px] justify-between w-full hover:bg-[#DAEBFF] rounded ${isActive ? 'bg-[#DAEBFF] text-[#000000]' : ''}`}

        // className="sidebar-item plain  flex flex-row p-[10px] justify-between w-full  hover:bg-[#DAEBFF] rounded"
        active={key === activeIndex}
      >
        <img src={item.icon} alt="" className="w-7 h-7" />
        <p className="font-semibold self-center text-[#818485] font-['Poppins'] text-lg leading-[30px]">
          {item.title}
        </p>
        <p></p>
      </Link>
    );
  }
}
