import { Alert, Modal } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { SyncLoader } from 'react-spinners';
import { API_URL } from '../../../constants/api';
import { config } from '../../../constants/auth_actions';

const AddNewCustomer = ({ modalState, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const access_token = localStorage.getItem('access_token');
  const current_company_id = localStorage.getItem('current_company_id');

  const handleNewCustomer = async (e) => {
    e.preventDefault();

    const data = {
      name: e.target[0].value,
      email: e.target[1].value,
      company: current_company_id,
    };

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${API_URL}/companies/${current_company_id}/customers/add/`,
        data,
        config(access_token)
      );

      console.log(response);
      setIsLoading(false);
      toast.success('Successfully registered customer');
      handleClose();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setError('Something went wrong');
      return error;
    }
  };

  return (
    <Modal
      open={modalState}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="h-[100vh] w-[100vw] flex items-center justify-center"
    >
      <form
        onSubmit={handleNewCustomer}
        className="flex flex-col justify-between gap-2 h-[30rem] w-[85%] sm:w-[45%] md:h-[32rem] md:w-[40%] lg:w-[33%] xl:h-[30rem] xl:w-[26%] md:shadow-lg rounded-2xl bg-white border-none outline-none p-3 md:p-6"
      >
        <div className="flex flex-col gap-5">
          <p className="text-lg font-medium text-center">Add a new customer</p>
          <div className="w-full flex flex-col text-sm">
            <label for="name" className="text-gray-600 font-medium">
              Name
            </label>
            <input
              type="text"
              required
              className="bg-gray-100 rounded-md w-full h-[2.5rem] outline-none px-2"
            />
          </div>
          <div className="w-full flex flex-col text-sm">
            <label for="email" className="text-gray-600 font-medium">
              Email
            </label>
            <input
              type="email"
              required
              className="bg-gray-100 rounded-md w-full h-[2.5rem] outline-none px-2"
            />
          </div>

          {error && (
            <Alert
              severity="error"
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              {error}
            </Alert>
          )}
        </div>
        <button
          type="submit"
          className="h-[41px] w-full bg-[#3B8CF7] rounded-[10px] text-white font-['Poppins'] font-semibold leading-[18px] mb-[15px]"
        >
          {isLoading ? <SyncLoader /> : 'Add'}
        </button>
      </form>
    </Modal>
  );
};

export default AddNewCustomer;
