import React from 'react';
import { useNavigate } from 'react-router-dom';
import imgVector from '../Images/Vector1.png';

function Communicate() {
  const history = useNavigate();
  const access_token = localStorage.getItem('access_token');

  const getStarted = () => {
    if (access_token) {
      history('/company/setup');
    } else {
      history('/signin');
    }
  };

  return (
    <section className="text-center h-64 flex flex-col items-center justify-center gap-3 mt-10 max-[768px]:mt-24">
      <ul className="text-2xl md:text-4xl text-[#17106B] text-center">
        <li>
          Communicate With your Clients in <br />
          Real time With Our
        </li>
      </ul>
      <ul className="grid grid-rows-2 justify-center place-items-center content-center h-28">
        <ul className="flex flex-col align-center justify-around">
          <li className="font-medium text-[#3592FF] text-3xl max-[768px]:text-2xl max-[430px]:text-xl">
            Live Chat
          </li>
          <li className="text-center">
            <img
              src={imgVector}
              className="w-32 max-[768px]:w-24 "
              alt="vector1"
            />
          </li>
        </ul>
        <li>
          {' '}
          <button
            onClick={getStarted}
            className="bg-[#71BDFF] text-white rounded-md border-none outline-none py-3 px-3 mt-9 w-[10rem] font-medium"
          >
            Get Started
          </button>
        </li>
      </ul>
    </section>
  );
}
export default Communicate;
