import React from 'react';
import NewFooter from '../components/NewFooter';
import NewHeader from '../components/NewHeader';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <main className="w-full overflow-hidden">
      <div className="hidden md:block">
        <NewHeader />
      </div>
      <div className="block md:hidden">
        <Header />
      </div>
      {children}
      <NewFooter />
    </main>
  );
};

export default Layout;
