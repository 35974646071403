import React from 'react';
// import items from "../data/sidebar.json"
import { AiFillPieChart } from 'react-icons/ai';
import { IoIosChatboxes, IoMdSettings } from 'react-icons/io';
import { RiContactsBookFill } from 'react-icons/ri';
import BottombarItem from './BottombarItem';
// import {HiOutlineChatAlt2FiSettings} from 'react-icons/fi'
function MobileBottomNav() {
  const data = [
    {
      title: 'Dashboard',
      icon: AiFillPieChart,
      path: '/Dashboard',
    },
    {
      title: 'Conversation',
      icon: IoIosChatboxes,
      path: '/Inbox',
    },
    {
      title: 'Agents',
      icon: 'customer_support.png',
      path: '/Agents',
    },
    {
      title: 'Contact',
      icon: RiContactsBookFill,
      path: '/Contacts',
    },
    {
      title: 'Settings',
      icon: IoMdSettings,
      path: '/Settings',
    },
  ];

  // Converted array of objects
  return (
    <div class="fixed bottom-0 md:hidden block lg:hidden left-0 z-50 w-full h-26 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
      <div className="flex space-y-2 ">
        {data.map((item, index) => (
          <BottombarItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}

export default MobileBottomNav;
