import PersonnelSearchList from './PersonnelSearchList';
import PersonnelSearchSkeleton from './PersonnelSearchSkeleton';

const PersonnelSearch = ({
  searchValues,
  isSearching,
  setIsSearching,
  searchInput,
  searchItems,
}) => {
  // const searchItems = useSelector((state) => state.main.searchItems);
  console.log(searchItems);

  return (
    <>
      {isSearching && (
        <div className="flex items-start justify-start flex-col absolute top-[3rem] left-0 w-full max-w-[28rem] max-h-[400px] overflow-x-hidden rounded-lg bg-white z-50 shadow-md">
          {/* {searchItems?.length > 0 && ( */}
          <PersonnelSearchList
            searchItems={searchItems}
            setIsSearching={setIsSearching}
            searchInput={searchInput}
          />
          {/* )} */}

          {searchItems?.length === 0 && searchValues > 0 && (
            <PersonnelSearchSkeleton />
          )}
        </div>
      )}
    </>
  );
};

export default PersonnelSearch;
