import React from 'react'
import items from "../data/statistics.json"

function Statistics() {
    const itemsPerRow = 3;
  return (
    // <div>Statistics</div>
    <div class="grid grid-cols-3 mt-[30px] md:hidden ">
        {
            items.map((item, index)=>(
                <div className={`border-r-2 p-3 ${index > items.length - 4 ? '' : 'border-b-2'}`} key={item.title}>
                <p className='text-[12px] font-[poppins] text-center text-[#17106B] font-semibold'>{item.title}</p>
                <p className='text-[48px] font-bold font-[poppins] text-center text-[#17106B]'>{item.value}</p>
            </div>
    
            ))
        }
      </div>
  )
}

export default Statistics