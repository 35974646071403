import { Modal } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { SyncLoader } from 'react-spinners';
import { API_URL } from '../../../constants/api';
import { config } from '../../../constants/auth_actions';
import SelectInputField from '../reusables/SelectInputField';

const AddAgentForm = ({ agentEmail, modalState, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('Agent');
  const access_token = localStorage.getItem('access_token');
  const current_company_id = localStorage.getItem('current_company_id');

  const handleNewAgent = async (e) => {
    e.preventDefault();
    const data = {
      company: current_company_id,
      email: userEmail,
      role: userRole?.toLocaleLowerCase(),
    };

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${API_URL}/companies/${current_company_id}/teammates/add/`,
        data,
        config(access_token)
      );
      console.log(response);
      toast.success('Successfully registered');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error('something went wrong');
      console.log(error);
      return error;
    }
  };

  useEffect(() => {
    setUserEmail(agentEmail || '');
  }, [agentEmail]);

  return (
    <Modal
      open={modalState}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="h-[100vh] w-[100vw] flex items-center justify-center"
    >
      <form
        onSubmit={handleNewAgent}
        className="flex flex-col justify-between gap-2 h-[30rem] w-[85%] sm:w-[45%] md:h-[32rem] md:w-[40%] lg:w-[33%] xl:h-[30rem] xl:w-[26%] md:shadow-lg rounded-2xl bg-white border-none outline-none p-3 md:p-6"
      >
        <div className="flex flex-col gap-5">
          <p className="text-lg font-medium text-center">
            Add a new team member
          </p>
          <div className="w-full flex flex-col text-sm">
            <label for="email" className="text-gray-600 font-medium">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
              className="bg-gray-100 rounded-md w-full h-[2.5rem] outline-none px-2"
            />
          </div>
          <div className="w-full flex flex-col text-sm">
            <SelectInputField
              label={'Role'}
              role={userRole}
              setRole={setUserRole}
              elements={['Agent', 'Admin']}
            />
          </div>
        </div>
        <button
          type="submit"
          className="h-[41px] w-full bg-[#3B8CF7] rounded-[10px] text-white font-['Poppins'] font-semibold leading-[18px] mb-[15px]"
        >
          {isLoading ? <SyncLoader color="#fff" /> : 'Add'}
        </button>
      </form>
    </Modal>
  );
};

export default AddAgentForm;
