import axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from '../../../constants/api';
import { config } from '../../../constants/auth_actions';

const SelectComponent = () => {
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  const handleCategoryChange = (event) => {
    event.preventDefault();
    const newValue = event.target.value;
    setSelectedCompany(newValue);

    const company = companyList.find(
      (item) => item.name === event.target.value
    );

    localStorage.setItem('current_company_id', company?.id);
    window.location.reload();
  };

  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    const current_company_id = localStorage.getItem('current_company_id');

    (async () => {
      try {
        const userCompanies = await axios.get(
          `${API_URL}/companies/`,
          config(access_token)
        );

        setCompanyList(userCompanies?.data);

        if (current_company_id) {
          const userCompany = await axios.get(
            `${API_URL}/companies/${current_company_id}/`,
            config(access_token)
          );
          // console.log(userCompany);
          setSelectedCompany(userCompany?.data?.name);
        }

        if (current_company_id === undefined) {
          localStorage.setItem(
            'current_company_id',
            userCompanies?.data[0]?.id
          );

          const company = companyList.find(
            (item) => item.id === userCompanies?.data[0]?.id
          );
          setSelectedCompany(company?.name);
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    })();
  }, [companyList]);

  return (
    <div className="top-7 left-0 grid gap-2 mx-0 w-[10rem] md:w-[15rem]">
      <div className="flex justify-start">
        <select
          id="select"
          value={selectedCompany}
          onChange={(e) => handleCategoryChange(e)}
          className="w-full py-2 px-2 outline-none text-lg border md:border-2 bg-white border-[#3592FF] rounded-lg"
        >
          {companyList?.map((company, index) => (
            <option key={index}>{company?.name}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectComponent;
