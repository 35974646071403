function ChatbotIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 72 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.6 11.0625V12.0625H39.6H52.2C56.5977 12.0625 60.2 15.7174 60.2 20.2812V51.625C60.2 55.1633 57.3965 58 54 58H18C14.6035 58 11.8 55.1633 11.8 51.625V20.2812C11.8 15.7174 15.4023 12.0625 19.8 12.0625H32.4H33.4V11.0625V3.6875C33.4 2.17736 34.5835 1 36 1C37.4165 1 38.6 2.17736 38.6 3.6875V11.0625ZM28.8 48.9375H29.8V47.9375V44.25V43.25H28.8H21.6H20.6V44.25V47.9375V48.9375H21.6H28.8ZM39.6 48.9375H40.6V47.9375V44.25V43.25H39.6H32.4H31.4V44.25V47.9375V48.9375H32.4H39.6ZM50.4 48.9375H51.4V47.9375V44.25V43.25H50.4H43.2H42.2V44.25V47.9375V48.9375H43.2H50.4ZM1 44.25V29.5C1 27.9899 2.18354 26.8125 3.6 26.8125H6.2V46.9375H3.6C2.18354 46.9375 1 45.7601 1 44.25ZM19.7 29.5C19.7 32.5762 22.139 35.1094 25.2 35.1094C28.261 35.1094 30.7 32.5762 30.7 29.5C30.7 26.4238 28.261 23.8906 25.2 23.8906C22.139 23.8906 19.7 26.4238 19.7 29.5ZM41.3 29.5C41.3 32.5762 43.739 35.1094 46.8 35.1094C49.861 35.1094 52.3 32.5762 52.3 29.5C52.3 26.4238 49.861 23.8906 46.8 23.8906C43.739 23.8906 41.3 26.4238 41.3 29.5ZM65.8 46.9375V26.8125H68.4C69.8165 26.8125 71 27.9899 71 29.5V44.25C71 45.7601 69.8165 46.9375 68.4 46.9375H65.8Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
}

export default ChatbotIcon;
