import axios from 'axios';
import { parseError } from './mixin';
// import {setAuthHeaders} from './config'

const URL = 'conversations';

export const createConversationAPI = async (data) => {
  try {
    const response = await axios.post(`${URL}/create/`, data);
    console.log('create conversation', response);
    if (response.status === 200) {
      return response.data;
    }
    return parseError(response);
  } catch (error) {
    return parseError(error);
  }
};

export const getConversationAPI = async (company_id) => {
  try {
    const response = await axios.get(`${URL}/company/${company_id}`);
    // console.log('get', response.data);
    console.log('getConversations', response);
    if (response?.status === 200) {
      return response?.data;
    }
    return parseError(response);
  } catch (error) {
    return parseError(error);
  }
};

export const getConversationMessagesAPI = async (conversation_id) => {
  try {
    const response = await axios.get(`${URL}/${conversation_id}/messages/`);
    console.log('getMessagesCOnversation', response.data);
    console.log('getMessagesCOnversation', response);
    if (response.status === 200) {
      return response?.data;
    }
    return parseError(response);
  } catch (error) {
    return parseError(error);
  }
};
export const createConversationMessagesAPI = async (data, conversation_id) => {
  try {
    const response = await axios.post(
      `${URL}/${conversation_id}/messages/create/`,
      data
    );
    console.log('create message endpoint', response.data);
    if (response.status === 200) {
      return response.data;
    }
    return parseError(response);
  } catch (error) {
    return parseError(error);
  }
};

export const createCustomersAPI = async (data, company_id) => {
  try {
    const response = await axios.post(
      `${URL}/${company_id}/customers/add/`,
      data
    );

    console.log({ response });
    if (response.status === 200) {
      return response.data.data;
    }
    return parseError(response);
  } catch (error) {
    return parseError(error);
  }
};
export const getConversationApi = async (data, company_id) => {
  try {
    const response = await axios.get(
      `${URL}/${company_id}/conversations/`,
      data
    );
    console.log({ response });
    if (response.status === 200) {
      return response.data.data;
    }
    return parseError(response);
  } catch (error) {
    return parseError(error);
  }
};
export const getTeammatesApi = async (company_id) => {
  try {
    const response = await axios.get(`${URL}/${company_id}/teammates/`);
    console.log({ response });
    if (response.status === 200) {
      return response.data.data;
    }
    return parseError(response);
  } catch (error) {
    return parseError(error);
  }
};
export const createTeammatesApi = async (company_id) => {
  try {
    const response = await axios.get(`${URL}/${company_id}/teammates/add/`);
    console.log({ response });
    if (response.status === 200) {
      return response.data.data;
    }
    return parseError(response);
  } catch (error) {
    return parseError(error);
  }
};
