import { Avatar, ClickAwayListener } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from '../../constants/api';
import { config } from '../../constants/auth_actions';
import AddAgentForm from '../utils/cards/AddAgentForm';

const PersonnelSearchList = ({ searchItems, setIsSearching, searchInput }) => {
  const [modalState, setModalState] = useState(false);
  const [agentEmail, setAgentEmail] = useState('');
  const access_token = localStorage.getItem('access_token');

  const { data: loggedinAgent } = useQuery({
    queryKey: ['conversations'],
    queryFn: () =>
      axios
        .get(`${API_URL}/auth/user/`, config(access_token))
        .then((res) => res),
  });

  const handleClick = async (user) => {
    searchInput.value = '';
    // setIsSearching(false);

    if (loggedinAgent?.data?.role === 'agent') {
      console.log('not permitted');
      return;
    } else {
      setAgentEmail(user?.email);
      localStorage.setItem('user_modal', 'AGENTFORM');
      const user_modal = localStorage.getItem('user_modal');
      if (user_modal === 'AGENTFORM') {
        setModalState(true);
      }
    }
  };

  const handleClickAway = () => {
    setIsSearching(false);
  };

  const handleClose = () => {
    localStorage.removeItem('user_modal');
    const user_modal = localStorage.getItem('user_modal');
    if (!user_modal || user_modal !== 'AGENTFORM') {
      setModalState(false);
    }
  };

  useEffect(() => {
    const user_modal = localStorage.getItem('user_modal');
    if (user_modal === 'AGENTFORM') {
      setModalState(true);
    } else {
      setModalState(false);
    }
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="w-full h-full overflow-y-scroll no-scrollbar">
        {searchItems.length > 0 &&
          searchItems?.map((searchItem) => (
            <div
              key={searchItem?.id}
              onClick={() => handleClick(searchItem)}
              className="flex items-center justify-between p-2 cursor-pointer hover:bg-gray-50"
            >
              <div className="flex items-center gap-2">
                <Avatar src={searchItem?.image} alt="" />
                <div className="flex flex-col">
                  <p className="text-sm">{`${searchItem?.first_name} ${searchItem?.last_name}`}</p>

                  <p className="text-xs text-gray-500">{searchItem?.email}</p>
                </div>
              </div>
              <p className="text-xl font-bold text-gray-500">+</p>
            </div>
          ))}
        <AddAgentForm
          agentEmail={agentEmail}
          modalState={modalState}
          handleClose={handleClose}
        />
      </div>
    </ClickAwayListener>
  );
};

export default PersonnelSearchList;
