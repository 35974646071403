import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CopyIcon from '../utils/icons/CopyIcon';

const ScriptCode = () => {
  const history = useNavigate();
  const [textToCopy, setTectToCopy] = useState(
    '<script src="//code.andromedia.com/widget/fVywZnhxNT" async></script>'
  );

  function copyToClipboard() {
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea from the document
    document.body.removeChild(textarea);
  }

  return (
    <div className="flex flex-col justify-center w-full md:w-[90vw] lg:w-[55vw] h-full my-[5rem] gap-[2rem] px-2">
      <p className="text-[#344266] font-semibold text-2xl text-center">
        Add Live Chat to your Website
      </p>

      <div className="w-full">
        <p className="font-medium mb-1 text-[#344266]">
          Copy this javascript code to add widget to website
        </p>
        <textarea
          readOnly
          required
          type="text"
          value={textToCopy}
          className="p-2 bg-[#DAEBFF] rounded-md border-none w-full h-[5rem] max-h-[5rem]"
        />
      </div>

      <div className="flex items-between gap-2">
        <button
          onClick={copyToClipboard}
          className="w-full flex items-center py-2 bg-[#DAEBFF]"
        >
          <CopyIcon />
          <p>Copy To Clipboard</p>
        </button>

        {/* <button className="w-full flex items-center py-2 bg-[#DAEBFF]">
          <EmailIcon />
          <p>Send to Email</p>
        </button> */}
      </div>

      <button
        onClick={() => {
          localStorage.removeItem('setup_step');
          history('/dashboard');
        }}
        className="bg-[#3592FF] font-semibold text-white py-3 rounded-md w-full"
      >
        Continue
      </button>

      <p className="font-semibold text-center text-[#344266]">Step 3/3</p>
    </div>
  );
};

export default ScriptCode;
