import React from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { FaCrown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import SelectComponent from './utils/reusables/SelectComponent';

const Navbar = () => {
  // const { isLoading, error, initiatePaystackPayment } = usePaystackPayment();
  const history = useNavigate();

  const handleClick = () => {
    // Redirect to the new page when the button is clicked
    window.location.href = '/Pricing';
  };

  const handleSignout = () => {
    localStorage.clear();
    history('/');
  };

  return (
    <>
      <div className="hidden md:flex flex-row w-[100%] h-[92px] justify-between bg-[#fff] items-center px-[60px] border-b-[2px]">
        <div className="flex flex-row items-center">
          <img src="nanoChat-logo.svg" alt="" />
          <div className="flex flex-col justify-around h-[72px]">
            <h3 className="w-[96px] h-[26px] font-['Poppins'] font-[700] text-[32px] leading-[48px] text-[#17106B]">
              Nano
            </h3>
            <h4 className="w-[66px] h-[26px] font-['Poppins'] font-[700] text-[24px] leading-[36px] text-[#3592FF]">
              Chat
            </h4>
          </div>
        </div>

        <button
          onClick={handleSignout}
          className="bg-[#3592FF] rounded-[6px] text-white font-semibold px-3 py-2"
        >
          Sign out
        </button>
      </div>
      {/* Mobile */}
      <div className="md:hidden lg:hidden">
        <div className="flex  w-[100%] h-[92px]    justify-between bg-[#Fff] items-center px-[20px] border-b-[2px] shadow-lg">
          <img src="chat_logo.png" alt="" />
          <div
            onClick={() => handleClick()}
            className=" flex items-center gap-2 border border-[#3592FF] font-bold text-[10px] text-[#17106B] rounded-lg p-1"
          >
            <FaCrown style={{ fontSize: '24px', color: '#17106B' }} /> Upgrade
            to Premium
          </div>
          <div className="flex items-center">
            <BsFillPersonFill style={{ fontSize: '38px', color: '#17106B' }} />
            <AiFillCaretDown style={{ fontSize: '10px', color: '#17106B' }} />
          </div>
        </div>

        <div className="flex mt-[30px] px-[20px] justify-between">
          <div className="inline-block md:hidden">
            <SelectComponent />
          </div>
          {/* <div className="border flex gap-2 py-2  items-start justify-between border-[#3592FF]  text-[10px] rounded-lg px-5">
            <img
              src="website.png"
              className="w-[30px] h-full font-[#3592FF]"
              alt=""
            />
            <div className="flex flex-col items-start justify-center ">
              <p className="font-bold">Website:</p>
              <p className="text-[#3592FF]">nanocodes.com</p>
            </div>
          </div> */}
          {/* <div className="border py-2 flex gap-2 justify-between border-[#3592FF] text-[10px] rounded-lg px-5">
            <img
              src="customer_support.png"
              alt=""
              className="w-[30px] h-full"
            />
            <div className="flex flex-col items-start justify-center">
              <p className="font-bold">Agent Id:</p>
              <p className="text-[#3592FF] font-bold">Romeo Nwachukwu</p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Navbar;
