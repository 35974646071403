import React, { useEffect, useState } from 'react';
import { ContentHeader } from '../../components/ContentHeader';
import { TablesContacts } from '../../components/Tables';

import AddNewCustomer from '../../components/utils/cards/AddNewCustomer';
import Dashboards from '../Dashboards';

const Contacts = () => {
  const [modalState, setModalState] = useState(false);

  const handleNewCustomer = async () => {
    localStorage.setItem('user_modal', 'CUSTOMERFORM');
    const user_modal = localStorage.getItem('user_modal');
    if (user_modal === 'CUSTOMERFORM') {
      setModalState(true);
    }
  };

  const handleClose = () => {
    localStorage.removeItem('user_modal');
    const user_modal = localStorage.getItem('user_modal');
    if (!user_modal || user_modal !== 'CUSTOMERFORM') {
      setModalState(false);
    }
  };

  useEffect(() => {
    const user_modal = localStorage.getItem('user_modal');
    if (user_modal === 'CUSTOMERFORM') {
      setModalState(true);
    } else {
      setModalState(false);
    }
  }, []);

  return (
    <Dashboards>
      <div className="flex w-full px-[15px] lg:px-[10px] justify-around ">
        <div className="flex flex-col lg:w-[65%] mt-[80px]">
          <ContentHeader title={'Customers'} />
          <TablesContacts />
          <button
            onClick={handleNewCustomer}
            className="h-[41px] w-full bg-[#DAEBFF] rounded-[10px] text-[#17106B] font-['Poppins'] text-sm font-[500] mb-[15px]"
          >
            + Add New Customer
          </button>
        </div>
        <div className=" flex flex-col w-[20%] mt-[30px] h-[20%] justify-between">
          {/* <div className="flex items-center gap-2 h-1/8 bg-grey justify-end">
            <h5 className="text-[#818485] font-['Poppins'] font-medium leading-[30px]">
              Agent: <span className="text-[#3592FF]">Admin</span>
            </h5>
            <img src="Vector(2).svg" alt="" className="h-7 w-7" />
          </div> */}
        </div>
      </div>
      {/* <div className="w-full px-[20px] block md:hidden">
        <TablesConversationsMobile name={'Contacts'} />
        <ContactsListMobile />
      </div> */}
      <AddNewCustomer modalState={modalState} handleClose={handleClose} />
    </Dashboards>
  );
};

export default Contacts;
