import { useState } from 'react';

const useChat = () => {
  const message = [
    {
      text: 'Hello Andromedia, you guys are great',
      time: '12 noon',
      sender: 'user',
    },
    {
      text: 'hello user thank you for choosing Andromedia, hope you are doing good?',
      time: '12:01 PM',
      sender: 'agent',
    },
    {
      text: 'I like your work',
      time: '12:01 PM',
      sender: 'user',
    },
  ];

  const [messages, setMessages] = useState(message);

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  return { messages, addMessage };
};

export default useChat;
