import {  parseResponse } from "./mixin"
import { createCompanyAPI, createCustomersAPI } from "../api/companies"





export const createCompany = async (data) => {
    const response = await createCompanyAPI(data)
    // console.log({response})
    return parseResponse(response)
  }
export const createCustomer = async (data, company_id) => {
    const response = await createCustomersAPI(data, company_id)
    return parseResponse(response)
  }


// export const getAllCompanies = async (filter) => {
//   filter = filter || { } 
	
// 	let filterString = ''
// 	for (const [key, value] of Object.entries(filter)) {
// 		if (!value) { continue }
// 		filterString += `${key}=${value}&`
// 	}
// 	filterString = filterString ? `?${filterString.slice(0, -1)}` : filterString

//     const response = await (filterString)
//     return parseResponse(response)
//   }


// export const getMyResources = async () => {
//     const response = await getMyResourceAPI()
//     return parseResponse(response)
//   }

//   export const deleteResource = async (_id) => {
//     const response = await deleteResourceAPI(_id)
//     return parseResponse(response)
//   }