import React from 'react';

import items from '../data/sidebar.json';
import SidebarItem from './SidebarItem';

export default function Sidebar() {
  return (
    <div className="md:block flex-col w-[20%] h-full justify-items-end hidden  items-center mt-[80px] font-black">
      <hr className=" -ml-0.5 h-0.5 w-75 bg-black-600 mb-[15px]" />
      <div className="sidebar ml-auto flex flex-col space-y-2 h-5/8 w-[80%]">
        {items.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}
