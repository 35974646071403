function NoData({ height, width }) {
  return (
    <svg
      width={width ? width : '250'}
      height={height ? height : '221'}
      viewBox="0 0 500 373"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M500 327.4H0V327.65H500V327.4Z" fill="#EBEBEB" />
      <path d="M449.9 343.49H416.78V343.74H449.9V343.49Z" fill="#EBEBEB" />
      <path d="M331.22 346.21H322.53V346.46H331.22V346.21Z" fill="#EBEBEB" />
      <path d="M415.78 334.21H396.59V334.46H415.78V334.21Z" fill="#EBEBEB" />
      <path d="M95.65 335.89H52.46V336.14H95.65V335.89Z" fill="#EBEBEB" />
      <path d="M110.89 335.89H104.56V336.14H110.89V335.89Z" fill="#EBEBEB" />
      <path d="M225.15 340.11H131.47V340.36H225.15V340.11Z" fill="#EBEBEB" />
      <path
        d="M237 282.8H43.91C42.3973 282.797 40.9476 282.195 39.8789 281.124C38.8102 280.054 38.21 278.603 38.21 277.09V5.66C38.2232 4.15603 38.8292 2.71798 39.8964 1.65822C40.9637 0.598458 42.406 0.00257639 43.91 0H237C238.514 0 239.967 0.601588 241.038 1.67242C242.108 2.74325 242.71 4.19561 242.71 5.71V277.09C242.71 278.604 242.108 280.057 241.038 281.128C239.967 282.198 238.514 282.8 237 282.8ZM43.91 0.200001C42.4637 0.20265 41.0774 0.779065 40.0557 1.80273C39.0339 2.82639 38.46 4.21365 38.46 5.66V277.09C38.46 278.536 39.0339 279.924 40.0557 280.947C41.0774 281.971 42.4637 282.547 43.91 282.55H237C238.447 282.547 239.835 281.971 240.858 280.948C241.881 279.924 242.457 278.537 242.46 277.09V5.66C242.457 4.21273 241.881 2.82549 240.858 1.80212C239.835 0.77874 238.447 0.202644 237 0.200001H43.91Z"
        fill="#EBEBEB"
      />
      <path
        d="M453.31 282.8H260.21C258.696 282.797 257.246 282.195 256.175 281.125C255.105 280.054 254.503 278.604 254.5 277.09V5.66C254.516 4.15511 255.124 2.71708 256.193 1.65759C257.262 0.598109 258.705 0.00254829 260.21 0H453.31C454.812 0.0052045 456.252 0.602249 457.317 1.66173C458.382 2.72121 458.987 4.15775 459 5.66V277.09C459 278.601 458.401 280.05 457.335 281.121C456.268 282.191 454.821 282.795 453.31 282.8ZM260.21 0.200001C258.763 0.202644 257.375 0.77874 256.352 1.80212C255.329 2.82549 254.753 4.21273 254.75 5.66V277.09C254.753 278.537 255.329 279.924 256.352 280.948C257.375 281.971 258.763 282.547 260.21 282.55H453.31C454.757 282.547 456.145 281.971 457.168 280.948C458.191 279.924 458.767 278.537 458.77 277.09V5.66C458.767 4.21273 458.191 2.82549 457.168 1.80212C456.145 0.77874 454.757 0.202644 453.31 0.200001H260.21Z"
        fill="#EBEBEB"
      />
      <path
        d="M289.69 119.06L427.47 119.06V28.83L289.69 28.83V119.06Z"
        fill="#E6E6E6"
      />
      <path
        d="M285.48 119.06L425.5 119.06V28.83L285.48 28.83V119.06Z"
        fill="#F0F0F0"
      />
      <path
        d="M289.69 136.76L427.47 136.76V119.05L289.69 119.05V136.76Z"
        fill="#E6E6E6"
      />
      <path
        d="M278.49 136.76L418.51 136.76V119.05L278.49 119.05V136.76Z"
        fill="#F0F0F0"
      />
      <path
        d="M419.62 113.18V34.72L291.37 34.72V113.18L419.62 113.18Z"
        fill="#FAFAFA"
      />
      <path
        d="M390.7 113.17L374.32 34.72H348.76L365.14 113.17H390.7Z"
        fill="white"
      />
      <path
        d="M416.9 107.32C416.956 107.32 417.011 107.309 417.063 107.287C417.115 107.265 417.161 107.234 417.201 107.193C417.24 107.153 417.27 107.106 417.291 107.054C417.311 107.002 417.321 106.946 417.32 106.89V38.55C417.32 38.4386 417.276 38.3318 417.197 38.253C417.118 38.1743 417.011 38.13 416.9 38.13C416.844 38.1286 416.789 38.1386 416.738 38.1592C416.686 38.1798 416.639 38.2107 416.6 38.25C416.561 38.2892 416.53 38.3361 416.509 38.3877C416.489 38.4393 416.479 38.4945 416.48 38.55V106.89C416.479 106.946 416.489 107.002 416.509 107.054C416.53 107.106 416.56 107.153 416.599 107.193C416.639 107.234 416.685 107.265 416.737 107.287C416.789 107.309 416.844 107.32 416.9 107.32Z"
        fill="#F0F0F0"
      />
      <path
        d="M359.65 113.17L343.27 34.72H333.3L349.69 113.17H359.65Z"
        fill="white"
      />
      <path d="M292.12 113.18V34.72H291.37V113.18H292.12Z" fill="#E6E6E6" />
      <path
        opacity="0.6"
        d="M284.1 43.59H421.88L422.42 37H284.64L284.1 43.59Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M284.1 54.39H421.88L422.42 47.81H284.64L284.1 54.39Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M284.1 65.19H421.88L422.42 58.61H284.64L284.1 65.19Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M284.1 76H421.88L422.42 69.41H284.64L284.1 76Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M284.1 86.8H421.88L422.42 80.21H284.64L284.1 86.8Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M284.1 97.6H421.88L422.42 91.01H284.64L284.1 97.6Z"
        fill="#EBEBEB"
      />
      <path d="M407.69 261.78H378.8V267.48H407.69V261.78Z" fill="#E6E6E6" />
      <path d="M324.31 327.4H329.64V196.33H324.31V327.4Z" fill="#E6E6E6" />
      <path d="M305.83 267.48H378.79V261.78H305.83V267.48Z" fill="#F5F5F5" />
      <path d="M407.69 292.95H378.8V298.65H407.69V292.95Z" fill="#E6E6E6" />
      <path d="M305.83 298.64H378.79V292.94H305.83V298.64Z" fill="#F5F5F5" />
      <path d="M407.69 199.45H378.8V205.15H407.69V199.45Z" fill="#E6E6E6" />
      <path d="M305.83 205.14H378.79V199.44H305.83V205.14Z" fill="#F5F5F5" />
      <path d="M407.69 230.61H378.8V236.31H407.69V230.61Z" fill="#E6E6E6" />
      <path d="M305.83 236.31H378.79V230.61H305.83V236.31Z" fill="#F5F5F5" />
      <path d="M397.28 327.4H402.61V196.33H397.28V327.4Z" fill="#E6E6E6" />
      <path d="M373.46 327.4H378.79V196.33H373.46V327.4Z" fill="#F5F5F5" />
      <path d="M305.83 327.4H311.16V196.33H305.83V327.4Z" fill="#F5F5F5" />
      <path
        d="M65.38 327.4H119.96L119.96 221.51H65.38L65.38 327.4Z"
        fill="#E6E6E6"
      />
      <path d="M79.95 327.4H65.37V312.78H95.23L79.95 327.4Z" fill="#FAFAFA" />
      <path d="M214.18 327.4H268.76V221.51H214.18V327.4Z" fill="#E6E6E6" />
      <path d="M65.38 322.36H226.91V221.5H65.38V322.36Z" fill="#FAFAFA" />
      <path
        d="M212.33 327.4H226.9V312.78H197.05L212.33 327.4Z"
        fill="#FAFAFA"
      />
      <path d="M76.68 284.33H215.6V259.09H76.68V284.33Z" fill="#F0F0F0" />
      <path d="M76.68 314.96H215.6V289.72H76.68V314.96Z" fill="#F0F0F0" />
      <path
        d="M189.2 261.79H103.09C101.875 261.79 100.71 261.307 99.8515 260.449C98.9925 259.59 98.51 258.425 98.51 257.21V256.9H193.77V257.21C193.77 258.423 193.289 259.586 192.432 260.445C191.575 261.304 190.413 261.787 189.2 261.79Z"
        fill="#FAFAFA"
      />
      <path
        d="M76.68 253.69L215.6 253.69V228.45L76.68 228.45V253.69Z"
        fill="#F0F0F0"
      />
      <path
        d="M189.2 231.15H103.09C101.875 231.15 100.71 230.667 99.8515 229.809C98.9925 228.95 98.51 227.785 98.51 226.57V226.26H193.77V226.57C193.77 227.783 193.289 228.946 192.432 229.805C191.575 230.664 190.413 231.147 189.2 231.15Z"
        fill="#FAFAFA"
      />
      <path
        d="M189.2 292.43H103.09C101.875 292.43 100.71 291.947 99.8515 291.089C98.9925 290.23 98.51 289.065 98.51 287.85V287.54H193.77V287.85C193.77 289.063 193.289 290.226 192.432 291.085C191.575 291.944 190.413 292.427 189.2 292.43Z"
        fill="#FAFAFA"
      />
      <path
        d="M74.07 119.06L211.85 119.06V28.83L74.07 28.83V119.06Z"
        fill="#E6E6E6"
      />
      <path
        d="M69.87 119.06L209.89 119.06V28.83L69.87 28.83V119.06Z"
        fill="#F0F0F0"
      />
      <path
        d="M74.07 136.76L211.85 136.76V119.05L74.07 119.05V136.76Z"
        fill="#E6E6E6"
      />
      <path
        d="M62.86 136.76L202.88 136.76V119.05L62.86 119.05V136.76Z"
        fill="#F0F0F0"
      />
      <path
        d="M204 113.18V34.72L75.75 34.72V113.18L204 113.18Z"
        fill="#FAFAFA"
      />
      <path
        d="M175.08 113.17L158.7 34.72H133.14L149.52 113.17H175.08Z"
        fill="white"
      />
      <path
        d="M201.27 107.32C201.326 107.32 201.381 107.309 201.433 107.287C201.485 107.265 201.531 107.234 201.571 107.193C201.61 107.153 201.64 107.106 201.661 107.054C201.681 107.002 201.691 106.946 201.69 106.89V38.55C201.691 38.4945 201.681 38.4393 201.661 38.3877C201.64 38.3361 201.609 38.2892 201.57 38.25C201.531 38.2107 201.484 38.1798 201.432 38.1592C201.381 38.1386 201.326 38.1286 201.27 38.13C201.159 38.13 201.052 38.1743 200.973 38.253C200.894 38.3318 200.85 38.4386 200.85 38.55V106.89C200.849 106.946 200.859 107.002 200.879 107.054C200.9 107.106 200.93 107.153 200.969 107.193C201.009 107.234 201.055 107.265 201.107 107.287C201.159 107.309 201.214 107.32 201.27 107.32Z"
        fill="#F0F0F0"
      />
      <path
        d="M144.03 113.17L127.65 34.72H117.68L134.06 113.17H144.03Z"
        fill="white"
      />
      <path d="M76.5 113.18V34.72H75.75V113.18H76.5Z" fill="#E6E6E6" />
      <path
        opacity="0.6"
        d="M68.47 43.59H206.25L206.79 37H69.01L68.47 43.59Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M68.47 47.31H206.25L206.79 40.72H69.01L68.47 47.31Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M68.47 51.02H206.25L206.79 44.44H69.01L68.47 51.02Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M68.47 54.74H206.25L206.79 48.15H69.01L68.47 54.74Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M68.47 58.45H206.25L206.79 51.87H69.01L68.47 58.45Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.6"
        d="M68.47 62.17H206.25L206.79 55.58H69.01L68.47 62.17Z"
        fill="#EBEBEB"
      />
      <path d="M96.02 218.27H100.78V165.18H96.02V218.27Z" fill="#F5F5F5" />
      <path d="M96.62 218.24H97.97V165.15H96.62V218.24Z" fill="#FAFAFA" />
      <path d="M98.53 218.24H99.06V165.15H98.53V218.24Z" fill="#FAFAFA" />
      <path
        d="M116.03 221.51H80.79C80.79 220.332 81.2578 219.203 82.0904 218.37C82.9231 217.538 84.0524 217.07 85.23 217.07H111.58C112.758 217.07 113.887 217.538 114.72 218.37C115.552 219.203 116.02 220.332 116.02 221.51H116.03Z"
        fill="#F0F0F0"
      />
      <path
        d="M89.84 198.14C90.2025 198.137 90.5495 197.992 90.8058 197.736C91.0622 197.479 91.2074 197.133 91.21 196.77V162C91.21 161.637 91.0657 161.288 90.8087 161.031C90.5518 160.774 90.2033 160.63 89.84 160.63C89.6592 160.629 89.48 160.663 89.3127 160.731C89.1453 160.8 88.9931 160.9 88.8648 161.028C88.7365 161.155 88.6347 161.307 88.5652 161.473C88.4958 161.64 88.46 161.819 88.46 162V196.81C88.473 197.167 88.624 197.506 88.8815 197.754C89.1389 198.002 89.4825 198.14 89.84 198.14Z"
        fill="#F0F0F0"
      />
      <path
        d="M77.07 177.8H119.72L114.81 148H81.98L77.07 177.8Z"
        fill="#E0E0E0"
      />
      <path
        d="M250 372.56C357.082 372.56 443.89 367.492 443.89 361.24C443.89 354.988 357.082 349.92 250 349.92C142.918 349.92 56.11 354.988 56.11 361.24C56.11 367.492 142.918 372.56 250 372.56Z"
        fill="#F5F5F5"
      />
      <path
        d="M104.119 147.359L103.121 147.431L104.45 165.873L105.447 165.801L104.119 147.359Z"
        fill="#407BFF"
      />
      <path
        d="M106.125 175.322L105.127 175.394L105.627 182.336L106.625 182.264L106.125 175.322Z"
        fill="#407BFF"
      />
      <path
        d="M337.71 260.16H123.55C121.166 260.117 118.882 259.193 117.139 257.566C115.396 255.94 114.317 253.725 114.11 251.35L103.59 105.22C103.492 104.093 103.631 102.958 103.999 101.888C104.366 100.818 104.954 99.8373 105.724 99.0082C106.493 98.1792 107.428 97.5206 108.468 97.0748C109.507 96.629 110.629 96.406 111.76 96.42H325.92C328.302 96.4626 330.585 97.3851 332.327 99.0098C334.07 100.634 335.151 102.847 335.36 105.22L345.88 251.35C345.978 252.477 345.838 253.613 345.471 254.683C345.104 255.754 344.516 256.735 343.747 257.565C342.977 258.395 342.043 259.054 341.003 259.501C339.963 259.948 338.842 260.173 337.71 260.16Z"
        fill="#407BFF"
      />
      <path
        d="M338.53 260.16H124.37C121.986 260.117 119.702 259.193 117.959 257.566C116.216 255.94 115.137 253.725 114.93 251.35L104.41 105.22C104.312 104.093 104.451 102.958 104.819 101.888C105.186 100.818 105.774 99.8373 106.544 99.0082C107.313 98.1792 108.248 97.5206 109.288 97.0748C110.327 96.629 111.449 96.406 112.58 96.42H326.74C329.122 96.4626 331.405 97.3851 333.147 99.0098C334.89 100.634 335.971 102.847 336.18 105.22L346.7 251.35C346.799 252.478 346.661 253.614 346.294 254.685C345.928 255.756 345.34 256.738 344.57 257.568C343.801 258.399 342.865 259.058 341.825 259.504C340.785 259.951 339.662 260.174 338.53 260.16Z"
        fill="#407BFF"
      />
      <path
        opacity="0.5"
        d="M338.53 260.16H124.37C121.986 260.117 119.702 259.193 117.959 257.566C116.216 255.94 115.137 253.725 114.93 251.35L104.41 105.22C104.312 104.093 104.451 102.958 104.819 101.888C105.186 100.818 105.774 99.8373 106.544 99.0082C107.313 98.1792 108.248 97.5206 109.288 97.0748C110.327 96.629 111.449 96.406 112.58 96.42H326.74C329.122 96.4626 331.405 97.3851 333.147 99.0098C334.89 100.634 335.971 102.847 336.18 105.22L346.7 251.35C346.799 252.478 346.661 253.614 346.294 254.685C345.928 255.756 345.34 256.738 344.57 257.568C343.801 258.399 342.865 259.058 341.825 259.504C340.785 259.951 339.662 260.174 338.53 260.16Z"
        fill="white"
      />
      <path
        d="M327.06 100.82H112.9H112.18C106.71 101.27 107.83 109.6 113.35 109.6H327.87C333.4 109.6 333.32 101.27 327.78 100.82H327.06Z"
        fill="#407BFF"
      />
      <path
        d="M118.48 105.22C118.501 105.478 118.468 105.737 118.382 105.981C118.297 106.225 118.161 106.449 117.984 106.637C117.807 106.826 117.592 106.975 117.354 107.075C117.115 107.176 116.859 107.225 116.6 107.22C116.056 107.212 115.535 107.004 115.136 106.634C114.737 106.265 114.489 105.761 114.44 105.22C114.419 104.963 114.452 104.705 114.537 104.461C114.622 104.218 114.756 103.995 114.933 103.806C115.109 103.618 115.322 103.469 115.559 103.368C115.797 103.267 116.052 103.216 116.31 103.22C116.855 103.225 117.379 103.433 117.78 103.802C118.181 104.172 118.43 104.677 118.48 105.22Z"
        fill="#FAFAFA"
      />
      <path
        d="M125.35 105.22C125.371 105.478 125.338 105.737 125.252 105.981C125.167 106.225 125.031 106.449 124.854 106.637C124.677 106.826 124.462 106.975 124.224 107.075C123.985 107.176 123.729 107.225 123.47 107.22C122.926 107.212 122.405 107.004 122.006 106.634C121.607 106.265 121.359 105.761 121.31 105.22C121.289 104.963 121.322 104.705 121.407 104.461C121.492 104.218 121.626 103.995 121.803 103.806C121.979 103.618 122.192 103.469 122.429 103.368C122.667 103.267 122.922 103.216 123.18 103.22C123.725 103.225 124.249 103.433 124.65 103.802C125.051 104.172 125.3 104.677 125.35 105.22Z"
        fill="#FAFAFA"
      />
      <path
        d="M132.21 105.22C132.231 105.477 132.198 105.735 132.113 105.979C132.028 106.222 131.894 106.445 131.717 106.634C131.541 106.822 131.328 106.971 131.091 107.072C130.853 107.173 130.598 107.224 130.34 107.22C129.796 107.215 129.273 107.007 128.874 106.637C128.475 106.267 128.227 105.762 128.18 105.22C128.157 104.963 128.189 104.704 128.274 104.459C128.358 104.215 128.493 103.992 128.669 103.803C128.845 103.614 129.059 103.465 129.297 103.364C129.535 103.264 129.792 103.215 130.05 103.22C130.594 103.228 131.115 103.436 131.514 103.806C131.913 104.175 132.161 104.679 132.21 105.22Z"
        fill="#FAFAFA"
      />
      <path
        d="M332.85 245.58H128C127.135 245.566 126.305 245.23 125.673 244.639C125.042 244.048 124.652 243.243 124.58 242.38L115.93 122.21C115.891 121.798 115.941 121.382 116.076 120.99C116.21 120.598 116.426 120.24 116.71 119.938C116.994 119.636 117.339 119.399 117.721 119.24C118.104 119.082 118.516 119.007 118.93 119.02H323.83C324.694 119.032 325.524 119.365 326.156 119.955C326.788 120.545 327.178 121.348 327.25 122.21L335.91 242.38C335.948 242.798 335.895 243.219 335.757 243.614C335.618 244.01 335.396 244.372 335.107 244.675C334.817 244.978 334.465 245.216 334.076 245.372C333.687 245.528 333.269 245.599 332.85 245.58Z"
        fill="white"
      />
      <path
        d="M246.53 199.8L243.19 153.48L233.09 147.4H205.28L209.06 199.8H246.53Z"
        fill="white"
      />
      <path
        d="M246.53 200.28H209.06C208.936 200.283 208.816 200.238 208.724 200.154C208.633 200.07 208.578 199.954 208.57 199.83L204.8 147.43C204.795 147.364 204.804 147.298 204.827 147.236C204.849 147.174 204.884 147.118 204.93 147.07C204.974 147.021 205.028 146.981 205.088 146.953C205.148 146.926 205.214 146.911 205.28 146.91H233.09C233.178 146.913 233.263 146.937 233.34 146.98L243.44 153.06C243.508 153.099 243.565 153.154 243.607 153.22C243.649 153.286 243.674 153.362 243.68 153.44L247 199.76C247.007 199.827 246.999 199.896 246.976 199.96C246.954 200.024 246.918 200.082 246.87 200.13C246.826 200.177 246.774 200.214 246.715 200.24C246.657 200.266 246.594 200.279 246.53 200.28ZM209.53 199.28H246L242.72 153.73L233 147.88H205.8L209.53 199.28Z"
        fill="#407BFF"
      />
      <path
        d="M243.19 153.48L233.09 147.4L236.8 155.46L243.19 153.48Z"
        fill="#EBEBEB"
      />
      <path
        d="M236.8 155.94C236.708 155.939 236.617 155.913 236.54 155.863C236.462 155.814 236.4 155.743 236.36 155.66L232.65 147.6C232.607 147.505 232.594 147.4 232.614 147.297C232.634 147.195 232.685 147.102 232.76 147.03C232.839 146.962 232.938 146.921 233.042 146.912C233.146 146.903 233.25 146.927 233.34 146.98L243.44 153.06C243.521 153.109 243.587 153.18 243.63 153.265C243.672 153.35 243.69 153.445 243.68 153.54C243.667 153.632 243.628 153.719 243.568 153.789C243.508 153.86 243.429 153.912 243.34 153.94L236.94 155.94H236.8ZM234.19 148.62L237.06 154.87L242.06 153.34L234.19 148.62Z"
        fill="#407BFF"
      />
      <path
        d="M221 171C221.015 171.272 220.974 171.544 220.881 171.799C220.787 172.055 220.642 172.288 220.455 172.486C220.267 172.683 220.042 172.841 219.792 172.948C219.542 173.055 219.272 173.11 219 173.11C218.429 173.1 217.882 172.879 217.465 172.489C217.048 172.099 216.789 171.569 216.74 171C216.714 170.725 216.747 170.448 216.837 170.188C216.927 169.927 217.071 169.688 217.26 169.488C217.45 169.287 217.68 169.129 217.935 169.024C218.19 168.919 218.464 168.87 218.74 168.88C219.312 168.893 219.859 169.116 220.276 169.507C220.693 169.899 220.951 170.43 221 171Z"
        fill="#407BFF"
      />
      <path
        d="M234.7 171C234.724 171.274 234.69 171.55 234.6 171.809C234.509 172.069 234.365 172.306 234.176 172.506C233.986 172.705 233.757 172.862 233.503 172.966C233.248 173.071 232.975 173.12 232.7 173.11C232.128 173.1 231.58 172.879 231.161 172.49C230.742 172.1 230.482 171.57 230.43 171C230.414 170.727 230.453 170.454 230.546 170.198C230.64 169.941 230.784 169.706 230.972 169.508C231.159 169.309 231.385 169.151 231.636 169.043C231.887 168.935 232.157 168.88 232.43 168.88C233.004 168.888 233.555 169.11 233.974 169.502C234.394 169.893 234.653 170.428 234.7 171Z"
        fill="#407BFF"
      />
      <path
        d="M238.58 184.74C238.458 184.738 238.342 184.691 238.253 184.608C238.165 184.524 238.11 184.411 238.1 184.29C237.85 180.88 232.51 178.11 226.2 178.11C222.01 178.11 218.25 179.36 216.39 181.36C216.008 181.729 215.709 182.174 215.512 182.667C215.315 183.16 215.226 183.69 215.25 184.22C215.255 184.286 215.246 184.352 215.225 184.414C215.204 184.476 215.171 184.534 215.128 184.584C215.041 184.684 214.918 184.746 214.785 184.755C214.652 184.764 214.522 184.721 214.421 184.633C214.321 184.546 214.259 184.423 214.25 184.29C214.22 183.627 214.329 182.965 214.57 182.347C214.812 181.729 215.179 181.168 215.65 180.7C217.65 178.51 221.72 177.14 226.17 177.14C233.1 177.14 238.75 180.25 239.03 184.22C239.034 184.283 239.026 184.346 239.005 184.406C238.985 184.466 238.953 184.521 238.911 184.568C238.869 184.616 238.818 184.654 238.762 184.682C238.705 184.71 238.643 184.726 238.58 184.73V184.74Z"
        fill="#407BFF"
      />
      <path
        d="M213.17 166.72C213.048 166.718 212.931 166.671 212.84 166.59C212.749 166.498 212.698 166.374 212.698 166.245C212.698 166.116 212.749 165.992 212.84 165.9L214.97 163.61C215.061 163.522 215.183 163.473 215.31 163.473C215.437 163.473 215.559 163.522 215.65 163.61C215.695 163.655 215.73 163.708 215.754 163.766C215.779 163.824 215.791 163.887 215.791 163.95C215.791 164.013 215.779 164.076 215.754 164.134C215.73 164.192 215.695 164.245 215.65 164.29L213.52 166.58C213.474 166.626 213.419 166.662 213.359 166.686C213.299 166.71 213.235 166.721 213.17 166.72Z"
        fill="#407BFF"
      />
      <path
        d="M237.58 166.72C237.459 166.714 237.343 166.669 237.25 166.59L234.8 164.3C234.754 164.255 234.717 164.202 234.692 164.142C234.667 164.083 234.654 164.019 234.654 163.955C234.654 163.891 234.667 163.827 234.692 163.768C234.717 163.708 234.754 163.655 234.8 163.61C234.891 163.522 235.013 163.473 235.14 163.473C235.267 163.473 235.389 163.522 235.48 163.61L237.93 165.9C237.975 165.945 238.01 165.998 238.034 166.056C238.059 166.114 238.071 166.177 238.071 166.24C238.071 166.303 238.059 166.366 238.034 166.424C238.01 166.482 237.975 166.535 237.93 166.58C237.835 166.669 237.71 166.719 237.58 166.72Z"
        fill="#407BFF"
      />
      <path
        d="M202.26 210.15H204.52L207.78 214.49L207.47 210.15H209.75L210.31 217.99H208L204.76 213.68L205.07 217.99H202.79L202.26 210.15Z"
        fill="#407BFF"
      />
      <path
        d="M211.33 214.07C211.253 213.538 211.29 212.995 211.438 212.478C211.586 211.96 211.842 211.481 212.19 211.07C212.57 210.696 213.025 210.408 213.525 210.223C214.025 210.039 214.558 209.963 215.09 210C216.217 209.939 217.324 210.315 218.18 211.05C218.964 211.831 219.419 212.883 219.45 213.99C219.533 214.753 219.43 215.525 219.15 216.24C218.891 216.816 218.457 217.295 217.91 217.61C217.253 217.962 216.514 218.131 215.77 218.1C215.005 218.124 214.244 217.981 213.54 217.68C212.923 217.384 212.403 216.919 212.04 216.34C211.611 215.657 211.366 214.875 211.33 214.07ZM213.76 214.07C213.75 214.684 213.948 215.282 214.32 215.77C214.476 215.942 214.668 216.078 214.882 216.168C215.097 216.257 215.328 216.299 215.56 216.29C215.782 216.305 216.004 216.267 216.208 216.178C216.411 216.09 216.59 215.953 216.73 215.78C217.017 215.234 217.112 214.607 217 214C217.004 213.412 216.807 212.84 216.44 212.38C216.28 212.211 216.085 212.078 215.87 211.99C215.654 211.902 215.423 211.861 215.19 211.87C214.972 211.861 214.756 211.903 214.558 211.993C214.359 212.084 214.186 212.22 214.05 212.39C213.757 212.905 213.655 213.507 213.76 214.09V214.07Z"
        fill="#407BFF"
      />
      <path
        d="M224.15 210.15H227.75C228.343 210.131 228.935 210.23 229.49 210.44C229.929 210.624 230.32 210.908 230.63 211.27C230.945 211.637 231.186 212.062 231.34 212.52C231.502 213.012 231.603 213.523 231.64 214.04C231.717 214.694 231.666 215.356 231.49 215.99C231.352 216.429 231.108 216.828 230.78 217.15C230.506 217.44 230.161 217.654 229.78 217.77C229.315 217.905 228.834 217.979 228.35 217.99H224.76L224.15 210.15ZM226.7 211.93L227.01 216.21H227.6C227.965 216.234 228.33 216.176 228.67 216.04C228.894 215.91 229.059 215.699 229.13 215.45C229.244 215.006 229.272 214.544 229.21 214.09C229.229 213.48 229.042 212.881 228.68 212.39C228.494 212.222 228.276 212.094 228.038 212.015C227.801 211.936 227.549 211.907 227.3 211.93H226.7Z"
        fill="#407BFF"
      />
      <path
        d="M237.45 216.69H234.7L234.41 217.99H231.94L234.32 210.15H237L240.51 217.99H237.97L237.45 216.69ZM236.82 215L235.76 212.18L235.1 215H236.82Z"
        fill="#407BFF"
      />
      <path
        d="M239.37 210.15H246.73L246.87 212.09H244.4L244.82 217.99H242.4L241.98 212.09H239.51L239.37 210.15Z"
        fill="#407BFF"
      />
      <path
        d="M252.37 216.69H249.62L249.33 217.99H246.86L249.24 210.15H251.88L255.38 217.99H252.85L252.37 216.69ZM251.74 215L250.67 212.18L250 215H251.74Z"
        fill="#407BFF"
      />
      <path
        d="M353.69 118.08C354.58 117.63 355.69 117.08 356.63 116.51C357.57 115.94 358.63 115.39 359.63 114.79C361.58 113.58 363.56 112.39 365.43 111.07C369.186 108.5 372.706 105.602 375.95 102.41C376.36 102.03 376.72 101.61 377.11 101.2L377.68 100.59L377.96 100.29L378.1 100.13C377.96 100.4 378.1 100.32 378.1 100.06C378.188 99.7029 378.238 99.3376 378.25 98.97C378.227 96.8437 377.982 94.7256 377.52 92.65C376.63 88.17 375.28 83.55 373.95 79.03L377.83 77.33C380.279 81.6666 382.324 86.2191 383.94 90.93C384.848 93.4758 385.426 96.1274 385.66 98.82C385.721 99.6572 385.694 100.498 385.58 101.33C385.432 102.506 385.014 103.632 384.36 104.62L384.19 104.85L384.06 105.01L383.91 105.2L383.6 105.58L382.98 106.33C382.57 106.82 382.17 107.33 381.73 107.8C378.31 111.595 374.495 115.014 370.35 118C368.35 119.48 366.23 120.9 364.06 122.19C362.99 122.84 361.9 123.46 360.79 124.07C359.68 124.68 358.6 125.24 357.32 125.84L353.69 118.08Z"
        fill="#FFB573"
      />
      <path
        d="M344.79 353.18C345.539 353.161 346.283 353.061 347.01 352.88C347.047 352.869 347.08 352.848 347.106 352.82C347.133 352.792 347.151 352.757 347.16 352.72C347.165 352.682 347.159 352.643 347.143 352.607C347.127 352.572 347.102 352.542 347.07 352.52C346.78 352.33 344.24 350.69 343.26 351.13C343.151 351.181 343.058 351.259 342.99 351.358C342.921 351.456 342.88 351.571 342.87 351.69C342.831 351.879 342.841 352.074 342.899 352.258C342.956 352.442 343.06 352.608 343.2 352.74C343.659 353.074 344.224 353.23 344.79 353.18ZM346.44 352.6C344.99 352.89 343.89 352.84 343.44 352.45C343.35 352.358 343.284 352.245 343.249 352.121C343.215 351.996 343.211 351.866 343.24 351.74C343.244 351.687 343.261 351.636 343.291 351.592C343.321 351.548 343.362 351.513 343.41 351.49C343.94 351.26 345.41 352 346.44 352.6Z"
        fill="#407BFF"
      />
      <path
        d="M347 352.88H347.1C347.129 352.862 347.154 352.837 347.171 352.807C347.189 352.778 347.199 352.744 347.2 352.71C347.2 352.6 347.2 350.19 346.28 349.39C346.17 349.284 346.037 349.204 345.891 349.157C345.746 349.111 345.591 349.098 345.44 349.12C345.282 349.121 345.13 349.177 345.008 349.277C344.886 349.377 344.802 349.516 344.77 349.67C344.58 350.67 346.1 352.42 346.9 352.88C346.933 352.889 346.967 352.889 347 352.88ZM345.57 349.49C345.733 349.491 345.889 349.551 346.01 349.66C346.538 350.438 346.81 351.36 346.79 352.3C345.99 351.66 345.04 350.3 345.16 349.73C345.16 349.64 345.23 349.52 345.48 349.49H345.57Z"
        fill="#407BFF"
      />
      <path
        d="M346.22 93.07C345.22 98.07 343.22 108.07 346.67 111.42C346.67 111.42 345.31 116.42 336.08 116.42C325.92 116.42 331.23 111.42 331.23 111.42C336.77 110.1 336.62 105.99 335.66 102.12L346.22 93.07Z"
        fill="#FFB573"
      />
      <path
        d="M329.28 113.42C327.69 113.64 329.05 109.51 329.69 109.08C331.19 108.08 350.55 106.69 350.42 109.08C350.34 110.08 349.86 112.08 349.02 112.74C348.18 113.4 343.2 111.34 329.28 113.42Z"
        fill="#263238"
      />
      <path
        d="M332.44 112C331.17 112.43 331.29 108.27 331.72 107.77C332.72 106.61 348.39 102.59 348.85 104.86C349.03 105.86 349.12 107.86 348.58 108.57C348.04 109.28 343.44 108.11 332.44 112Z"
        fill="#263238"
      />
      <path
        d="M326.61 84.11C326.547 84.1127 326.484 84.1006 326.427 84.0745C326.37 84.0485 326.319 84.0092 326.28 83.96C325.975 83.5665 325.581 83.2502 325.131 83.0365C324.682 82.8229 324.188 82.7179 323.69 82.73C323.638 82.737 323.585 82.7334 323.534 82.7194C323.484 82.7054 323.437 82.6813 323.395 82.6487C323.354 82.616 323.32 82.5753 323.295 82.5292C323.27 82.483 323.255 82.4323 323.25 82.38C323.241 82.277 323.273 82.1747 323.338 82.0945C323.404 82.0144 323.497 81.9626 323.6 81.95C324.229 81.9179 324.857 82.0384 325.43 82.3013C326.003 82.5643 326.504 82.9618 326.89 83.46C326.957 83.5412 326.989 83.6454 326.98 83.7503C326.971 83.8551 326.92 83.952 326.84 84.02C326.772 84.0688 326.693 84.0997 326.61 84.11Z"
        fill="#263238"
      />
      <path
        d="M324.67 89C324.216 90.5953 323.543 92.1199 322.67 93.53C323.037 93.7409 323.445 93.8689 323.867 93.9052C324.288 93.9415 324.713 93.8851 325.11 93.74L324.67 89Z"
        fill="#FF5652"
      />
      <path
        d="M325.16 87.79C325.23 88.46 324.92 89.04 324.48 89.08C324.04 89.12 323.63 88.62 323.57 87.94C323.51 87.26 323.81 86.69 324.24 86.65C324.67 86.61 325.09 87.11 325.16 87.79Z"
        fill="#263238"
      />
      <path
        d="M324.44 86.67L322.78 86.36C322.78 86.36 323.73 87.54 324.44 86.67Z"
        fill="#263238"
      />
      <path
        d="M356.7 352.69H348.32L348.98 333.29H357.36L356.7 352.69Z"
        fill="#FFB573"
      />
      <path
        d="M347.66 351.72H357.07C357.232 351.716 357.39 351.772 357.514 351.877C357.637 351.982 357.718 352.129 357.74 352.29L358.81 359.73C358.831 359.918 358.812 360.109 358.754 360.289C358.697 360.469 358.601 360.635 358.475 360.776C358.348 360.917 358.193 361.029 358.02 361.106C357.847 361.182 357.659 361.221 357.47 361.22C354.19 361.17 352.61 360.97 348.47 360.97C345.92 360.97 342.2 361.24 338.69 361.24C335.18 361.24 334.98 357.76 336.45 357.45C343.01 356.03 344.05 354.09 346.26 352.23C346.654 351.903 347.148 351.723 347.66 351.72Z"
        fill="#263238"
      />
      <g opacity="0.2">
        <path
          d="M357.36 333.29H348.98L348.64 343.29H357.02L357.36 333.29Z"
          fill="black"
        />
      </g>
      <path
        d="M323.37 123C318.242 121.006 313.219 118.753 308.32 116.25C303.26 113.777 298.439 110.842 293.92 107.48C292.713 106.534 291.57 105.509 290.5 104.41C290.22 104.11 289.94 103.83 289.67 103.48C289.353 103.122 289.059 102.745 288.79 102.35C288.052 101.276 287.592 100.035 287.45 98.74C287.34 97.5119 287.507 96.2746 287.94 95.12C288.287 94.1919 288.761 93.3168 289.35 92.52C290.316 91.1991 291.456 90.015 292.74 89C295.006 87.1958 297.452 85.6308 300.04 84.33C301.28 83.67 302.54 83.08 303.82 82.53C305.1 81.98 306.36 81.46 307.73 80.99L309.5 84.84C305.14 87.59 300.62 90.62 297.3 94C296.557 94.7529 295.903 95.5883 295.35 96.49C294.87 97.28 294.88 97.87 294.96 97.82C295.04 97.77 294.96 97.82 295.1 97.92L295.5 98.35C295.65 98.53 295.86 98.71 296.05 98.9C296.897 99.6796 297.796 100.401 298.74 101.06C300.804 102.522 302.955 103.857 305.18 105.06C307.43 106.32 309.75 107.51 312.11 108.65C316.83 110.91 321.71 113.08 326.5 115.06L323.37 123Z"
        fill="#FFB573"
      />
      <path
        d="M312 82.15L313.38 79.15L307.06 77C307.06 77 305.06 82.91 307.43 85.63C308.42 85.4741 309.357 85.0744 310.154 84.4669C310.952 83.8595 311.586 83.0633 312 82.15Z"
        fill="#FFB573"
      />
      <path
        d="M313.35 72.66L308.32 71.2L307.06 76.96L313.35 79.15V72.66Z"
        fill="#FFB573"
      />
      <path
        d="M378.35 79.44L379.08 72.44L372.6 74C372.6 74 372.5 80.58 375.93 81.62L378.35 79.44Z"
        fill="#FFB573"
      />
      <path
        d="M375.96 67.51L371.73 69.48L372.6 73.95L379.08 72.42L375.96 67.51Z"
        fill="#FFB573"
      />
      <path
        d="M347.54 83.25C347.85 91.56 348.15 95.07 344.4 99.72C338.75 106.72 328.48 105.27 325.7 97.22C323.2 89.98 323.12 77.61 330.88 73.53C332.586 72.6229 334.496 72.1667 336.427 72.2048C338.359 72.243 340.249 72.7741 341.918 73.7478C343.587 74.7215 344.98 76.1056 345.964 77.7686C346.948 79.4316 347.49 81.3184 347.54 83.25Z"
        fill="#FFB573"
      />
      <path
        d="M343.92 99.71C352.08 96.18 357.44 88.71 355.18 77.13C353.01 66.02 345.51 64.92 342.41 67.22C339.31 69.52 331.58 66.09 326.94 69.99C318.94 76.76 326.5 83.99 330.46 88.36C332.82 93.22 335.92 103.16 343.92 99.71Z"
        fill="#263238"
      />
      <path
        d="M340.53 75.14C341.525 76.417 342.873 77.3745 344.406 77.8943C345.94 78.4142 347.592 78.4737 349.158 78.0654C350.725 77.6572 352.138 76.7991 353.223 75.597C354.308 74.395 355.016 72.9016 355.262 71.3013C355.508 69.7009 355.28 68.0637 354.605 66.5916C353.931 65.1195 352.841 63.8771 351.469 63.0177C350.097 62.1582 348.503 61.7194 346.884 61.7553C345.265 61.7913 343.693 62.3005 342.36 63.22C340.537 64.5584 339.32 66.5659 338.977 68.8012C338.634 71.0364 339.193 73.3164 340.53 75.14Z"
        fill="#407BFF"
      />
      <path
        d="M342.65 68C341.48 61.3 347.81 56.14 356.29 58.63C364.77 61.12 360.29 68.63 358.54 74.63C356.79 80.63 361.37 86.35 363.27 81.78C365.17 77.21 361.97 75.78 361.97 75.78C361.97 75.78 370.97 78.11 362.61 88.26C354.25 98.41 346.68 87.2 348.73 80.59C350.39 75.28 343.76 74.33 342.65 68Z"
        fill="#263238"
      />
      <path
        d="M334.18 70.53C330.31 68.53 323.76 66.88 320.03 73.16C318.27 76.16 318.95 80.16 318.95 80.16L330.34 80.91L334.18 70.53Z"
        fill="#263238"
      />
      <path
        d="M317.61 78.18C317.543 78.1749 317.48 78.1439 317.435 78.0935C317.391 78.0431 317.367 77.9773 317.37 77.91C317.37 77.74 317.66 73.72 320.09 71.2C325.96 65.1 332.84 70.2 334.8 71.85C334.842 71.8946 334.866 71.9532 334.868 72.0146C334.87 72.076 334.849 72.1358 334.81 72.1828C334.77 72.2298 334.715 72.2605 334.654 72.2692C334.593 72.278 334.531 72.264 334.48 72.23C332.59 70.61 326.01 65.76 320.48 71.54C318.18 73.93 317.9 77.9 317.9 77.94C317.899 77.9756 317.89 78.0107 317.875 78.0427C317.859 78.0748 317.837 78.1031 317.809 78.1258C317.782 78.1486 317.75 78.1651 317.716 78.1745C317.681 78.1838 317.645 78.1857 317.61 78.18Z"
        fill="#263238"
      />
      <path
        d="M331.85 88C331.805 89.5732 331.223 91.0837 330.2 92.28C328.82 93.9 327.2 93.1 326.87 91.28C326.54 89.67 326.87 86.88 328.61 85.91C330.35 84.94 331.87 86.18 331.85 88Z"
        fill="#FFB573"
      />
      <path
        d="M330.05 164.12C330.05 164.12 330.59 222.27 335.63 254.67C339.71 280.84 347.24 341.36 347.24 341.36H358.67C358.67 341.36 359.78 282.93 357.67 257.05C352.44 191.55 365.95 179.3 355.05 164.12H330.05Z"
        fill="#263238"
      />
      <path
        opacity="0.1"
        d="M330.05 164.12C330.05 164.12 330.59 222.27 335.63 254.67C339.71 280.84 347.24 341.36 347.24 341.36H358.67C358.67 341.36 359.78 282.93 357.67 257.05C352.44 191.55 365.95 179.3 355.05 164.12H330.05Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M336.06 190.5C340.06 208.05 336.87 235.69 334.68 247.86C332.36 229.38 331.19 205.43 330.61 187.86C332.68 184.52 334.64 184.3 336.06 190.5Z"
        fill="black"
      />
      <path
        d="M345.68 341.58H360.23L360.99 336.48L345.6 335.96L345.68 341.58Z"
        fill="#407BFF"
      />
      <path
        d="M349.83 115C351.2 112.28 358.56 110.57 362.58 110.58L365.58 123.94C365.58 123.94 357.58 135.83 354.24 134.57C350.31 133.12 346.9 120.87 349.83 115Z"
        fill="#407BFF"
      />
      <path
        opacity="0.4"
        d="M349.83 115C351.2 112.28 358.56 110.57 362.58 110.58L365.58 123.94C365.58 123.94 357.58 135.83 354.24 134.57C350.31 133.12 346.9 120.87 349.83 115Z"
        fill="black"
      />
      <path
        d="M341.35 338.31C341.367 338.281 341.375 338.248 341.375 338.215C341.375 338.182 341.367 338.149 341.35 338.12C341.331 338.082 341.301 338.05 341.263 338.03C341.225 338.011 341.182 338.004 341.14 338.01C340.72 338.08 337.02 338.66 336.52 339.72C336.467 339.816 336.439 339.925 336.439 340.035C336.439 340.145 336.467 340.254 336.52 340.35C336.606 340.508 336.729 340.643 336.879 340.742C337.029 340.841 337.201 340.903 337.38 340.92C338.59 341.04 340.38 339.36 341.28 338.34L341.35 338.31ZM336.91 339.85C337.25 339.29 339.17 338.77 340.63 338.5C339.31 339.84 338.15 340.59 337.47 340.5C337.352 340.488 337.24 340.447 337.142 340.381C337.044 340.315 336.964 340.225 336.91 340.12C336.888 340.082 336.876 340.039 336.876 339.995C336.876 339.951 336.888 339.908 336.91 339.87C336.91 339.87 336.9 339.86 336.91 339.85Z"
        fill="#407BFF"
      />
      <path
        d="M341.35 338.31C341.367 338.281 341.375 338.248 341.375 338.215C341.375 338.182 341.367 338.149 341.35 338.12C341.29 338.05 339.81 336.34 338.44 336.25C338.243 336.233 338.044 336.258 337.856 336.321C337.669 336.385 337.496 336.487 337.35 336.62C336.94 336.99 336.98 337.31 337.08 337.51C337.51 338.37 340.17 338.67 341.24 338.44C341.268 338.429 341.292 338.411 341.311 338.389C341.331 338.366 341.344 338.339 341.35 338.31ZM337.44 337.05C337.484 336.982 337.538 336.921 337.6 336.87C337.704 336.771 337.829 336.695 337.965 336.649C338.102 336.602 338.247 336.585 338.39 336.6C339.32 336.829 340.156 337.343 340.78 338.07C339.59 338.17 337.67 337.81 337.41 337.29C337.393 337.251 337.387 337.208 337.393 337.166C337.398 337.124 337.414 337.084 337.44 337.05Z"
        fill="#407BFF"
      />
      <path
        d="M350.08 334.76L342.42 338.17L339.01 331.06L334.55 321.76L334.02 320.68L341.68 317.27L342.27 318.49L346.59 327.5L350.08 334.76Z"
        fill="#FFB573"
      />
      <path
        opacity="0.2"
        d="M346.59 327.5L339.01 331.06L334.55 321.76L342.27 318.49L346.59 327.5Z"
        fill="black"
      />
      <path
        d="M321.07 164.12C321.07 164.12 296.6 225 303.23 253.6C309.23 279.49 335.73 328.52 335.73 328.52L346 323.38C346 323.38 329.61 264.19 327.84 251.33C324.38 226.33 346.61 191 346.61 164.12H321.07Z"
        fill="#263238"
      />
      <path
        opacity="0.1"
        d="M321.07 164.12C321.07 164.12 296.6 225 303.23 253.6C309.23 279.49 335.73 328.52 335.73 328.52L346 323.38C346 323.38 329.61 264.19 327.84 251.33C324.38 226.33 346.61 191 346.61 164.12H321.07Z"
        fill="white"
      />
      <path
        d="M341.06 337.1L348.66 331.57C348.788 331.47 348.949 331.42 349.111 331.431C349.274 331.442 349.427 331.513 349.54 331.63L354.79 337.02C354.917 337.161 355.014 337.327 355.072 337.507C355.131 337.687 355.152 337.878 355.132 338.067C355.112 338.256 355.053 338.438 354.957 338.602C354.862 338.766 354.734 338.909 354.58 339.02C351.89 340.9 350.5 341.68 347.16 344.11C345.1 345.61 341 348.92 338.16 350.99C335.32 353.06 333.16 350.36 334.16 349.25C338.64 344.25 339.58 341.15 340.28 338.34C340.388 337.849 340.664 337.41 341.06 337.1Z"
        fill="#263238"
      />
      <path
        d="M335.07 330.69L348.3 324.64L346.61 319.25L332.44 325.71L335.07 330.69Z"
        fill="#407BFF"
      />
      <path
        d="M327.56 114.36C326.47 111.52 316.91 108.36 312.11 107.36L310 123.08C310 123.08 317.84 134.34 321.27 133.41C325.33 132.31 329.92 120.45 327.56 114.36Z"
        fill="#407BFF"
      />
      <path
        opacity="0.4"
        d="M327.56 114.36C326.47 111.52 316.91 108.36 312.11 107.36L310 123.08C310 123.08 317.84 134.34 321.27 133.41C325.33 132.31 329.92 120.45 327.56 114.36Z"
        fill="black"
      />
      <path
        d="M317.07 113.57C317.07 113.57 313.07 114.97 321.07 164.12H355.07C354.5 150.28 354.48 141.74 361.07 113.32C356.306 112.337 351.476 111.702 346.62 111.42C341.48 111.05 336.32 111.05 331.18 111.42C324.59 112 317.07 113.57 317.07 113.57Z"
        fill="#407BFF"
      />
      <path
        opacity="0.4"
        d="M317.07 113.57C317.07 113.57 313.07 114.97 321.07 164.12H355.07C354.5 150.28 354.48 141.74 361.07 113.32C356.306 112.337 351.476 111.702 346.62 111.42C341.48 111.05 336.32 111.05 331.18 111.42C324.59 112 317.07 113.57 317.07 113.57Z"
        fill="black"
      />
      <path
        d="M355.6 162.13L357.13 165.13C357.25 165.37 356.97 165.61 356.58 165.61H320.9C320.59 165.61 320.34 165.46 320.32 165.26L320.01 162.26C320.01 162.05 320.26 161.87 320.59 161.87H355C355.114 161.852 355.232 161.867 355.338 161.913C355.444 161.959 355.535 162.034 355.6 162.13Z"
        fill="#407BFF"
      />
      <path
        opacity="0.3"
        d="M355.6 162.13L357.13 165.13C357.25 165.37 356.97 165.61 356.58 165.61H320.9C320.59 165.61 320.34 165.46 320.32 165.26L320.01 162.26C320.01 162.05 320.26 161.87 320.59 161.87H355C355.114 161.852 355.232 161.867 355.338 161.913C355.444 161.959 355.535 162.034 355.6 162.13Z"
        fill="white"
      />
      <path
        d="M351 166H351.92C352.11 166 352.25 165.9 352.23 165.79L351.8 161.79C351.8 161.67 351.63 161.58 351.45 161.58H350.52C350.34 161.58 350.2 161.67 350.21 161.79L350.64 165.79C350.63 165.88 350.79 166 351 166Z"
        fill="#263238"
      />
      <path
        d="M328.61 166H329.53C329.71 166 329.85 165.9 329.84 165.79L329.41 161.79C329.41 161.67 329.24 161.58 329.05 161.58H328.13C327.94 161.58 327.81 161.67 327.82 161.79L328.25 165.79C328.26 165.88 328.42 166 328.61 166Z"
        fill="#263238"
      />
    </svg>
  );
}

export default NoData;
