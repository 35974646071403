function EmailTwo() {
  return (
    <svg
      width="30"
      height="40"
      viewBox="0 0 52 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4444 43.0675C12.1333 43.0675 9.82222 40.9141 8.66667 39.9912C2.88889 35.6845 1.15556 34.1464 0 33.2235V46.1438C0 47.8424 1.29368 49.22 2.88889 49.22H26C27.5952 49.22 28.8889 47.8424 28.8889 46.1438V33.2235C27.7333 34.1464 26 35.6845 20.2222 39.9912C19.0667 40.9141 16.7556 43.0675 14.4444 43.0675ZM26 24.61H2.88889C1.29368 24.61 0 25.9876 0 27.6863V29.2244C2.31111 31.0701 2.02222 31.0701 10.4 37.5303C11.2667 38.1455 13 39.9912 14.4444 39.9912C15.8889 39.9912 17.6222 38.1455 18.4889 37.8379C26.8667 31.3778 26.5778 31.3778 28.8889 29.532V27.6863C28.8889 25.9876 27.5952 24.61 26 24.61ZM49.1111 15.3813H20.2222C18.627 15.3813 17.3333 16.7588 17.3333 18.4575V21.5338H26C28.9981 21.5338 31.4699 23.9774 31.7516 27.0921L31.7778 27.071V39.9912H49.1111C50.7063 39.9912 52 38.6137 52 36.915V18.4575C52 16.7588 50.7063 15.3813 49.1111 15.3813ZM46.2222 27.6863H40.4444V21.5338H46.2222V27.6863ZM14.4444 18.4575C14.4444 15.065 17.0363 12.305 20.2222 12.305H40.4444V3.07625C40.4444 1.37758 39.1508 0 37.5556 0H8.66667C7.07146 0 5.77778 1.37758 5.77778 3.07625V21.5338H14.4444V18.4575Z"
        fill="#15ACF5"
      />
    </svg>
  );
}

export default EmailTwo;
