import axios from 'axios';
import { useState } from 'react';
import { API_URL } from '../constants/api';

const usePaystackPayment = (onPaymentSuccess) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const initiatePaystackPayment = async (amountInKobo) => {
    try {
      setIsLoading(true);

      const requestData = {
        reference_id: '2',
        amount: amountInKobo.toString(), // Amount in kobo (e.g., 2995 for ₦29.95)
        payment_status: 'pending', // Change to your currency if necessary
        payment_channel: 'paystack',
        company: 2, // Replace with a unique reference
        subscription: 1,
      };

      const response = await axios.post(`${API_URL}/payments/`, requestData);
      console.log(response);

      const authorizationUrl =
        response.data.data.payment_response.data.authorization_url;

      if (authorizationUrl) {
        // Redirect to Paystack payment page
        window.location.href = authorizationUrl;
      }
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePaystackCallback = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const paymentStatus = queryParams.get('status');
    console.log(queryParams);
    if (paymentStatus === 'success') {
      // Payment was successful, call the success callback
      onPaymentSuccess();
    } else {
      // Payment failed or was canceled
      setError('Payment failed or was canceled');
    }
  };

  return { isLoading, error, initiatePaystackPayment, handlePaystackCallback };
};

export default usePaystackPayment;
