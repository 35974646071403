import {
  createConversationAPI,
  createConversationMessagesAPI,
  getConversationMessagesAPI,
} from '../api/conversations';
import { parseResponse } from './mixin';

export const createConversations = async (data) => {
  const response = await createConversationAPI(data);
  return parseResponse(response);
};

export const createConversationMessages = async (data, conversation_id) => {
  const response = await createConversationMessagesAPI(data, conversation_id);
  return parseResponse(response);
};
export const getConversationsMessages = async ( conversation_id) => {
  const response = await getConversationMessagesAPI(conversation_id);
  return parseResponse(response);
};

