import axios from 'axios';
import { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import backgroundImage from '../Images/Background.png';
import Layout from '../component/Layout';
import { UserContext } from '../context/UserContext';
// import Contacts from "../routes/pages/Contacts";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { API_URL } from '../constants/api';
import { config } from '../constants/auth_actions';
import Dashboard from '../routes/pages/Dashboard';

// import Communicate from "./Communicate";

function SignIn() {
  const location = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const logindata = useContext(UserContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('pass', password, email);
    setLoading(true);
    const user = {
      password,
      email,
    };
    // console.log(user);

    try {
      const response = await axios.post(`${API_URL}/auth/login/`, user);
      console.log(response);

      if (response.status === 200) {
        toast.success('Successfully logged in');
        const { access } = response?.data;

        // logindata.tokenHandler(access);
        if (access) {
          const userCompanies = await axios.get(
            `${API_URL}/companies/`,
            config(access)
          );

          console.log('usercompanies', userCompanies);

          const { user } = response.data;
          logindata.userHandler(user);
          localStorage.setItem(
            'current_company_id',
            userCompanies?.data[0]?.id
          );
          localStorage.setItem('refresh_token', response.data?.refresh);
          localStorage.setItem('access_token', response.data?.access);
          localStorage.setItem('email', email);
          localStorage.setItem('Password', password);
          navigate('/dashboard');
          window.location.reload();
        }
      } else {
        // Handle unexpected response status
        toast.error('Unexpected response status: ' + response.status);
      }
    } catch (err) {
      console.log(err);
      // Handle error
      if (err?.response?.status === 401) {
        toast.error('Incorrect email or password');
      } else if (err?.response?.status === 400) {
        toast.error('Incorrect email or password');
      } else if (err?.response?.status === 500) {
        toast.error('Network error, please try again');
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!isLoggedIn ? (
        <Layout>
          <form
            onSubmit={handleSubmit}
            className=" mt-40  bg-cover bg-center h-100 w-100 mb-[6rem]"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <div className="flex flex-col gap-10 justify-center items-center ">
              <div className="flex gap-0 justify-center items-center w-[50%]  rounded-lg  h-[3rem] max-[1174px]:w-1/2 max-[768px]:w-[70%] max-[500px]:w-[80%] max-[400px]:w-[95%]">
                <Link
                  to="/SignUp"
                  className="rounded-lg bg-[#DAEBFF] w-1/2  h-full outline-none text-center  cursor-pointer text-[#1B72FC] "
                >
                  <input
                    type="submit"
                    className=" h-full cursor-pointer "
                    value="Register"
                  />{' '}
                </Link>
                <Link
                  to="/SignIn"
                  className="bg-[#1B72FC] rounded-lg  w-1/2  h-full outline-none cursor-pointer text-center text-[#ffffff]  border-[#1B72FC]  relative right-2"
                >
                  <input
                    type="submit"
                    className="h-full cursor-pointer"
                    value="Sign In"
                  />{' '}
                </Link>
              </div>
              <div className="w-[50%] flex flex-col  max-[1174px]:w-1/2 max-[768px]:w-[68%] max-[500px]:w-[77%] max-[400px]:w-[93%]">
                <label for="email" className="text-[#000] font-medium ">
                  Email
                </label>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-[#DAEBFF] rounded-md  outline-none px-2  h-[3rem] max"
                />
              </div>
              <div className="w-[50%]  flex flex-col  max-[1174px]:w-1/2 max-[768px]:w-[68%] max-[500px]:w-[77%] max-[400px]:w-[93%] relative">
                <label for="password" className="text-[#000] font-medium">
                  Password
                </label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-[#DAEBFF] rounded-md w-full h-[3rem] outline-none px-2"
                />
                {showPassword ? (
                  <FaEye
                    onClick={togglePasswordVisibility}
                    className="text-gray-400 h-5 w-5 mx-2 cursor-pointer self-end absolute bottom-3"
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </FaEye>
                ) : (
                  <FaEyeSlash
                    onClick={togglePasswordVisibility}
                    className="text-gray-400 h-5 w-5 mx-2 cursor-pointer self-end absolute bottom-3"
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </FaEyeSlash>
                )}
              </div>

              {/* <input type="submit" className="text-[white] rounded-md w-[49%]  h-[3rem] outline-none px-2 bg-[#1B72FC] cursor-pointer  max-[1174px]:w-1/2 max-[768px]:w-[68%] max-[500px]:w-[77%] max-[400px]:w-[93%]" value='Sign In' onClick={handleLogin} /> */}
              <button
                type="submit"
                className="text-[white] rounded-md w-[50%] h-[3rem] outline-none px-2 bg-[#1B72FC] cursor-pointer max-[1174px]:w-1/2 max-[768px]:w-[68%] max-[500px]:w-[77%] max-[400px]:w-[93%]"
                value="Sign In"
              >
                {loading ? <SyncLoader color="white" size={10} /> : 'Sign in'}
              </button>
              <div className=" text-[#1B72FC]  text-right w-[49%]">
                <Link to="/forgot-password">Forgot password?</Link>
              </div>
            </div>
            {/* <div className="relative  h-96 mb-16 mt-16">
              <img src={assetImg} alt="" className="absolute right-0" />
            </div> */}
          </form>
        </Layout>
      ) : (
        <>
          <Dashboard />
          {/* <Contacts /> */}
        </>
      )}
    </>
  );
}

export default SignIn;
