import React from 'react';

export default function Header() {
    return (
        <div className="relative">

        <div className="relative h-screen py-[40px] overflow-hidden ">
            <img className="object-cover w-full h-full absolute top-0 left-0 z-[-1]" src="/application_bg.png" alt="" />
            <div className="flex w-2/3 m-auto flex-col items-center justify-center h-full relative z-10 text-white">
                <h1 className="text-[60px] font-bold mb-4 text-center">Smart <br /> Applications</h1>
                <p className="text-lg text-center">Nam sollicitudin dignissim nunc, cursus ullamcorper eros vulputate sed. Vestibulum sit amet tortor sit amet libero s.</p>
            </div>
        </div>
        {/* <img className="w-90 h-full m-auto absolute top-1/2 transform -translate-y-1/2 z-[-1]" src="/application_phone.png" alt="" /> */}
        {/* <img className="w-90 h-full absolute top-1/2 mt-96 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[-1]" src="/application_phone.png" alt="" /> */}

            {/* <img className="w-90 h-full m-auto absolute top-10   z-[-1]" src="/application_phone.png" alt="" /> */}
        </div>
    );
}
